import Select from "react-select";

const TableRowsExtraCost = ({
  rowsData,
  deleteTableRows,
  handleChange,
  handleOptionsProduct,
  handleOptionsTypology,
  handleOptionsTypology_1,
  handleChangeProduct,
  handleChangeTypology,
  handleChangeTypology_1,
}) => {
  return rowsData.map((data, index) => {
    const { productId, typologyId, typology_1, m2_limit, extra_cost } = data;
    // console.log(data);
    // console.log(data);
    return (
      <tr key={index}>
        <td>
          <Select
            name="productId"
            className="form-select-sm mb-1"
            options={handleOptionsProduct}
            value={handleOptionsProduct.find((c) => {
              if (c.value === productId) {
                return c.label;
              }
            })}
            onChange={handleChangeProduct(index)}
            // onChange={(e) => handleChange(index, e)}
          />
        </td>
        <td>
          <Select
            name="transId"
            className="form-select-sm mb-1"
            options={handleOptionsTypology}
            value={handleOptionsTypology.find((c) => {
              if (c.value === typologyId) {
                return c.label;
              }
            })}
            onChange={handleChangeTypology(index)}
            // onChange={(e) => handleChange(index, e)}
          />
        </td>
        <td>
          <Select
            name="typology_1"
            className="form-select-sm mb-1"
            options={handleOptionsTypology_1}
            value={handleOptionsTypology_1.find((c) => {
              if (c.value === typology_1) {
                return c.label;
              }
            })}
            onChange={handleChangeTypology_1(index)}
            // onChange={(e) => handleChange(index, e)}
          />
        </td>
        <td>
          <input
            type="text"
            value={m2_limit}
            onChange={(e) => handleChange(index, e)}
            name="m2_limit"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="text"
            value={extra_cost}
            onChange={(e) => handleChange(index, e)}
            name="extra_cost"
            className="form-control"
          />
        </td>
        <td>
          <button
            className="btn btn-outline-danger"
            onClick={() => deleteTableRows(index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
};

export default TableRowsExtraCost;
