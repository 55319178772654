import React from "react";
import { Navigate } from "react-router-dom";
import FavoriteProductsList from "../components/favoriteProducts/FavoriteProductsList";

const FavoriteProducts = () => {
  const isLoggedin = !!localStorage.getItem("token");
  
  return (
    <section>
      {isLoggedin && <FavoriteProductsList />}
      {!isLoggedin && <Navigate to="/login" />}
    </section>
  );
};

export default FavoriteProducts;
