import { fetchWrapper } from "../_helpers/fetch-wrapper";
import config from "../conf/Config.json";

let apiUrl;
if (process.env.REACT_APP_ENV === 'production') {
  apiUrl = `https://${config.production.api_id}.execute-api.${config.production.region}.amazonaws.com`;
} else if (process.env.REACT_APP_ENV === 'nikos-aws') {
  apiUrl = `https://${config["nikos-aws"].api_id}.execute-api.${config["nikos-aws"].region}.amazonaws.com`;
} else {
  apiUrl = '';
}
const baseUrl = `${apiUrl}/offers`;

const getAll = () => {
    return fetchWrapper.get(baseUrl);
};

const getById = (offer_key) => {
    return fetchWrapper.get(`${baseUrl}/id?offer_key=${offer_key}`);
};

const create = (params) => {
    return fetchWrapper.post(`${baseUrl}/registration`, params);
};

const update = (offer_key, params) => {
    return fetchWrapper.put(`${baseUrl}/edit?offer_key=${offer_key}`, params);
};

const _delete = (offer_key) => {
    return fetchWrapper.delete(`${baseUrl}/delete?offer_key=${offer_key}`);
};

export const offersService = {
    getAll,
    getById,
    create,
    update,
    delete: _delete,
};
