import React, { useState, useEffect } from "react";
import { productsService } from "../../_services/productsService";
import { Link } from "react-router-dom";
import { offerProductsService } from "../../_services/offerProductsService";

const OfferProductsList = (props) => {
  const [products, setProducts] = useState([]);
  // const [hasResult, setHasResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sorting, setSorting] = useState({
    key: "offer_product_code",
    ascending: false,
  });
  // const [currentData, setCurrentData] = useState([]);
  const isAddEditOfferMode = props.isAddEditOffer ? true : false;
  const showAmount = props.showAmount ? true : false;

  useEffect(() => {
    console.log("Offer Key!");
    // console.log(props.offer_key);
    // console.log(props.offerPass);
    if (props.offer_key) {
      setIsLoading(true);
      // Error Handling
      offerProductsService
        .getByOffer(props.offer_key, props.lang)
        .then((data) => {
          console.log(data);
          setProducts(data);
          applySorting("offer_product_code", !sorting.ascending);          {
            !isAddEditOfferMode && props.handleLoading(false);
          }
          // props.getAllOfferProducts(data);
        });
      setIsLoading(false);
    }
  }, [props.lang]);
  // };

  useEffect(() => {
    // console.log(products);
    if (products.length > 0) {
      const currentDataCopy = [...products];
      // console.log(currentDataCopy);
      const sortedCurrentData = currentDataCopy.sort((a, b) => {
        return a[sorting.key].localeCompare(b[sorting.key]);
      });

      setProducts(
        sorting.ascending ? sortedCurrentData : sortedCurrentData.reverse()
      );
      // console.log(sortedCurrentData);
      props.getAllOfferProducts(sortedCurrentData);
    }
  }, [sorting]);

  const applySorting = (key, ascending) => {
    // console.log(products);
    setSorting({ key: key, ascending: ascending });
  };

  const _delete = (offer_product_key) => {
    setProducts(
      products.map((x) => {
        if (x.offer_product_key === offer_product_key) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    offerProductsService.delete(offer_product_key).then(() => {
      setProducts((products) =>
        products.filter((x) => x.offer_product_key !== offer_product_key)
      );
    });
  };

  const handleW = (x, y) => {
    x = parseInt(x);
    y = parseInt(y);
    const img_ration = x / y;
    // console.log("W x" + x);
    // console.log("W y" + y);
    let fixH = 400;
    let fixW = 400;
    if (y >= x) {
      if (y > fixH) {
        y = fixH;
        x = y * img_ration;
        // console.log("x" + x)
        return [x, y];
      } else {
        x = y * img_ration;
        // console.log("x" + x)
        return [x, y];
      }
    } else {
      if (x > fixW) {
        x = fixW;
        y = x / img_ration;
        // console.log("x" + x)
        return [x, y];
      } else {
        y = x / img_ration;
        // console.log("x" + x)
        return [x, y];
      }
    }
  };

  const handleH = (x, y) => {
    x = parseInt(x);
    y = parseInt(y);
    const img_ration = x / y;
    let fixH = 400;
    let fixW = 400;
    if (y >= x) {
      if (y > fixH) {
        y = fixH;
        x = y * img_ration;
        // console.log(`1. x: ${x}, y: ${y}`);
        return [x, y];
      } else {
        x = y * img_ration;
        // console.log(`2. x: ${x}, y: ${y}`);
        return [x, y];
      }
    } else {
      if (x > fixW) {
        x = fixW;
        y = x / img_ration;
        // console.log(`3. x: ${x}, y: ${y}`);
        return [x, y];
      } else {
        y = x / img_ration;
        // console.log(`4. x: ${x}, y: ${y}`);
        return [x, y];
      }
    }
  };

  return (
    <section>
      {isAddEditOfferMode && <h3>Λίστα Προϊόντων</h3>}
      {/* <button onClick={fetchData} className="btn btn-sm btn-primary mb-3">
        Εμφάνιση Δεδομένων
      </button> */}
      {/* {hasResult && ( */}
      <div className="table-responsive">
        <table className="table table-striped table-hover table-bordered table-sm">
          <thead className="thead-light">
            <tr>
              <th
                style={{ width: "5%" }}
                onClick={() =>
                  applySorting("offer_product_code", !sorting.ascending)
                }
              >
                Α/Α
              </th>
              <th style={{ width: "25%" }}>
                {props.lang === "el" && "Εικόνα"}
                {props.lang === "en" && "Image"}
                {props.lang === "it" && "Italy"}
              </th>
              <th style={{ width: "25%" }}>
                {props.lang === "el" && "Προϊόντα"}
                {props.lang === "en" && "Product"}
                {props.lang === "it" && "Italy"}
              </th>
              <th style={{ width: "5%" }}>
                {props.lang === "el" && "Τεμ."}
                {props.lang === "en" && "Quantity"}
                {props.lang === "it" && "Italy"}
              </th>
              <th style={{ width: "10%" }}>
                {props.lang === "el" && "Διαστάσεις"}
                {props.lang === "en" && "Dimensions"}
                {props.lang === "it" && "Italy"}
              </th>
              {isAddEditOfferMode ? (
                <th style={{ width: "10%" }}>
                  {props.lang === "el" && "Τιμή"}
                  {props.lang === "en" && "Price"}
                  {props.lang === "it" && "Italy"}
                </th>
              ) : showAmount ? (
                <th style={{ width: "10%" }}>
                  {props.lang === "el" && "Τιμή"}
                  {props.lang === "en" && "Price"}
                  {props.lang === "it" && "Italy"}
                </th>
              ) : (
                <th style={{ display: "none" }}></th>
              )}
              {isAddEditOfferMode ? (
                <th style={{ width: "10%" }}>
                  {props.lang === "el" && "Σύνολο"}
                  {props.lang === "en" && "Total"}
                  {props.lang === "it" && "Italy"}
                </th>
              ) : showAmount ? (
                <th style={{ width: "10%" }}>
                  {props.lang === "el" && "Σύνολο"}
                  {props.lang === "en" && "Total"}
                  {props.lang === "it" && "Italy"}
                </th>
              ) : (
                <th style={{ display: "none" }}></th>
              )}
              {isAddEditOfferMode ? (
                <th style={{ width: "10%" }}>
                  <Link
                    to="add"
                    className="btn btn-sm btn-success mb"
                    state={{
                      offer_key: props.offer_key,
                      offerPass: props.offerPass,
                    }}
                  >
                    Δημιουργία
                  </Link>
                </th>
              ) : (
                <th style={{ display: "none" }}></th>
              )}
            </tr>
          </thead>
          <tbody className="align-middle">
            {products &&
              products.map((product) => (
                <tr key={product.offer_product_key}>
                  <td>
                    {product.offer_product_code !== "None"
                      ? product.offer_product_code
                      : "-"}
                  </td>
                  <td style={{ width: "400px", height: "400px" }}>
                    <img
                      src={product.img}
                      style={{
                        width: `${handleH(product.x, product.y)[0]}px`,
                        height: `${handleH(product.x, product.y)[1]}px`,
                      }}
                    />
                  </td>
                  <td>
                    {product.product_name && (
                      <p className="mb-0">{product.product_name.value}</p>
                    )}
                    {product.typology && (
                      <p className="mb-0">{product.typology.value}</p>
                    )}
                    {product.typology_1 && (
                      <p className="mb-0">{product.typology_1.value}</p>
                    )}
                    {product.typology_2 && (
                      <p className="mb-0">{product.typology_2.value}</p>
                    )}
                    {product.typology_3 && (
                      <p className="mb-0">{product.typology_3.value}</p>
                    )}
                    {product.typology_4 && (
                      <p className="mb-0">{product.typology_4.value}</p>
                    )}
                    {product.typology_5 && (
                      <p className="mb-0">{product.typology_5.value}</p>
                    )}
                    {product.typology_6 && (
                      <p className="mb-0">{product.typology_6.value}</p>
                    )}
                    <br /> {/*separate products from characteristics*/}
                    {product.extra_yalo_1 && (
                      <p className="mb-0">{product.extra_yalo_1.value}</p>
                    )}
                    {product.extra_yalo_2 && (
                      <p className="mb-0">{product.extra_yalo_2.value}</p>
                    )}
                    {product.extra_yalo_3 && (
                      <p className="mb-0">{product.extra_yalo_3.value}</p>
                    )}
                    {product.extra_yalo_4 && (
                      <p className="mb-0">{product.extra_yalo_4.value}</p>
                    )}
                    {product.extra_yalo_5 && (
                      <p className="mb-0">{product.extra_yalo_5.value}</p>
                    )}
                    {product.extra_yalo_6 && (
                      <p className="mb-0">{product.extra_yalo_6.value}</p>
                    )}
                    {product.extra_yalo_7 && (
                      <p className="mb-0">{product.extra_yalo_7.value}</p>
                    )}
                    {product.extra_yalo_8 && (
                      <p className="mb-0">{product.extra_yalo_8.value}</p>
                    )}
                    {product.extra_yalo_9 && (
                      <p className="mb-0">{product.extra_yalo_9.value}</p>
                    )}
                    {product.extra_yalo_10 && (
                      <p className="mb-0">{product.extra_yalo_10.value}</p>
                    )}
                    {product.extra_yalo_11 && (
                      <p className="mb-0">{product.extra_yalo_11.value}</p>
                    )}
                    {product.extra_yalo_12 && (
                      <p className="mb-0">{product.extra_yalo_12.value}</p>
                    )}
                    {product.extra_patzoy_1_1 && (
                      <p className="mb-0">{product.extra_patzoy_1_1.value}</p>
                    )}
                    {product.extra_patzoy_1_2 && (
                      <p className="mb-0">{product.extra_patzoy_1_2.value}</p>
                    )}
                    {product.extra_patzoy_2 && (
                      <p className="mb-0">{product.extra_patzoy_2.value}</p>
                    )}
                    {product.extra_patzoy_3 && (
                      <p className="mb-0">{product.extra_patzoy_3.value}</p>
                    )}
                    {product.extra_patzoy_4 && (
                      <p className="mb-0">{product.extra_patzoy_4.value}</p>
                    )}
                    {product.extra_patzoy_5 && (
                      <p className="mb-0">{product.extra_patzoy_5.value}</p>
                    )}
                    {product.extra_patzoy_6 && (
                      <p className="mb-0">{product.extra_patzoy_6.value}</p>
                    )}
                    {product.extra_patzoy_7 && (
                      <p className="mb-0">{product.extra_patzoy_7.value}</p>
                    )}
                  </td>
                  <td>
                    {product.quantity !== "None" ? product.quantity : "-"}
                  </td>
                  <td>
                    {product.x !== "None" ? product.x : "-"}x
                    {product.y !== "None" ? product.y : "-"}
                  </td>
                  {isAddEditOfferMode ? (
                    <td>
                      {product.unit_amount && (
                        <p className="mt-2">
                          {product.total_unit_amount
                            ? product.total_unit_amount
                            : product.unit_amount}
                          €
                        </p>
                      )}
                      {product.typology && <br />}
                      {product.typology_1 && <br />}
                      {product.typology_2 && <br />}
                      {product.typology_3 && <br />}
                      {product.typology_4 && <br />}
                      {product.typology_5 && <br />}
                      {product.typology_6 && <br />}
                      {/*separate products from characteristics*/}
                      {product.extra_yalo_1 && (
                        <p className="mb-0">{product.extra_yalo_1_amount_with_charge ? product.extra_yalo_1_amount_with_charge: product.extra_yalo_1_amount} €</p>
                      )}
                      {product.extra_yalo_2 && (
                        <p className="mb-0">{product.extra_yalo_2_amount_with_charge ? product.extra_yalo_2_amount_with_charge: product.extra_yalo_2_amount} €</p>
                      )}
                      {product.extra_yalo_3 && (
                        <p className="mb-0">{product.extra_yalo_3_amount_with_charge ? product.extra_yalo_3_amount_with_charge: product.extra_yalo_3_amount} €</p>
                      )}
                      {product.extra_yalo_4 && (
                        <p className="mb-0">{product.extra_yalo_4_amount_with_charge ? product.extra_yalo_4_amount_with_charge: product.extra_yalo_4_amount} €</p>
                      )}
                      {product.extra_yalo_5 && (
                        <p className="mb-0">{product.extra_yalo_5_amount_with_charge ? product.extra_yalo_5_amount_with_charge: product.extra_yalo_5_amount} €</p>
                      )}
                      {product.extra_yalo_6 && (
                        <p className="mb-0">{product.extra_yalo_6_amount_with_charge ? product.extra_yalo_6_amount_with_charge: product.extra_yalo_6_amount} €</p>
                      )}
                      {product.extra_yalo_7 && (
                        <p className="mb-0">{product.extra_yalo_7_amount_with_charge ? product.extra_yalo_7_amount_with_charge: product.extra_yalo_7_amount} €</p>
                      )}
                      {product.extra_yalo_8 && (
                        <p className="mb-0">{product.extra_yalo_8_amount_with_charge ? product.extra_yalo_8_amount_with_charge: product.extra_yalo_8_amount} €</p>
                      )}
                      {product.extra_yalo_9 && (
                        <p className="mb-0">{product.extra_yalo_9_amount_with_charge ? product.extra_yalo_9_amount_with_charge: product.extra_yalo_9_amount} €</p>
                      )}
                      {product.extra_yalo_10 && (
                        <p className="mb-0">{product.extra_yalo_10_amount_with_charge ? product.extra_yalo_10_amount_with_charge: product.extra_yalo_10_amount} €</p>
                      )}
                      {product.extra_yalo_11 && (
                        <p className="mb-0">{product.extra_yalo_11_amount_with_charge ? product.extra_yalo_11_amount_with_charge: product.extra_yalo_11_amount} €</p>
                      )}
                      {product.extra_yalo_12 && (
                        <p className="mb-0">{product.extra_yalo_12_amount_with_charge ? product.extra_yalo_12_amount_with_charge: product.extra_yalo_12_amount} €</p>
                      )}
                      {product.extra_patzoy_1_1 && (
                        <p className="mb-0">
                          {product.extra_patzoy_1_1_amount_with_charge ? product.extra_patzoy_1_1_amount_with_charge: product.extra_patzoy_1_1_amount} €
                        </p>
                      )}
                      {product.extra_patzoy_1_2 && (
                        <p className="mb-0">
                          {product.extra_patzoy_1_2_amount_with_charge ? product.extra_patzoy_1_2_amount_with_charge: product.extra_patzoy_1_2_amount} €
                        </p>
                      )}
                      {product.extra_patzoy_2 && (
                        <p className="mb-0">
                          {product.extra_patzoy_2_amount_with_charge ? product.extra_patzoy_2_amount_with_charge: product.extra_patzoy_2_amount} €
                        </p>
                      )}
                      {product.extra_patzoy_3 && (
                        <p className="mb-0">
                          {product.extra_patzoy_3_amount_with_charge ? product.extra_patzoy_3_amount_with_charge: product.extra_patzoy_3_amount} €
                        </p>
                      )}
                      {product.extra_patzoy_4 && (
                        <p className="mb-0">
                          {product.extra_patzoy_4_amount_with_charge ? product.extra_patzoy_4_amount_with_charge: product.extra_patzoy_4_amount} €
                        </p>
                      )}
                      {product.extra_patzoy_5 && (
                        <p className="mb-0">
                          {product.extra_patzoy_5_amount_with_charge ? product.extra_patzoy_5_amount_with_charge: product.extra_patzoy_5_amount} €
                        </p>
                      )}
                      {product.extra_patzoy_6 && (
                        <p className="mb-0">
                          {product.extra_patzoy_6_amount_with_charge ? product.extra_patzoy_6_amount_with_charge: product.extra_patzoy_6_amount} €
                        </p>
                      )}
                      {product.extra_patzoy_7 && (
                        <p className="mb-0">
                          {product.extra_patzoy_7_amount_with_charge ? product.extra_patzoy_7_amount_with_charge: product.extra_patzoy_7_amount} €
                        </p>
                      )}
                    </td>
                  ) : showAmount ? (
                    <td>
                      {product.unit_amount && (
                        <p className="mt-2">
                          {product.total_unit_amount
                            ? product.total_unit_amount
                            : product.unit_amount}
                          €
                        </p>
                      )}
                      {product.typology && <br />}
                      {product.typology_1 && <br />}
                      {product.typology_2 && <br />}
                      {product.typology_3 && <br />}
                      {product.typology_4 && <br />}
                      {product.typology_5 && <br />}
                      {product.typology_6 && <br />}
                      <br /> {/*separate products from characteristics*/}
                      {product.extra_yalo_1 && (
                        <p className="mb-0">{product.extra_yalo_1_amount_with_charge ? product.extra_yalo_1_amount_with_charge: product.extra_yalo_1_amount} €</p>
                      )}
                      {product.extra_yalo_2 && (
                        <p className="mb-0">{product.extra_yalo_2_amount_with_charge ? product.extra_yalo_2_amount_with_charge: product.extra_yalo_2_amount} €</p>
                      )}
                      {product.extra_yalo_3 && (
                        <p className="mb-0">{product.extra_yalo_3_amount_with_charge ? product.extra_yalo_3_amount_with_charge: product.extra_yalo_3_amount} €</p>
                      )}
                      {product.extra_yalo_4 && (
                        <p className="mb-0">{product.extra_yalo_4_amount_with_charge ? product.extra_yalo_4_amount_with_charge: product.extra_yalo_4_amount} €</p>
                      )}
                      {product.extra_yalo_5 && (
                        <p className="mb-0">{product.extra_yalo_5_amount_with_charge ? product.extra_yalo_5_amount_with_charge: product.extra_yalo_5_amount} €</p>
                      )}
                      {product.extra_yalo_6 && (
                        <p className="mb-0">{product.extra_yalo_6_amount_with_charge ? product.extra_yalo_6_amount_with_charge: product.extra_yalo_6_amount} €</p>
                      )}
                      {product.extra_yalo_7 && (
                        <p className="mb-0">{product.extra_yalo_7_amount_with_charge ? product.extra_yalo_7_amount_with_charge: product.extra_yalo_7_amount} €</p>
                      )}
                      {product.extra_yalo_8 && (
                        <p className="mb-0">{product.extra_yalo_8_amount_with_charge ? product.extra_yalo_8_amount_with_charge: product.extra_yalo_8_amount} €</p>
                      )}
                      {product.extra_yalo_9 && (
                        <p className="mb-0">{product.extra_yalo_9_amount_with_charge ? product.extra_yalo_9_amount_with_charge: product.extra_yalo_9_amount} €</p>
                      )}
                      {product.extra_yalo_10 && (
                        <p className="mb-0">{product.extra_yalo_10_amount_with_charge ? product.extra_yalo_10_amount_with_charge: product.extra_yalo_10_amount} €</p>
                      )}
                      {product.extra_yalo_11 && (
                        <p className="mb-0">{product.extra_yalo_11_amount_with_charge ? product.extra_yalo_11_amount_with_charge: product.extra_yalo_11_amount} €</p>
                      )}
                      {product.extra_yalo_12 && (
                        <p className="mb-0">{product.extra_yalo_12_amount_with_charge ? product.extra_yalo_12_amount_with_charge: product.extra_yalo_12_amount} €</p>
                      )}
                      {product.extra_patzoy_1_1 && (
                        <p className="mb-0">
                          {product.extra_patzoy_1_1_amount_with_charge ? product.extra_patzoy_1_1_amount_with_charge: product.extra_patzoy_1_1_amount} €
                        </p>
                      )}
                      {product.extra_patzoy_1_2 && (
                        <p className="mb-0">
                          {product.extra_patzoy_1_2_amount_with_charge ? product.extra_patzoy_1_2_amount_with_charge: product.extra_patzoy_1_2_amount} €
                        </p>
                      )}
                      {product.extra_patzoy_2 && (
                        <p className="mb-0">
                          {product.extra_patzoy_2_amount_with_charge ? product.extra_patzoy_2_amount_with_charge: product.extra_patzoy_1_2_amount} €
                        </p>
                      )}
                      {product.extra_patzoy_3 && (
                        <p className="mb-0">
                          {product.extra_patzoy_3_amount_with_charge ? product.extra_patzoy_3_amount_with_charge: product.extra_patzoy_3_amount} €
                        </p>
                      )}
                      {product.extra_patzoy_4 && (
                        <p className="mb-0">
                          {product.extra_patzoy_4_amount_with_charge ? product.extra_patzoy_4_amount_with_charge: product.extra_patzoy_4_amount} €
                        </p>
                      )}
                      {product.extra_patzoy_5 && (
                        <p className="mb-0">
                          {product.extra_patzoy_5_amount_with_charge ? product.extra_patzoy_5_amount_with_charge: product.extra_patzoy_5_amount} €
                        </p>
                      )}
                      {product.extra_patzoy_6 && (
                        <p className="mb-0">
                          {product.extra_patzoy_6_amount_with_charge ? product.extra_patzoy_6_amount_with_charge: product.extra_patzoy_6_amount} €
                        </p>
                      )}
                      {product.extra_patzoy_7 && (
                        <p className="mb-0">
                          {product.extra_patzoy_7_amount_with_charge ? product.extra_patzoy_7_amount_with_charge: product.extra_patzoy_7_amount} €
                        </p>
                      )}
                    </td>
                  ) : (
                    <td style={{ display: "none" }}></td>
                  )}
                  {isAddEditOfferMode ? (
                    <td>
                      <div className="justify-content-center">
                        {product.total_unit_amount === '0' ? 0 : product.total_amount}
                        {product.total_amount ? "€" : ""}
                      </div>
                      {product.typology && <br />}
                      {product.typology_1 && <br />}
                      {product.typology_2 && <br />}
                      <br /> {/*separate products from characteristics*/}
                      <div className="justify-content-center">
                        {(product.total_char_amount && product.total_char_amount !== '0') ? product.total_char_amount : null}
                        {product.total_char_amount && product.total_char_amount !== '0' ? "€" : ""}
                      </div>
                    </td>
                  ) : showAmount ? (
                    <td>
                      <div className="justify-content-center">
                        {product.total_unit_amount === '0' ? 0 : product.total_amount}
                        {product.total_amount ? "€" : ""}
                      </div>
                      {product.typology && <br />}
                      {product.typology_1 && <br />}
                      {product.typology_2 && <br />}
                      <br /> {/*separate products from characteristics*/}
                      <div className="justify-content-center">
                        {(product.total_char_amount && product.total_char_amount !== '0') ? product.total_char_amount : null}
                        {product.total_char_amount && product.total_char_amount !== '0' ? "€" : ""}
                      </div>
                    </td>
                  ) : (
                    <td style={{ display: "none" }}></td>
                  )}
                  {isAddEditOfferMode ? (
                    <td style={{ whiteSpace: "nowrap" }}>
                      <div className="justify-content-center">
                        {product.fav && (
                          <p className="bg-warning mb-0">Αγαπημένο</p>
                        )}
                        {/* {!product.fav && ( */}
                        <Link
                          to={`edit/${product.product_key}`}
                          className="btn btn-sm btn-primary me-1"
                          state={{
                            offer_key: props.offer_key,
                            offerPass: props.offerPass,
                            favPass: product.fav,
                            imgPass: product.img,
                          }}
                        >
                          Επεξεργασία
                        </Link>
                        {/* )} */}
                        {/* {product.fav && (
                          <button
                            onClick={() => _delete(product.offer_product_key)}
                            className="btn btn-sm btn-primary me-1"
                            disabled={product.fav}
                          >
                            Επεξεργασία
                          </button>
                        )} */}
                        {/* {!product.fav && ( */}
                        <button
                          onClick={() => _delete(product.offer_product_key)}
                          className="btn btn-sm btn-danger btn-delete-user"
                          // disabled={product.isDeleting || product.fav}
                        >
                          {product.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                        {/* )} */}
                      </div>
                      <div className="mt-2">
                        <Link
                          to={`edit/dimensions/${product.offer_product_key}`}
                          className="btn btn-sm btn-outline-secondary me-1"
                          state={{
                            offer_key: props.offer_key,
                            offerPass: props.offerPass,
                            productNamePass:
                              product.product_name &&
                              product.product_name.value,
                            productTypologyPass:
                              product.typology && product.typology.value,
                            productFyllaPass:
                              product.typology_1 && product.typology_1.value,
                            productOpenPass:
                              product.typology_2 && product.typology_2.value,
                            product_key: product.product_key,
                          }}
                        >
                          Μορφοποίηση
                        </Link>
                        <Link
                          to={`edit/specifics/${product.offer_product_key}`}
                          className="btn btn-sm btn-outline-secondary me-1"
                          state={{
                            offer_key: props.offer_key,
                            offerPass: props.offerPass,
                            productNamePass:
                              product.product_name &&
                              product.product_name.value,
                            productTypologyPass:
                              product.typology && product.typology.value,
                            productFyllaPass:
                              product.typology_1 && product.typology_1.value,
                            productOpenPass:
                              product.typology_2 && product.typology_2.value,
                            product_key: product.product_key,
                          }}
                        >
                          Χαρακτηριστικά
                        </Link>
                      </div>
                    </td>
                  ) : (
                    <td style={{ display: "none" }}></td>
                  )}
                </tr>
              ))}
            {isLoading && (
              <tr>
                <td colSpan="100%" className="text-center">
                  <div className="spinner-border spinner-border-lg align-center"></div>
                </td>
              </tr>
            )}
            {!products && !isLoading && (
              <tr>
                <td colSpan="100%" className="text-center">
                  Δεν υπάρχουν προιόντα για αυτή τη προσφορά adada
                </td>
              </tr>
            )}
            {!isLoading && products && products.length === 0 && (
              <tr>
                <td colSpan="100%" className="text-center">
                  <div className="p-2">
                    Δεν υπάρχουν προιόντα για αυτή τη προσφορά
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* )} */}
    </section>
  );
};

export default OfferProductsList;
