import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import AddDeleteTableRowsExtraCost from "../../_helpers/add-delete-table-rows/AddDeleteTableRowsExtraCost";
import { costingService } from "../../_services/costingService";

const Costing = () => {
  const [pageState, setPageState] = useState({
    work_hour_cost: "",
    per_product_cost: "",
    palete_cost: "",
    area_1: "",
    area_2: "",
    area_3: "",
    area_4: "",
    area_5: "",
    options: "",
    isLoading: false,
    error: "",
  });

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, setValue, formState } = useForm();

  const onSubmit = (data) => {
    console.log("Save button sends data: " + JSON.stringify(data));
    setPageState({
      ...pageState,
      isLoading: true,
      error: "",
    });
    // console.log("username" + username);
    costingService.update(data).then((data) => {
      // console.log('Updating');
      // console.log("Resp" + JSON.stringify(data));
      if (data.message === undefined || data.message === null) {
        setPageState({
          ...pageState,
          isLoading: false,
          error: "",
        });
      } else {
        setPageState({
          ...pageState,
          isLoading: false,
          error: data.message,
        });
      }
    });
  };

  const handleReset = () => {
    setPageState({
      ...pageState,
      isLoading: true,
      error: "",
    });
    // const params = {
    //   work_hour_cost: "23.75",
    //   per_product_cost: "18",
    //   area_1: "0",
    //   area_2: "4",
    //   area_3: "8",
    //   area_4: "14",
    //   area_5: "16",
    // };
    costingService.reset().then((data) => {
      const fields = [
        "work_hour_cost",
        "per_product_cost",
        "palete_cost",
        "area_1",
        "area_2",
        "area_3",
        "area_4",
        "area_5",
      ];
      fields.forEach((field) => setValue(field, data[field]));
      // console.log('Reseting');
      // console.log("Resp" + JSON.stringify(data));
      if (data.message === undefined || data.message === null) {
        setPageState({
          ...pageState,
          isLoading: false,
          error: "",
        });
      } else {
        setPageState({
          ...pageState,
          isLoading: false,
          error: data.message,
        });
      }
    });
  };

  useEffect(() => {
    setPageState({
      ...pageState,
      isLoading: true,
      error: "",
    });
    // get user and set form fields
    costingService.getAll().then((data) => {
      const fields = [
        "work_hour_cost",
        "per_product_cost",
        "palete_cost",
        "area_1",
        "area_2",
        "area_3",
        "area_4",
        "area_5",
      ];
      console.log(data);
      fields.forEach((field) => setValue(field, data[field]));
      if (data.message === undefined || data.message === null) {
        setPageState({
          ...pageState,
          isLoading: false,
          error: "",
        });
      } else {
        setPageState({
          ...pageState,
          isLoading: false,
          error: data.message,
        });
      }
    });
  }, []);

  return (
    <section>
      <h1>Βασικά στοιχεία Κοστολόγησης</h1>
      <form
        className="row g-1"
        // style={{ width: "100%" }}
        onSubmit={handleSubmit(onSubmit)}
        onReset={reset}
      >
        <div className="row mb-3 justify-content-center">
          <div className="col-md-3">
            <label className="form-label">Εργατοώρα</label>
            <input
              type="text"
              {...register("work_hour_cost")}
              placeholder="23.75"
              className="form-control"
            />
          </div>
          <div className="col-md-3">
            <label className="form-label">Κόστος Υλικών</label>
            <input
              type="text"
              {...register("per_product_cost")}
              placeholder="18"
              className="form-control"
            />
          </div>
          <div className="col-md-3">
            <label className="form-label">Κόστος τμ νταλίκας</label>
            <input
              type="text"
              {...register("palete_cost")}
              placeholder="150"
              className="form-control"
            />
          </div>
        </div>
        <div className="shadow-lg p-4 mb-3 bg-body rounded">
          <div className="row g-2 mb-1 justify-content-center">
            <div className="col-sm-2">
              <label className="col-form-label">Περιοχή 1</label>
            </div>
            <div className="col-sm-2">
              <input
                type="number"
                {...register("area_1")}
                placeholder="0"
                className="form-control"
              />
            </div>
            <div className="col-sm-8">
              <span className="form-text">
                Επιβάρυνση σε ώρες, χρόνου ταξιδιού για Αθήνα ανά άτομο
              </span>
            </div>
          </div>
          <div className="row g-2 mb-1 justify-content-center">
            <div className="col-sm-2">
              <label className="col-form-label">Περιοχή 2</label>
            </div>
            <div className="col-sm-2">
              <input
                type="number"
                {...register("area_2")}
                placeholder="4"
                className="form-control"
              />
            </div>
            <div className="col-sm-8">
              <span className="form-text">
                Επιβάρυνση σε ώρες, χρόνου ταξιδιού για οικοδομές στην
                Πελοπόννησο ανά άτομο
              </span>
            </div>
          </div>
          <div className="row g-2 mb-1 justify-content-center">
            <div className="col-sm-2">
              <label className="col-form-label">Περιοχή 3</label>
            </div>
            <div className="col-sm-2">
              <input
                type="number"
                {...register("area_3")}
                placeholder="8"
                className="form-control"
              />
            </div>
            <div className="col-sm-8">
              <span className="form-text">
                Επιβάρυνση σε ώρες, χρόνου ταξιδιού για οικοδομές στην Κέα,
                Κύθνο, Αίγινα, Άνδρο Σκύρο ανά άτομο.
              </span>
            </div>
          </div>
          <div className="row g-2 mb-1 justify-content-center">
            <div className="col-sm-2">
              <label className="col-form-label">Περιοχή 4</label>
            </div>
            <div className="col-sm-2">
              <input
                type="number"
                {...register("area_4")}
                placeholder="14"
                className="form-control"
              />
            </div>
            <div className="col-sm-8">
              <span className="form-text">
                Επιβάρυνση σε ώρες, χρόνου ταξιδιού για οικοδομές στην Ύδρα,
                Σπέτσες, Τήνο Σύρος ανά άτομο
              </span>
            </div>
          </div>
          <div className="row g-2 mb-1justify-content-center">
            <div className="col-sm-2">
              <label className="col-form-label">Περιοχή 5</label>
            </div>
            <div className="col-sm-2">
              <input
                type="number"
                {...register("area_5")}
                placeholder="16"
                className="form-control"
              />
            </div>
            <div className="col-sm-8">
              <span className="form-text">
                Επιβάρυνση σε ώρες, χρόνου ταξιδιού για οικοδομές στην Σέριφο,
                Σίφνο, Μήλο, Πάρο, Νάξο, Μύκονο ανά άτομο
              </span>
            </div>
          </div>
        </div>
        <div className="col-12">
          <button
            type="submit"
            disabled={formState.isSubmitting}
            className="btn btn-success"
          >
            {formState.isSubmitting && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            {!pageState.isLoading && "Αποθήκευση"}
            {pageState.isLoading && "Loading.."}
          </button>
          <button type="button" className="btn btn-link" onClick={handleReset}>
            Επαναφορά
          </button>
        </div>
        {pageState.error && `${pageState.error}`}
      </form>
      {/* Επιπλέον  extra Κόστη */}
      <div className="row mt-2 shadow p-1 bg-body rounded justify-content-center">
        <div className="col-sm-12">
          <h5 className="mb-0">Ώρες τοποθέτησης ανά τετραγωνικό κουφώματος</h5>
          <AddDeleteTableRowsExtraCost />
        </div>
      </div>
    </section>
  );
};

export default Costing;
