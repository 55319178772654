import { fetchWrapper } from "../_helpers/fetch-wrapper";
import config from "../conf/Config.json";

let apiUrl;

if (process.env.REACT_APP_ENV === 'production') {
  apiUrl = `https://${config.production.api_id}.execute-api.${config.production.region}.amazonaws.com`;
} else if (process.env.REACT_APP_ENV === 'nikos-aws') {
  apiUrl = `https://${config["nikos-aws"].api_id}.execute-api.${config["nikos-aws"].region}.amazonaws.com`;
} else {
  apiUrl = '';
}
console.log(apiUrl);
const baseUrl = `${apiUrl}/users`;
const getAll = () => {
  return fetchWrapper.get(baseUrl);
};

const getById = (username) => {
  return fetchWrapper.get(`${baseUrl}/username?username=${username}`);
};

const create = (params) => {
  return fetchWrapper.post(`${baseUrl}/signup`, params);
};

const update = (username, params) => {
  console.log(`${baseUrl}/edit?username=${username}`);
  console.log(params);
  return fetchWrapper.put(`${baseUrl}/edit?username=${username}`, params);
};

// prefixed with underscored because delete is a reserved word in javascript
const _delete = (username) => {
  console.log(`Delete user with username=${username}`);
  return fetchWrapper.delete(`${baseUrl}/delete?username=${username}`);
};

const login = (creds) => {
  const api_resp = fetchWrapper.post(`${apiUrl}/login`, creds).then((data) => {
    // console.log("Login response data: " + JSON.stringify(data));
    if (data.message === undefined || data.message === null) {
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", data.username);
      return true;
    } else {
      return data.message;
    }
  });
  // console.log("Login response api_resp: " + JSON.stringify(api_resp));
  return api_resp;
};

const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

export const userService = {
  getAll,
  getById,
  create,
  update,
  delete: _delete,
  login,
  logout,
};
