import React from "react";
import { Navigate } from "react-router-dom";
import OffersList from "../components/offers/OffersList";

const OffersPage = () => {
  const isLoggedin = !!localStorage.getItem("token");
  
  return (
    <section>
      {isLoggedin && <OffersList />}
      {!isLoggedin && <Navigate to="/login" />}
    </section>
  );
};

export default OffersPage;
