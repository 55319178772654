import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import OfferProductsList from "./OfferProductsList";
import TextArea from "textarea-autosize-reactjs";
import config from "../../conf/Config";
import { offerCostingService } from "../../_services/offerCostingService";

const OfferInspection = () => {
  const isEl = config.lang.hellenic;
  const isEn = config.lang.english;
  const isIt = config.lang.italian;
  const location = useLocation();
  const { offerProducts, offer_key, offerPass } = location.state;
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [lang, setLang] = useState(isEl);
  const [showAmount, setShowAmount] = useState(true);
  const [showCategories, setShowCategories] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [groupedProducts, setGroupedProducts] = useState({});

  // console.log(products);
  console.log(offerPass.costsDescPass);
  // console.log(offerPass.costsAmountPass);
  // console.log(offerPass.chargesDescPass);
  console.log(offerPass.chargesAmountPass);

  const getOfferCostingById = () => {
    setIsLoading(true);
    setError("");
    offerCostingService.getById(offer_key).then((data) => {
      console.log(data);
      if (data.message === undefined || data.message === null) {
        // console.log(data.grouped_products);
        setGroupedProducts(JSON.parse(data.grouped_products));
      }
      setIsLoading(false);
      setError(data.message);
    });
  };

  useEffect(() => {
    // console.log(offerProducts);
    getOfferCostingById();
    console.log(groupedProducts);
    // getAllProductNames();
  }, [offerProducts, lang]);

  useEffect(() => {
    if (groupedProducts !== {}) {
      console.log(groupedProducts);
    }
  }, [groupedProducts]);

  const createPdf = () => {
    const popupWin = window.open("nikos", "", "_blank"); //_blank
    const renderTable = document.getElementById("printable_content").innerHTML;
    var pdfContent = `
            <html>
                <head>
                    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" integrity="sha384-MCw98/SFnGE8fJT3GXwEOngsV7Zt27NXFoaoApmYm81iuXoPkFOJwJ8ERdknLPMO" crossorigin="anonymous">
                </head>
                <body onload="window.print();" class="align-middle" >
                    ${renderTable}
                </body>
            </html>
        `;
    popupWin.document.open();
    popupWin.document.write(pdfContent);
    popupWin.document.close();
  };

  const getAllOfferProducts = (allProducts) => {
    // console.log(allProducts);
    setProducts(allProducts);
  };

  const returnBack = () => {
    navigate(`/offers/edit/${offer_key}`);
  };

  const handleLoading = (data) => {
    setIsLoading(data);
    // console.log(isLoading);
  };

  return (
    <section>
      <div>
        <div className="d-flex justify-content-center">
          {showAmount ? (
            <button
              className="btn btn-sm btn-secondary me-3 mb-2"
              onClick={() => {
                setShowAmount(false);
              }}
            >
              Προσφορά χωρίς τιμές
            </button>
          ) : (
            <button
              className="btn btn-sm btn-secondary ms-3 mb-2"
              onClick={() => {
                setShowAmount(true);
              }}
            >
              Προσφορά με τιμές
            </button>
          )}

          {showCategories ? (
            <button
              className="btn btn-sm btn-secondary ms-3 mb-2"
              onClick={() => {
                setShowCategories(false);
              }}
            >
              Προσφορά χωρίς Κατηγορίες
            </button>
          ) : (
            <button
              className="btn btn-sm btn-secondary ms-3 mb-2"
              onClick={() => {
                setShowCategories(true);
              }}
            >
              Προσφορά με Κατηγορίες
            </button>
          )}
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-light me-5 mb-2"
            onClick={() => {
              setIsLoading(true);
              setLang(isEl);
            }}
          >
            EL
          </button>
          <button
            className="btn btn-light me-5 mb-2"
            onClick={() => {
              setIsLoading(true);
              setLang(isEn);
            }}
          >
            EN
          </button>
          <button
            className="btn btn-light me-5 mb-2"
            onClick={() => {
              setIsLoading(true);
              setLang(isIt);
            }}
          >
            IT
          </button>
        </div>
        {isLoading && "Loading..."}
        {error && error}
      </div>
      <section id="printable_content">
        {/* Header */}
        <div className="container mb-2 p-2 bg-light rounded justify-content-center">
          <div className="row">
            <div className="col-md-8">
              <img src={process.env.PUBLIC_URL + "/logo-sylor.png"} />
            </div>
            <div className="col-md-4">
              <p className="mb-1">
                {lang === isEl && "Αριθμός προσφοράς"}
                {lang === isEn && "Offer Num"}
                {lang === isIt && "Italy"}: {offerPass.offerIdPass}
              </p>
              <p>
                {lang === isEl && "Ημερομηνία προσφοράς"}
                {lang === isEn && "Date"}
                {lang === isIt && "Italy"}: {offerPass.offerDatePass}
              </p>
            </div>
          </div>
          <h5>
            {lang === isEl && "Γενικές Επιλογές"}
            {lang === isEn && "General Options"}
            {lang === isIt && "Italy"}
          </h5>
          <div className="row">
            <div className="col-md-8">
              {lang === isEl && (
                <TextArea
                  style={{ border: "none", resize: "none" }}
                  disabled
                  defaultValue={offerPass.offerInfoelPass}
                />
              )}
              {lang === isEn && (
                <TextArea
                  style={{ border: "none", resize: "none" }}
                  disabled
                  defaultValue={offerPass.offerInfoenPass}
                />
              )}
              {lang === isIt && (
                <TextArea
                  style={{ border: "none", resize: "none" }}
                  disabled
                  defaultValue={offerPass.offerInfoitPass}
                />
              )}
            </div>
            <div className="col-md-4">
              <p>
                {lang === isEl && "Προς"}
                {lang === isEn && "To"}
                {lang === isIt && "Italy"}: {offerPass.offerToPass}
              </p>
            </div>
          </div>
        </div>
        <section>
          {offer_key && (
            <OfferProductsList
              getAllOfferProducts={getAllOfferProducts}
              offer_key={offer_key}
              showAmount={showAmount}
              lang={lang}
              handleLoading={handleLoading}
              // offerPass={offerPass}
            />
          )}
        </section>
        {/* Footer */}
        {showCategories && (
          <div className="container mb-2 p-2 bg-light rounded justify-content-center">
            <h5 className="text-primary">
              {(lang === isEl && "Κατηγορίες Προϊόντων") ||
                (lang === isEn && "Product categories") ||
                (lang === isIt && "Categorie di Prodotto")}
            </h5>
            <table className="table table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "50%" }}>
                    {(lang === isEl && "Τύπος προϊόντος") ||
                      (lang === isEn && "Product type") ||
                      (lang === isIt && "Tipologia di prodotto")}
                  </th>
                  <th style={{ width: "20%" }}>
                    {(lang === isEl && "Τεμάχια") ||
                      (lang === isEn && "Pieces") ||
                      (lang === isIt && "Pezzi")}
                  </th>
                  <th style={{ width: "30%" }}>
                    {(lang === isEl && "Τιμή") ||
                      (lang === isEn && "Price") ||
                      (lang === isIt && "Prezzo")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Προϊόντα */}
                {Object.keys(groupedProducts).length > 0 &&
                  Object.keys(groupedProducts).map((key, index) => {
                    // console.log(key);
                    if (key === config.product_char_cat.cat_yalo.name) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.cat_yalo.el) ||
                              (lang === isEn &&
                                config.product_char_cat.cat_yalo.en) ||
                              (lang === isIt &&
                                config.product_char_cat.cat_yalo.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    } else if (key === config.product_char_cat.cat_patz.name) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.cat_patz.el) ||
                              (lang === isEn &&
                                config.product_char_cat.cat_patz.en) ||
                              (lang === isIt &&
                                config.product_char_cat.cat_patz.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    } else if (key === config.product_char_cat.cat_ejo.name) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.cat_ejo.el) ||
                              (lang === isEn &&
                                config.product_char_cat.cat_ejo.en) ||
                              (lang === isIt &&
                                config.product_char_cat.cat_ejo.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    } else if (key === config.product_char_cat.cat_rolo.name) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.cat_rolo.el) ||
                              (lang === isEn &&
                                config.product_char_cat.cat_rolo.en) ||
                              (lang === isIt &&
                                config.product_char_cat.cat_rolo.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    }
                  })}
                {/* Χαρακτηριστικά */}
                {Object.keys(groupedProducts).length > 0 &&
                  Object.keys(groupedProducts).map((key, index) => {
                    if (
                      key === config.product_char_cat.extra_yalo_1.name &&
                      groupedProducts[key].total_price > 0
                    ) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.extra_yalo_1.el) ||
                              (lang === isEn &&
                                config.product_char_cat.extra_yalo_1.en) ||
                              (lang === isIt &&
                                config.product_char_cat.extra_yalo_1.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    } else if (
                      key === config.product_char_cat.cat_sita.name &&
                      groupedProducts[key].total_price > 0
                    ) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.cat_sita.el) ||
                              (lang === isEn &&
                                config.product_char_cat.cat_sita.en) ||
                              (lang === isIt &&
                                config.product_char_cat.cat_sita.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    } else if (
                      key === config.product_char_cat.extra_yalo_3.name &&
                      groupedProducts[key].total_price > 0
                    ) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.extra_yalo_3.el) ||
                              (lang === isEn &&
                                config.product_char_cat.extra_yalo_3.en) ||
                              (lang === isIt &&
                                config.product_char_cat.extra_yalo_3.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    } else if (
                      key === config.product_char_cat.cat_mech.name &&
                      groupedProducts[key].total_price > 0
                    ) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.cat_mech.el) ||
                              (lang === isEn &&
                                config.product_char_cat.cat_mech.en) ||
                              (lang === isIt &&
                                config.product_char_cat.cat_mech.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    } else if (
                      key === config.product_char_cat.extra_yalo_5.name &&
                      groupedProducts[key].total_price > 0
                    ) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.extra_yalo_5.el) ||
                              (lang === isEn &&
                                config.product_char_cat.extra_yalo_5.en) ||
                              (lang === isIt &&
                                config.product_char_cat.extra_yalo_5.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    } else if (
                      key === config.product_char_cat.extra_yalo_6.name &&
                      groupedProducts[key].total_price > 0
                    ) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.extra_yalo_6.el) ||
                              (lang === isEn &&
                                config.product_char_cat.extra_yalo_6.en) ||
                              (lang === isIt &&
                                config.product_char_cat.extra_yalo_6.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    } else if (
                      key === config.product_char_cat.extra_yalo_7.name &&
                      groupedProducts[key].total_price > 0
                    ) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.extra_yalo_7.el) ||
                              (lang === isEn &&
                                config.product_char_cat.extra_yalo_7.en) ||
                              (lang === isIt &&
                                config.product_char_cat.extra_yalo_7.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    } else if (
                      key === config.product_char_cat.cat_kaitia.name &&
                      groupedProducts[key].total_price > 0
                    ) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.cat_kaitia.el) ||
                              (lang === isEn &&
                                config.product_char_cat.cat_kaitia.en) ||
                              (lang === isIt &&
                                config.product_char_cat.cat_kaitia.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    } else if (
                      key === config.product_char_cat.cat_tamp.name &&
                      groupedProducts[key].total_price > 0
                    ) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.cat_tamp.el) ||
                              (lang === isEn &&
                                config.product_char_cat.cat_tamp.en) ||
                              (lang === isIt &&
                                config.product_char_cat.cat_tamp.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    } else if (
                      key === config.product_char_cat.cat_color.name &&
                      groupedProducts[key].total_price > 0
                    ) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.cat_color.el) ||
                              (lang === isEn &&
                                config.product_char_cat.cat_color.en) ||
                              (lang === isIt &&
                                config.product_char_cat.cat_color.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    } else if (
                      key === config.product_char_cat.cat_wood.name &&
                      groupedProducts[key].total_price > 0
                    ) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.cat_wood.el) ||
                              (lang === isEn &&
                                config.product_char_cat.cat_wood.en) ||
                              (lang === isIt &&
                                config.product_char_cat.cat_wood.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    } else if (
                      key === config.product_char_cat.cat_metal_parts.name &&
                      groupedProducts[key].total_price > 0
                    ) {
                      return (
                        <tr key={index}>
                          <td>
                            {(lang === isEl &&
                              config.product_char_cat.cat_metal_parts.el) ||
                              (lang === isEn &&
                                config.product_char_cat.cat_metal_parts.en) ||
                              (lang === isIt &&
                                config.product_char_cat.cat_metal_parts.it)}
                          </td>
                          <td>{groupedProducts[key].number_of_products}</td>
                          <td>{groupedProducts[key].total_price}</td>
                        </tr>
                      );
                    }
                  })}
              </tbody>
            </table>
            {offerPass.costsDescPass && offerPass.costsDescPass.length > 0 && (
              <div>
                <h6 className="text-primary">
                  {(lang === isEl && "Επιπλεόν Κόστη") ||
                    (lang === isEn && "Attribute Set") ||
                    (lang === isIt && "Set di caratteristiche")}
                </h6>
                <table className="table table-bordered table-sm">
                  <thead className="thead-light">
                    <tr>
                      <th style={{ width: "70%" }}>
                        {(lang === isEl && "Περιγραφή") ||
                          (lang === isEn && "Description") ||
                          (lang === isIt && "Descrizione")}
                      </th>
                      <th style={{ width: "30%" }}>
                        {(lang === isEl && "Τιμή") ||
                          (lang === isEn && "Price") ||
                          (lang === isIt && "Prezzo")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {offerPass.costsDescPass.map((desc, pos1) =>
                      offerPass.costsAmountPass.map((amount, pos2) => (
                        <tr key={pos2}>
                          {pos1 === pos2 && <td>{desc}</td>}
                          {pos1 === pos2 && <td>{amount}</td>}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            )}
            {offerPass.chargesDescPass &&
              offerPass.chargesDescPass.length > 0 && (
                <div>
                  <h6 className="text-primary">
                    {(lang === isEl && "Επιπλεόν Επιβαρύνσεις") ||
                      (lang === isEn && "Additional Charges") ||
                      (lang === isIt && "Costi aggiuntivi")}
                  </h6>
                  <table className="table table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "70%" }}>
                          {(lang === isEl && "Περιγραφή") ||
                            (lang === isEn && "Description") ||
                            (lang === isIt && "Descrizione")}
                        </th>
                        <th style={{ width: "30%" }}>
                          {(lang === isEl && "Τιμή") ||
                            (lang === isEn && "Price") ||
                            (lang === isIt && "Prezzo")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {offerPass.chargesDescPass.map((desc, pos1) =>
                        offerPass.chargesAmountPass.map((amount, pos2) => (
                          <tr key={pos2}>
                            {pos1 === pos2 && <td>{desc}</td>}
                            {pos1 === pos2 && <td>{amount}</td>}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            <section>
              {/* Γενικά Σύνολα */}
              <h5 className="text-primary">
                {(lang === isEl && "Γενικά Σύνολα") ||
                  (lang === isEn && "General Totals") ||
                  (lang === isIt && "Somme generiche")}
              </h5>
              <div className="row justify-content-left">
                <div className="col-4 mb-1">
                  {(lang === isEl && "Ποσό χωρίς ΦΠΑ") ||
                    (lang === isEn && "Amount without VAT") ||
                    (lang === isIt && "Importo senza IVA")}
                </div>
                <div className="col-4 mb-1">{offerPass.offerAmountPass}€</div>
              </div>
              {offerPass.offerDiscountPass !== "0" && (
                <div className="row justify-content-left">
                  <div className="col-4 mb-1">
                    {(lang === isEl && "Ποσό με έκπτωση") ||
                      (lang === isEn && "Discounted Amount") ||
                      (lang === isIt && "Ammontare dello sconto")}
                    : {offerPass.offerDiscountPass}%
                  </div>
                  <div className="col-4 mb-1">
                    {offerPass.offerDiscountAmountPass}€
                  </div>
                </div>
              )}
              {offerPass.offerFpaPass !== "0" && (
                <div className="row justify-content-left">
                  <div className="col-4 mb-1">
                    {(lang === isEl && "Ποσό με ΦΠΑ") ||
                      (lang === isEn && "Amount including VAT") ||
                      (lang === isIt && "Importo IVA inclusa")}
                    : {offerPass.offerFpaPass}%
                  </div>
                  <div className="col-4 mb-1">
                    {offerPass.offerFpaAmountPass}€
                  </div>
                </div>
              )}
            </section>
          </div>
        )}
      </section>
      <div className="d-flex justify-content-center mt-2">
        <button
          className="btn btn-sm btn-outline-success me-5"
          onClick={createPdf}
        >
          Εκτύπωση Προσφοράς
        </button>
        <button
          type="submit"
          onClick={returnBack}
          className="btn btn-sm btn-outline-danger ms-5"
        >
          Επιστροφή
        </button>
      </div>
    </section>
  );
};

export default OfferInspection;
