import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../_services/userService";

const LoginForm = () => {
  const [enteredUsername, setEnteredUsername] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const usernameChangeHandler = (event) => {
    setEnteredUsername(event.target.value);
  };

  const passwordChangeHandler = (event) => {
    setEnteredPassword(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();
    setError(null);

    const creds = {
      username: enteredUsername,
      password: enteredPassword,
    };

    setIsLoading(true);
    userService.login(creds).then((resp) => {
      if (resp === true) {
        setIsLoading(false);
        navigate("/homepage");
      } else {
        setError(resp);
        setIsLoading(false);
      }
    });
  };

  return (
    <section className="container-fluid vh-100 mt-5 pt-5">
      <div className="rounded d-flex justify-content-center">
        <div className="col-md-6 shadow-lg p-5 bg-light">
          <div className="text-center pb-3">
            <h3 className="text-primary">Στοιχεία Χρήστη</h3>
          </div>
          <form onSubmit={submitHandler}>
            <div className="col d-flex justify-content-center">
              <div className="form-outline mb-4">
                <label className="form-label">Όνομα Χρήστη</label>
                <input
                  className="form-control"
                  type="text"
                  value={enteredUsername}
                  required
                  onChange={usernameChangeHandler}
                />
                <label className="form-label">Κωδικός</label>
                <input
                  className="form-control"
                  type="password"
                  value={enteredPassword}
                  required
                  onChange={passwordChangeHandler}
                />
              </div>
              <div className="form-outline mb-4"></div>
            </div>
            <div className="col-12">
              {!isLoading && (
                <button
                  type="submit"
                  className="btn btn-primary btn-block mb-4"
                >
                  Είσοδος
                </button>
              )}
              {isLoading && <p>Σύνδεση...</p>}
              {!isLoading && error && <p>{error}</p>}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
