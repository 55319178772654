import React, { useState, useEffect } from "react";
import { translationsService } from "../../_services/translationsService";
import config from "../../conf/Config";
import { Link } from "react-router-dom";

function ExtraPatzouList() {
  const [translations, setTranslations] = useState({
    metalMech: [],
    hasMetalMech: false,
    metalColor: [],
    hasMetalColor: false,
    sita: [],
    hasSita: false,
    mech: [],
    hasMech: false,
    kaitia: [],
    hasKaitia: false,
    tablades: [],
    hasTablades: false,
    colorAdded: [],
    hasColorAdded: false,
    woodAdded: [],
    hasWoodAdded: false,
    isLoading: false,
    hasResult: false,
    error: "",
  });

  useEffect(() => {
    setTranslations({
      ...translations,
      isLoading: true,
    });
    translationsService.getAll().then((data) => {
      // console.log("data is :" + JSON.stringify(data));
      if (data.message === undefined || data.message === null) {
        // Successful response
        let _metalMech = [];
        let _metalColor = [];
        let _sita = [];
        let _mech = [];
        let _kaitia = [];
        let _tablades = [];
        let _colorAdded = [];
        let _woodAdded = [];
        data.map((translation, pos) => {
          // console.log("Translation.type is :" + JSON.stringify(translation.type));

          if (translation.type === config.product_extras_patzoy.metalMech) {
            _metalMech = [..._metalMech, translation];
          } else if (
            translation.type === config.product_extras_patzoy.metalColor
          ) {
            _metalColor = [..._metalColor, translation];
          } else if (translation.type === config.product_extras_patzoy.sita) {
            _sita = [..._sita, translation];
          } else if (translation.type === config.product_extras_patzoy.mech) {
            _mech = [..._mech, translation];
          } else if (translation.type === config.product_extras_patzoy.kaitia) {
            _kaitia = [..._kaitia, translation];
          } else if (
            translation.type === config.product_extras_patzoy.tamplades
          ) {
            _tablades = [..._tablades, translation];
          } else if (
            translation.type === config.product_extras_patzoy.colorAdded
          ) {
            _colorAdded = [..._colorAdded, translation];
          } else if (
            translation.type === config.product_extras_patzoy.woodAdded
          ) {
            _woodAdded = [..._woodAdded, translation];
          }
        });
        setTranslations({
          ...translations,
          metalMech: _metalMech,
          metalColor: _metalColor,
          sita: _sita,
          mech: _mech,
          kaitia: _kaitia,
          tablades: _tablades,
          colorAdded: _colorAdded,
          woodAdded: _woodAdded,
          isLoading: false,
          hasResult: true,
          error: "",
        });
      } else {
        setTranslations({
          ...translations,
          isLoading: false,
          error: data.message,
        });
      }
    });
  }, []);

  const showMetalMech = () => {
    // if (translations.crystals.length > 0) {
    setTranslations({
      ...translations,
      hasMetalMech: true,
      hasMetalColor: false,
      hasSita: false,
      hasMech: false,
      hasKaitia: false,
      hasTablades: false,
      hasColorAdded: false,
      hasWoodAdded: false,
    });
    // }
  };

  const showMetalColor = () => {
    setTranslations({
      ...translations,
      hasMetalMech: false,
      hasMetalColor: true,
      hasSita: false,
      hasMech: false,
      hasKaitia: false,
      hasTablades: false,
      hasColorAdded: false,
      hasWoodAdded: false,
    });
  };

  const showSita = () => {
    setTranslations({
      ...translations,
      hasMetalMech: false,
      hasMetalColor: false,
      hasSita: true,
      hasMech: false,
      hasKaitia: false,
      hasTablades: false,
      hasColorAdded: false,
      hasWoodAdded: false,
    });
  };

  const showMech = () => {
    setTranslations({
      ...translations,
      hasMetalMech: false,
      hasMetalColor: false,
      hasSita: false,
      hasMech: true,
      hasKaitia: false,
      hasTablades: false,
      hasColorAdded: false,
      hasWoodAdded: false,
    });
  };

  const showKaitia = () => {
    setTranslations({
      ...translations,
      hasMetalMech: false,
      hasMetalColor: false,
      hasSita: false,
      hasMech: false,
      hasKaitia: true,
      hasTablades: false,
      hasColorAdded: false,
      hasWoodAdded: false,
    });
  };

  const showTablades = () => {
    setTranslations({
      ...translations,
      hasMetalMech: false,
      hasMetalColor: false,
      hasSita: false,
      hasMech: false,
      hasKaitia: false,
      hasTablades: true,
      hasColorAdded: false,
      hasWoodAdded: false,
    });
  };

  const showColorAdded = () => {
    setTranslations({
      ...translations,
      hasMetalMech: false,
      hasMetalColor: false,
      hasSita: false,
      hasMech: false,
      hasKaitia: false,
      hasTablades: false,
      hasColorAdded: true,
      hasWoodAdded: false,
    });
  };

  const showWoodAdded = () => {
    setTranslations({
      ...translations,
      hasMetalMech: false,
      hasMetalColor: false,
      hasSita: false,
      hasMech: false,
      hasKaitia: false,
      hasTablades: false,
      hasColorAdded: false,
      hasWoodAdded: true,
    });
  };

  const _delete = (id, type) => {
    console.log(id, type);
    setTranslations({
      ...translations,
      isLoading: true,
    });
    translationsService.delete(id).then((data) => {
      console.log(data);
      if (data.message === undefined || data.message === null) {
        if (type === config.product_extras_patzoy.metalMech) {
          setTranslations({
            ...translations,
            isLoading: false,
            metalMech: translations.metalMech.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.product_extras_patzoy.metalColor) {
          setTranslations({
            ...translations,
            isLoading: false,
            metalColor: translations.metalColor.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.product_extras_patzoy.sita) {
          setTranslations({
            ...translations,
            isLoading: false,
            sita: translations.sita.filter((x) => x.translation_id !== id),
          });
        } else if (type === config.product_extras_patzoy.mech) {
          setTranslations({
            ...translations,
            isLoading: false,
            mech: translations.mech.filter((x) => x.translation_id !== id),
          });
        } else if (type === config.product_extras_patzoy.kaitia) {
          setTranslations({
            ...translations,
            isLoading: false,
            kaitia: translations.kaitia.filter((x) => x.translation_id !== id),
          });
        } else if (type === config.product_extras_patzoy.tamplades) {
          setTranslations({
            ...translations,
            isLoading: false,
            tablades: translations.tablades.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.product_extras_patzoy.colorAdded) {
          setTranslations({
            ...translations,
            isLoading: false,
            colorAdded: translations.colorAdded.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.product_extras_patzoy.woodAdded) {
          setTranslations({
            ...translations,
            isLoading: false,
            woodAdded: translations.woodAdded.filter(
              (x) => x.translation_id !== id
            ),
          });
        }
      } else {
        setTranslations({
          ...translations,
          isLoading: false,
          error: data.message,
        });
      }
    });
  };

  const buttonName = [
    "Μηχανισμοί ανά Τύπο",
    "Μηχανισμοί ανά Χρώμα",
    "Σήτες",
    "Μηχανισμοί",
    "Καϊτια",
    "Ταμπλάδες",
    "Επιβάρυνση για Χρώμα",
    "Επιβαρύνσεις",
  ];

  return (
    <section>
      <h1>Χαρακτηριστικά Πατζουριών</h1>
      {translations.isLoading && <p>Loading...</p>}
      {translations.error && <p>{translations.error}</p>}
      {translations.hasResult && (
        <section>
          <button
            onClick={showMetalMech}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            Μηχανισμοί ανά Τύπο
          </button>
          <button
            onClick={showMetalColor}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            Μηχανισμοί ανά Χρώμα
          </button>
          <button
            onClick={showSita}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            Σήτες
          </button>
          <button
            onClick={showMech}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            Μηχανισμοί
          </button>
          <button
            onClick={showKaitia}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            Καϊτια
          </button>
          <button
            onClick={showTablades}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            Ταμπλάδες
          </button>
          <button
            onClick={showColorAdded}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            Επιβάρυνση για Χρώμα
          </button>
          <button
            onClick={showWoodAdded}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            Επιβαρύνσεις
          </button>
        </section>
      )}
      <section className="table-responsive">
        {translations.hasMetalMech && (
          <div>
            <h5 className="text-primary">{buttonName[0]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_patzoy.metalMech}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.metalMech &&
                  translations.metalMech.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_patzoy.metalMech}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_patzoy.metalMech
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasMetalColor && (
          <div>
            <h5 className="text-primary">{buttonName[1]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_patzoy.metalColor}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.metalColor &&
                  translations.metalColor.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_patzoy.metalColor}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_patzoy.metalColor
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasSita && (
          <div>
            <h5 className="text-primary">{buttonName[2]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_patzoy.sita}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.sita &&
                  translations.sita.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_patzoy.sita}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_patzoy.sita
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasMech && (
          <div>
            <h5 className="text-primary">{buttonName[3]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_patzoy.mech}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.mech &&
                  translations.mech.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_patzoy.mech}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_patzoy.mech
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasKaitia && (
          <div>
            <h5 className="text-primary">{buttonName[4]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_patzoy.kaitia}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.kaitia &&
                  translations.kaitia.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_patzoy.kaitia}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_patzoy.kaitia
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasTablades && (
          <div>
            <h5 className="text-primary">{buttonName[5]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_patzoy.tamplades}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.tablades &&
                  translations.tablades.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_patzoy.tamplades}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_patzoy.tamplades
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasColorAdded && (
          <div>
            <h5 className="text-primary">{buttonName[6]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_patzoy.colorAdded}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.colorAdded &&
                  translations.colorAdded.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_patzoy.colorAdded}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_patzoy.colorAdded
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasWoodAdded && (
          <div>
            <h5 className="text-primary">{buttonName[7]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_patzoy.woodAdded}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.woodAdded &&
                  translations.woodAdded.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_patzoy.woodAdded}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_patzoy.woodAdded
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </section>
    </section>
  );
}

export default ExtraPatzouList;
