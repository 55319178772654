import LoginForm from "../components/login/LoginForm";
import { Navigate } from "react-router-dom";

const LoginPage = () => {
  const isLoggedin = !!localStorage.getItem("token");

  return (
    <section>
      {!isLoggedin && <LoginForm />}
      {isLoggedin && <Navigate to="/homepage" />}
    </section>
  );
};

export default LoginPage;
