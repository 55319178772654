import React from "react";
import { Navigate } from "react-router-dom";
import CustomersList from "../components/customers/CustomersList";

const CustomersPage = () => {
  const isLoggedin = !!localStorage.getItem("token");
  
  return (
    <section>
      {isLoggedin && <CustomersList />}
      {!isLoggedin && <Navigate to="/login" />}
    </section>
  );
};

export default CustomersPage;
