import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { offersService } from "../../_services/offersService";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const OffersList = () => {
  const [offers, setOffers] = useState(null);
  const [maxOfferId, setMaxOfferId] = useState(0);
  const [hasResult, setHasResult] = useState(false);
  const [isError, setIsError] = useState("");

  const [offerOptions, setOfferOptions] = useState({});
  const [offerDateOptions, setOfferDateOptions] = useState({});
  const [customerOptions, setCustomersOptions] = useState({});
  const [constructorOptions, setConstructorOptions] = useState({});
  const [userOptions, setUserOptions] = useState({});
  const [chargeOptions, setChargeOptions] = useState({});
  const [discountOptions, setDiscountOptions] = useState({});
  const [amountOptions, setAmountOptions] = useState({});

  // // const fetchData = () => {
  // useEffect(() => {
  //   offersService.getAll().then((data) => {
  //     if (data) {
  //       setOffers(data);
  //       handleFilter(data);
  //       console.log(data);
  //       setHasResult(true);
  //     } else setIsError("error fetching data");
  //   });
  // }, []);
  // // };

  // const fetchData = () => {
  useEffect(() => {
    offersService.getAll().then((x) => {
      setOffers(x);
      if (x.message === undefined || x.message === null) {
        handleFilter(x);
        setHasResult(true);
        let max = 0;
        x.map((offer) => {
          if (offer.offer_id > max) {
            console.log(offer.offer_id);
            max = offer.offer_id;
          }
        });
        setMaxOfferId(max);
      }
    });
  }, []);
  // };

  //Bootstrap Columns filtering
  const handleFilter = (data) => {
    const uniqueOffer = [
      ...new Set(
        data
          .map((item) => (item.offer_id === null ? "" : item.offer_id))
          .filter((notUndefined) => notUndefined !== undefined)
      ),
    ];
    const offerTemp = uniqueOffer.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    setOfferOptions(offerTemp);

    const uniqueOfferDate = [
      ...new Set(
        data
          .map((item) => (item.offer_date === null ? "" : item.offer_date))
          .filter((notUndefined) => notUndefined !== undefined)
      ),
    ];
    const dateTemp = uniqueOfferDate.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    setOfferDateOptions(dateTemp);

    const uniqueCustomer = [
      ...new Set(
        data
          .map((item) =>
            item.customer_name === null ? "" : item.customer_name
          )
          .filter((notUndefined) => notUndefined !== undefined)
      ),
    ];
    const customerTemp = uniqueCustomer.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    setCustomersOptions(customerTemp);

    const uniqueConstructor = [
      ...new Set(
        data
          .map((item) =>
            item.offer_constructor === null ? "" : item.offer_constructor
          )
          .filter((notUndefined) => notUndefined !== undefined)
      ),
    ];
    const constructorTemp = uniqueConstructor.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    setConstructorOptions(constructorTemp);

    const uniqueUser = [
      ...new Set(
        data
          .map((item) => (item.username === null ? "" : item.username))
          .filter((notUndefined) => notUndefined !== undefined)
      ),
    ];
    const userTemp = uniqueUser.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    setUserOptions(userTemp);

    const uniqueCharge = [
      ...new Set(
        data
          .map((item) => (item.charge === null ? "" : item.charge))
          .filter((notUndefined) => notUndefined !== undefined)
      ),
    ];
    const chargeTemp = uniqueCharge.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    setChargeOptions(chargeTemp);

    const uniqueDiscount = [
      ...new Set(
        data
          .map((item) => (item.discount === null ? "" : item.discount))
          .filter((notUndefined) => notUndefined !== undefined)
      ),
    ];
    const discountTemp = uniqueDiscount.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    setDiscountOptions(discountTemp);

    const uniqueAmount = [
      ...new Set(
        data
          .map((item) => (item.offer_amount === null ? "" : item.offer_amount))
          .filter((notUndefined) => notUndefined !== undefined)
      ),
    ];
    const amountTemp = uniqueAmount.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    setAmountOptions(amountTemp);
  };

  const columns = [
    {
      dataField: "offer_key",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "offer_id",
      text: "Προσφορά",
      sort: true,
      formatter: (cell) =>
        offerOptions.filter((opt) => opt.value === cell)[0].label || "",
      filter: selectFilter({
        options: offerOptions,
      }),
    },
    {
      dataField: "offer_date",
      text: "Ημερομηνία",
      sort: true,
      filter: selectFilter({
        options: offerDateOptions,
      }),
    },
    {
      dataField: "customer_name",
      text: "Πελάτης",
      sort: true,
      filter: selectFilter({
        options: customerOptions,
      }),
    },
    {
      dataField: "offer_constructor",
      text: "Κατασκευαστής",
      sort: true,
      formatter: (cell) =>
        constructorOptions.filter((opt) => opt.value === cell)[0].label || "",
      filter: selectFilter({
        options: constructorOptions,
      }),
    },
    {
      dataField: "username",
      text: "Χρήστης",
      sort: true,
      formatter: (cell) =>
        userOptions.filter((opt) => opt.value === cell)[0].label || "",
      filter: selectFilter({
        options: userOptions,
      }),
    },
    {
      dataField: "charge",
      text: "Επιβάρυνση",
      sort: true,
      filter: selectFilter({
        options: chargeOptions,
      }),
    },
    {
      dataField: "discount",
      text: "Έκπτωση",
      sort: true,
      filter: selectFilter({
        options: discountOptions,
      }),
    },
    {
      dataField: "offer_amount",
      text: "Ποσό",
      sort: true,
      filter: selectFilter({
        options: amountOptions,
      }),
    },
    {
      dataField: "offer_key",
      text: "Ενέργειες",
      formatter: (cellContent, row) => (
        <Link
          to={`edit/${cellContent}`}
          className="btn btn-sm btn-primary me-1 "
        >
          Επεξεργασία
        </Link>
      ),
      // headerFormater: priceFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "offer_id",
      order: "desc",
    },
  ];

  // function priceFormatter(column, colIndex) {
  //   return (
  //     <button>test</button>
  //   );
  // }
  // const _delete = (offer_key) => {
  //   setOffers(
  //     offers.map((x) => {
  //       if (x.offer_key === offer_key) {
  //         x.isDeleting = true;
  //       }
  //       return x;
  //     })
  //   );
  //   offersService.delete(offer_key).then(() => {
  //     setOffers((offers) => offers.filter((x) => x.offer_key !== offer_key));
  //   });
  // };

  return (
    <section>
      <h1>Λίστα Προσφορών</h1>
      <Link
        to="add"
        className="btn btn-sm btn-success mb"
        state={{ maxOfferId: maxOfferId }}
      >
        Δημιουργία
      </Link>
      {/* <button onClick={fetchData} className="btn btn-sm btn-primary mb-3">
        Εμφάνιση Δεδομένων
      </button> */}
      {!hasResult && <p>Loading ...</p>}
      {isError && <p>{isError}</p>}
      {hasResult && (
        // <div className="table-responsive">
        <BootstrapTable
          bootstrap4
          striped
          hover
          condensed
          keyField="offer_key"
          data={offers}
          columns={columns}
          defaultSorted={defaultSorted}
          filter={filterFactory()}
          pagination={paginationFactory()}
          // wrapperClasses="table-responsive"
        />
        // </div>
      )}
    </section>
  );
};

export default OffersList;

{
  /* <Link to="add" className="btn btn-sm btn-success mb" state={{ maxOfferId: maxOfferId }}>
    Δημιουργία
  </Link> */
}
