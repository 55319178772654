import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import config from "../../conf/Config";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { costingService } from "../../_services/costingService";
import { translationsService } from "../../_services/translationsService";
import { offerCostingService } from "../../_services/offerCostingService";

const OfferCosting = () => {
  const navigate = useNavigate();
  const { offer_key } = useParams();
  console.log(offer_key);
  const [showCosting, setShowCosting] = useState(false);
  const [selectedArea, setSelectedArea] = useState("");
  const [numberOfPpl, setNumberOfPpl] = useState("");
  const [newArea, setNewArea] = useState("");
  const [newPeople, setNewPeople] = useState("");
  const [optionsAreas, setOptionsAreas] = useState([]);
  const [offerCostingId, setOfferCostingId] = useState("");
  const [tripAmount, setTripAmount] = useState("");
  const [tripAmountActual, setTripAmountActual] = useState("");
  const [perProductAmount, setPerProductAmount] = useState("");
  const [perProductAmountActual, setPerProductAmountActual] = useState("");
  const [transportationOut, setTransportationOut] = useState("");
  const [transportationOutActual, setTransportationOutActual] = useState("");
  const [transportationIn, setTransportationIn] = useState("");
  const [epimetrisi, setEpimetrisi] = useState("");
  const [crewCost, setCrewCost] = useState("");
  const [extraCost, setExtraCost] = useState("");
  const [tripAmountList, setTripAmountList] = useState([]);
  const [perProductAmountList, setPerProductAmountList] = useState([]);
  const [transportationOutList, setTransportationOutList] = useState([]);
  const [transportationInList, setTransportationInList] = useState([]);
  const [epimetrisiList, setEpimetrisiList] = useState([]);
  const [crewCostList, setCrewCostList] = useState([]);
  const [extraCostList, setExtraCostList] = useState([]);
  const [pageState, setPageState] = useState({
    isLoading: false,
    error: "",
  });

  const [productNameId, setProductNameId] = useState([]);

  const { register, handleSubmit, reset, setValue, formState } = useForm();

  const getAllAreas = () => {
    setPageState({
      ...pageState,
      error: "",
      isLoading: true,
    });
    costingService.getAll().then((data) => {
      console.log(data);
      if (data.message === undefined || data.message === null) {
        let _areas = [
          {
            label: "Περιοχή 1",
            value: data.area_1,
          },
          {
            label: "Περιοχή 2",
            value: data.area_2,
          },
          {
            label: "Περιοχή 3",
            value: data.area_3,
          },
          {
            label: "Περιοχή 4",
            value: data.area_4,
          },
          {
            label: "Περιοχή 5",
            value: data.area_5,
          },
        ];
        console.log(_areas);
        setOptionsAreas(_areas);
        setPageState({
          ...pageState,
          isLoading: false,
        });
      } else {
        setPageState({
          ...pageState,
          isLoading: false,
          error: data.message,
        });
      }
    });
  };

  const getAllProductNames = () => {
    setPageState({
      ...pageState,
      error: "",
      isLoading: true,
    });
    translationsService
      .getByType(config.translationTypes.productName)
      .then((data) => {
        console.log(data);
        // console.log("Get all Product_names:" + JSON.stringify(data));
        if (data.message === undefined || data.message === null) {
          let _productNameId = [];
          data.map((translation) => {
            if (translation.el === config.product_name_costing.value1) {
              _productNameId = [
                ..._productNameId,
                {
                  name: "Παράθ Υαλοστ",
                  id: translation.translation_id,
                },
              ];
            } else if (translation.el === config.product_name_costing.value2) {
              _productNameId = [
                ..._productNameId,
                {
                  name: "Μπαλκ Υαλοστ",
                  id: translation.translation_id,
                },
              ];
            } else if (translation.el === config.product_name_costing.value3) {
              _productNameId = [
                ..._productNameId,
                {
                  name: "Παράθ Πατζουρ",
                  id: translation.translation_id,
                },
              ];
            } else if (translation.el === config.product_name_costing.value4) {
              _productNameId = [
                ..._productNameId,
                {
                  name: "Μπαλκ Πατζουρ",
                  id: translation.translation_id,
                },
              ];
            } else if (translation.el === config.product_name_costing.value5) {
              _productNameId = [
                ..._productNameId,
                {
                  name: "Εξώθυρα",
                  id: translation.translation_id,
                },
              ];
            } else if (translation.el === config.product_name_costing.value6) {
              _productNameId = [
                ..._productNameId,
                {
                  name: "Ρολό",
                  id: translation.translation_id,
                },
              ];
            }
          });
          setProductNameId(_productNameId);
          // console.log(_productNameId);
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      });
  };

  const getOfferCostingById = () => {
    setPageState({
      ...pageState,
      error: "",
      isLoading: true,
    });
    offerCostingService.getById(offer_key).then((data) => {
      console.log(data);
      if (data.message === undefined || data.message === null) {
        if (data.offer_costing_id === undefined) {
          setShowCosting(false);
        } else {
          setOfferCostingId(data.offer_costing_id);
          setNumberOfPpl(data.people);
          setSelectedArea(data.area);
          setTripAmount(data.trip_amount);
          setTripAmountActual(data.trip_amount_actual);
          setPerProductAmount(data.per_product_amount);
          setPerProductAmountActual(data.per_product_amount_actual);
          setTransportationOut(data.transportation_out);
          setTransportationOutActual(data.transportation_out_actual);
          setTransportationIn(
            data.transportation_in === undefined ||
              data.transportation_in === null
              ? "0"
              : data.transportation_in
          );
          setEpimetrisi(
            data.epimetrisi === undefined || data.epimetrisi === null
              ? "0"
              : data.epimetrisi
          );
          setCrewCost(
            data.crew_cost === undefined || data.crew_cost === null
              ? "0"
              : data.crew_cost
          );
          setExtraCost(
            data.extra_cost === undefined || data.extra_cost === null
              ? "0"
              : data.extra_cost
          );
          setShowCosting(true);
        }
        {
          data.trip_amount_list !== undefined &&
            setTripAmountList(data.trip_amount_list);
        }
        {
          data.per_product_amount_list &&
            setPerProductAmountList(data.per_product_amount_list);
        }
        {
          data.transportation_out_list &&
            setTransportationOutList(data.transportation_out_list);
        }
        {
          data.transportation_in_list &&
            setTransportationInList(data.transportation_in_list);
        }
        {
          data.epimetrisi_list && setEpimetrisiList(data.epimetrisi_list);
        }
        {
          data.crew_cost_list && setCrewCostList(data.crew_cost_list);
        }
        {
          data.extra_cost_list && setExtraCostList(data.extra_cost_list);
        }
      } else {
        setPageState({
          ...pageState,
          isLoading: false,
          error: data.message,
        });
      }
    });
  };

  useEffect(() => {
    getAllAreas();
    getAllProductNames();
    getOfferCostingById();
  }, []);

  const onSubmit = () => {
    // e.preventDefault();
    setPageState({
      ...pageState,
      isLoading: true,
      error: "",
    });
    console.log(!showCosting);
    if (!showCosting) {
      // Initial entry sending ppeople and area
      let _data = {
        people: numberOfPpl,
        area: selectedArea,
        offer: offer_key,
      };
      console.log(_data);
      if (_data.area === "" || _data.people === "") {
        alert("Συμπληρώστε Άτομα και Περιοχή");
        return;
      }
      offerCostingService.updateInitial(_data).then((data) => {
        console.log(data);
        if (data.message === undefined || data.message === null) {
          setOfferCostingId(data.offer_costing_id);
          setTripAmount(data.trip_amount);
          setTripAmountActual(data.trip_amount_actual);
          setPerProductAmount(data.per_product_amount);
          setPerProductAmountActual(data.per_product_amount_actual);
          setTransportationOut(data.transportation_out);
          setTransportationOutActual(data.transportation_out_actual);
          setShowCosting(true);
          setPageState({
            ...pageState,
            isLoading: false,
          });
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      });
      console.log(_data);
    } else {
      //update rest page with checked values
      console.log(tripAmountList);
      const request_body = {
        offer: offer_key,
        people: newPeople === "" ? numberOfPpl : newPeople,
        area: newArea === "" ? selectedArea : newArea,
        trip_amount: tripAmount,
        trip_amount_actual: tripAmountActual,
        per_product_amount: perProductAmount,
        per_product_amount_actual: perProductAmountActual,
        transportation_out: transportationOut,
        transportation_out_actual: transportationOutActual,
        transportation_in: transportationIn,
        epimetrisi: epimetrisi,
        crew_cost: crewCost,
        extra_cost: extraCost,
        trip_amount_list: tripAmountList,
        per_product_amount_list: perProductAmountList,
        transportation_out_list: transportationOutList,
        transportation_in_list: transportationInList,
        epimetrisi_list: epimetrisiList,
        crew_cost_list: crewCostList,
        extra_cost_list: extraCostList,
      };
      console.log(request_body);
      offerCostingService.update(offerCostingId, request_body).then((data) => {
        console.log(data);
        if (data.message === undefined || data.message === null) {
          // The following code is for reload after update
          window.location.reload(false);
          //
          setPageState({
            ...pageState,
            isLoading: false,
            error: "",
          });
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      });
    }
  };

  const selectPplChangeHandler = (e) => {
    if (e.target.value) {
      setNumberOfPpl(e.target.value);
      console.log(e.target.value);
      setNewPeople(e.target.value);
      if (newArea === "") setNewArea(selectedArea);
    }
  };

  const selectAreaChangeHandler = (e) => {
    console.log(e);
    if (e.value) {
      setSelectedArea(e.value);
      console.log(e.value);
      setNewArea(e.value);
      if (newPeople === "") setNewPeople(numberOfPpl);
    }
  };

  const tripAmountHandler = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      setTripAmountActual(e.target.value);
    }
  };

  const perProductAmountHandler = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      setPerProductAmountActual(e.target.value);
    }
  };

  const transportationOutHandler = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      setTransportationOutActual(e.target.value);
    }
  };

  const transportationInHandler = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      setTransportationIn(e.target.value);
    }
  };

  const epimetrisiHandler = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      setEpimetrisi(e.target.value);
    }
  };

  const crewCostHandler = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      setCrewCost(e.target.value);
    }
  };

  const extraCostHandler = (e) => {
    console.log(e.target.value);
    if (e.target.value) {
      setExtraCost(e.target.value);
    }
  };

  const returnBack = () => {
    navigate(`/offers/edit/${offer_key}`);
  };

  return (
    <section>
      <form
        className="row g-3"
        // onSubmit={onSubmit}
        onSubmit={handleSubmit(onSubmit)}
        onReset={reset}
      >
        <h1>Κοστολόγηση Προσφοράς</h1>
        {/* Άτομα - Περιοχή για κοστολόγηση */}
        <div className="row mb-2 shadow p-2 bg-light rounded justify-content-center">
          <div className="col-md-2 g-0 me-3">
            <label className="form-label">Άτομα</label>
            <input
              type="number"
              defaultValue={numberOfPpl}
              min="0"
              className="form-control"
              onChange={selectPplChangeHandler}
            />
          </div>
          <div className="col-md-3 g-0 ms-3">
            <label className="form-label">Επιλογή Περιοχής</label>
            {optionsAreas && (
              <Select
                placeholder="Περιοχή"
                options={optionsAreas}
                value={optionsAreas.find((c) => {
                  if (c.value === selectedArea) {
                    return c.label;
                  }
                })}
                onChange={selectAreaChangeHandler}
              />
            )}
          </div>
          <hr className="bg-danger border-2 border-top border-primary mt-3" />
          {/* Πίνακας με product with checked */}
          {showCosting && (
            <section>
              {productNameId.length > 0 && (
                <div className="row mb-2 justify-content-center">
                  <label className="col-sm-2 col-form-label">Περιγραφή</label>
                  <label className="col-sm-2 col-form-label">Τιμή</label>
                  <label className="col-sm-1 col-form-label">
                    {productNameId[0].name}
                  </label>
                  <label className="col-sm-1 col-form-label">
                    {productNameId[1].name}
                  </label>
                  <label className="col-sm-1 col-form-label">
                    {productNameId[2].name}
                  </label>
                  <label className="col-sm-1 col-form-label">
                    {productNameId[3].name}
                  </label>
                  <label className="col-sm-1 col-form-label">
                    {productNameId[4].name}
                  </label>
                  <label className="col-sm-1 col-form-label">
                    {productNameId[5].name}
                  </label>
                </div>
              )}
              <div className="row mb-2 justify-content-center">
                <label className="col-sm-2 col-form-label">Τοποθέτηση</label>
                <div className="col-sm-2">
                  <input
                    type="text"
                    // {...register("trip_amount")}
                    defaultValue={tripAmountActual}
                    className="form-control"
                    onChange={tripAmountHandler}
                  />
                  <input
                    type="text"
                    value={tripAmount}
                    disabled
                    className="form-control"
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      tripAmountList &&
                      tripAmountList.length > 0 &&
                      productNameId.length > 0 &&
                      tripAmountList.includes(productNameId[0].id)
                    }
                    onChange={() => {
                      if (!tripAmountList.includes(productNameId[0].id)) {
                        setTripAmountList([
                          ...tripAmountList,
                          productNameId[0].id,
                        ]);
                      } else {
                        setTripAmountList(
                          tripAmountList.filter((item) => {
                            return item !== productNameId[0].id;
                          })
                        );
                      }
                    }}
                  />
                  {/* <span>{productNameId.length > 0 && productNameId[0].id}</span> */}
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      if (!tripAmountList.includes(productNameId[1].id)) {
                        setTripAmountList([
                          ...tripAmountList,
                          productNameId[1].id,
                        ]);
                      } else {
                        setTripAmountList(
                          tripAmountList.filter((item) => {
                            return item !== productNameId[1].id;
                          })
                        );
                      }
                    }}
                    checked={
                      tripAmountList &&
                      tripAmountList.length > 0 &&
                      productNameId.length > 0 &&
                      tripAmountList.includes(productNameId[1].id)
                    }
                  />
                  {/* <span>{productNameId.length > 0 && productNameId[1].id}</span> */}
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      if (!tripAmountList.includes(productNameId[2].id)) {
                        setTripAmountList([
                          ...tripAmountList,
                          productNameId[2].id,
                        ]);
                      } else {
                        setTripAmountList(
                          tripAmountList.filter((item) => {
                            return item !== productNameId[2].id;
                          })
                        );
                      }
                    }}
                    checked={
                      tripAmountList &&
                      tripAmountList.length > 0 &&
                      productNameId.length > 0 &&
                      tripAmountList.includes(productNameId[2].id)
                    }
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      if (!tripAmountList.includes(productNameId[3].id)) {
                        setTripAmountList([
                          ...tripAmountList,
                          productNameId[3].id,
                        ]);
                      } else {
                        setTripAmountList(
                          tripAmountList.filter((item) => {
                            return item !== productNameId[3].id;
                          })
                        );
                      }
                    }}
                    checked={
                      tripAmountList &&
                      tripAmountList.length > 0 &&
                      productNameId.length > 0 &&
                      tripAmountList.includes(productNameId[3].id)
                    }
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      if (!tripAmountList.includes(productNameId[4].id)) {
                        setTripAmountList([
                          ...tripAmountList,
                          productNameId[4].id,
                        ]);
                      } else {
                        setTripAmountList(
                          tripAmountList.filter((item) => {
                            return item !== productNameId[4].id;
                          })
                        );
                      }
                    }}
                    checked={
                      tripAmountList &&
                      tripAmountList.length > 0 &&
                      productNameId.length > 0 &&
                      tripAmountList.includes(productNameId[4].id)
                    }
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={() => {
                      if (!tripAmountList.includes(productNameId[5].id)) {
                        setTripAmountList([
                          ...tripAmountList,
                          productNameId[5].id,
                        ]);
                      } else {
                        setTripAmountList(
                          tripAmountList.filter((item) => {
                            return item !== productNameId[5].id;
                          })
                        );
                      }
                    }}
                    checked={
                      tripAmountList &&
                      tripAmountList.length > 0 &&
                      productNameId.length > 0 &&
                      tripAmountList.includes(productNameId[5].id)
                    }
                  />
                </div>
              </div>
              <div className="row mb-2 justify-content-center">
                <label className="col-sm-2 col-form-label">Υλικά</label>
                <div className="col-sm-2">
                  <input
                    type="text"
                    // {...register("per_product_amount")}
                    className="form-control"
                    defaultValue={perProductAmountActual}
                    onChange={perProductAmountHandler}
                  />
                  <input
                    type="text"
                    value={perProductAmount}
                    disabled
                    className="form-control"
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      perProductAmountList &&
                      perProductAmountList.length > 0 &&
                      productNameId.length > 0 &&
                      perProductAmountList.includes(productNameId[0].id)
                    }
                    onChange={() => {
                      if (!perProductAmountList.includes(productNameId[0].id)) {
                        setPerProductAmountList([
                          ...perProductAmountList,
                          productNameId[0].id,
                        ]);
                      } else {
                        setPerProductAmountList(
                          perProductAmountList.filter((item) => {
                            return item !== productNameId[0].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      perProductAmountList &&
                      perProductAmountList.length > 0 &&
                      productNameId.length > 0 &&
                      perProductAmountList.includes(productNameId[1].id)
                    }
                    onChange={() => {
                      if (!perProductAmountList.includes(productNameId[1].id)) {
                        setPerProductAmountList([
                          ...perProductAmountList,
                          productNameId[1].id,
                        ]);
                      } else {
                        setPerProductAmountList(
                          perProductAmountList.filter((item) => {
                            return item !== productNameId[1].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      perProductAmountList &&
                      perProductAmountList.length > 0 &&
                      productNameId.length > 0 &&
                      perProductAmountList.includes(productNameId[2].id)
                    }
                    onChange={() => {
                      if (!perProductAmountList.includes(productNameId[2].id)) {
                        setPerProductAmountList([
                          ...perProductAmountList,
                          productNameId[2].id,
                        ]);
                      } else {
                        setPerProductAmountList(
                          perProductAmountList.filter((item) => {
                            return item !== productNameId[2].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      perProductAmountList &&
                      perProductAmountList.length > 0 &&
                      productNameId.length > 0 &&
                      perProductAmountList.includes(productNameId[3].id)
                    }
                    onChange={() => {
                      if (!perProductAmountList.includes(productNameId[3].id)) {
                        setPerProductAmountList([
                          ...perProductAmountList,
                          productNameId[3].id,
                        ]);
                      } else {
                        setPerProductAmountList(
                          perProductAmountList.filter((item) => {
                            return item !== productNameId[3].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      perProductAmountList &&
                      perProductAmountList.length > 0 &&
                      productNameId.length > 0 &&
                      perProductAmountList.includes(productNameId[4].id)
                    }
                    onChange={() => {
                      if (!perProductAmountList.includes(productNameId[4].id)) {
                        setPerProductAmountList([
                          ...perProductAmountList,
                          productNameId[4].id,
                        ]);
                      } else {
                        setPerProductAmountList(
                          perProductAmountList.filter((item) => {
                            return item !== productNameId[4].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      perProductAmountList &&
                      perProductAmountList.length > 0 &&
                      productNameId.length > 0 &&
                      perProductAmountList.includes(productNameId[5].id)
                    }
                    onChange={() => {
                      if (!perProductAmountList.includes(productNameId[5].id)) {
                        setPerProductAmountList([
                          ...perProductAmountList,
                          productNameId[5].id,
                        ]);
                      } else {
                        setPerProductAmountList(
                          perProductAmountList.filter((item) => {
                            return item !== productNameId[5].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
              </div>
              <div className="row mb-2 justify-content-center">
                <label className="col-sm-2 col-form-label">
                  Μεταφορά Εξωτερικού
                </label>
                <div className="col-sm-2">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={transportationOutActual}
                    onChange={transportationOutHandler}
                  />
                  <input
                    type="text"
                    value={transportationOut}
                    disabled
                    className="form-control"
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      transportationOutList &&
                      transportationOutList.length > 0 &&
                      productNameId.length > 0 &&
                      transportationOutList.includes(productNameId[0].id)
                    }
                    onChange={() => {
                      if (
                        !transportationOutList.includes(productNameId[0].id)
                      ) {
                        setTransportationOutList([
                          ...transportationOutList,
                          productNameId[0].id,
                        ]);
                      } else {
                        setTransportationOutList(
                          transportationOutList.filter((item) => {
                            return item !== productNameId[0].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      transportationOutList &&
                      transportationOutList.length > 0 &&
                      productNameId.length > 0 &&
                      transportationOutList.includes(productNameId[1].id)
                    }
                    onChange={() => {
                      if (
                        !transportationOutList.includes(productNameId[1].id)
                      ) {
                        setTransportationOutList([
                          ...transportationOutList,
                          productNameId[1].id,
                        ]);
                      } else {
                        setTransportationOutList(
                          transportationOutList.filter((item) => {
                            return item !== productNameId[1].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      transportationOutList &&
                      transportationOutList.length > 0 &&
                      productNameId.length > 0 &&
                      transportationOutList.includes(productNameId[2].id)
                    }
                    onChange={() => {
                      if (
                        !transportationOutList.includes(productNameId[2].id)
                      ) {
                        setTransportationOutList([
                          ...transportationOutList,
                          productNameId[2].id,
                        ]);
                      } else {
                        setTransportationOutList(
                          transportationOutList.filter((item) => {
                            return item !== productNameId[2].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      transportationOutList &&
                      transportationOutList.length > 0 &&
                      productNameId.length > 0 &&
                      transportationOutList.includes(productNameId[3].id)
                    }
                    onChange={() => {
                      if (
                        !transportationOutList.includes(productNameId[3].id)
                      ) {
                        setTransportationOutList([
                          ...transportationOutList,
                          productNameId[3].id,
                        ]);
                      } else {
                        setTransportationOutList(
                          transportationOutList.filter((item) => {
                            return item !== productNameId[3].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      transportationOutList &&
                      transportationOutList.length > 0 &&
                      productNameId.length > 0 &&
                      transportationOutList.includes(productNameId[4].id)
                    }
                    onChange={() => {
                      if (
                        !transportationOutList.includes(productNameId[4].id)
                      ) {
                        setTransportationOutList([
                          ...transportationOutList,
                          productNameId[4].id,
                        ]);
                      } else {
                        setTransportationOutList(
                          transportationOutList.filter((item) => {
                            return item !== productNameId[4].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      transportationOutList &&
                      transportationOutList.length > 0 &&
                      productNameId.length > 0 &&
                      transportationOutList.includes(productNameId[5].id)
                    }
                    onChange={() => {
                      if (
                        !transportationOutList.includes(productNameId[5].id)
                      ) {
                        setTransportationOutList([
                          ...transportationOutList,
                          productNameId[5].id,
                        ]);
                      } else {
                        setTransportationOutList(
                          transportationOutList.filter((item) => {
                            return item !== productNameId[5].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
              </div>
              <div className="row mb-2 justify-content-center">
                <label className="col-sm-2 col-form-label">
                  Μεταφορά Εσωτερικού
                </label>
                <div className="col-sm-2">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={transportationIn}
                    onChange={transportationInHandler}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      transportationInList &&
                      transportationInList.length > 0 &&
                      productNameId.length > 0 &&
                      transportationInList.includes(productNameId[0].id)
                    }
                    onChange={() => {
                      if (!transportationInList.includes(productNameId[0].id)) {
                        setTransportationInList([
                          ...transportationInList,
                          productNameId[0].id,
                        ]);
                      } else {
                        setTransportationInList(
                          transportationInList.filter((item) => {
                            return item !== productNameId[0].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      transportationInList &&
                      transportationInList.length > 0 &&
                      productNameId.length > 0 &&
                      transportationInList.includes(productNameId[1].id)
                    }
                    onChange={() => {
                      if (!transportationInList.includes(productNameId[1].id)) {
                        setTransportationInList([
                          ...transportationInList,
                          productNameId[1].id,
                        ]);
                      } else {
                        setTransportationInList(
                          transportationInList.filter((item) => {
                            return item !== productNameId[1].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      transportationInList &&
                      transportationInList.length > 0 &&
                      productNameId.length > 0 &&
                      transportationInList.includes(productNameId[2].id)
                    }
                    onChange={() => {
                      if (!transportationInList.includes(productNameId[2].id)) {
                        setTransportationInList([
                          ...transportationInList,
                          productNameId[2].id,
                        ]);
                      } else {
                        setTransportationInList(
                          transportationInList.filter((item) => {
                            return item !== productNameId[2].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      transportationInList &&
                      transportationInList.length > 0 &&
                      productNameId.length > 0 &&
                      transportationInList.includes(productNameId[3].id)
                    }
                    onChange={() => {
                      if (!transportationInList.includes(productNameId[3].id)) {
                        setTransportationInList([
                          ...transportationInList,
                          productNameId[3].id,
                        ]);
                      } else {
                        setTransportationInList(
                          transportationInList.filter((item) => {
                            return item !== productNameId[3].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      transportationInList &&
                      transportationInList.length > 0 &&
                      productNameId.length > 0 &&
                      transportationInList.includes(productNameId[4].id)
                    }
                    onChange={() => {
                      if (!transportationInList.includes(productNameId[4].id)) {
                        setTransportationInList([
                          ...transportationInList,
                          productNameId[4].id,
                        ]);
                      } else {
                        setTransportationInList(
                          transportationInList.filter((item) => {
                            return item !== productNameId[4].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      transportationInList &&
                      transportationInList.length > 0 &&
                      productNameId.length > 0 &&
                      transportationInList.includes(productNameId[5].id)
                    }
                    onChange={() => {
                      if (!transportationInList.includes(productNameId[5].id)) {
                        setTransportationInList([
                          ...transportationInList,
                          productNameId[5].id,
                        ]);
                      } else {
                        setTransportationInList(
                          transportationInList.filter((item) => {
                            return item !== productNameId[5].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
              </div>
              <div className="row mb-2 justify-content-center">
                <label className="col-sm-2 col-form-label">Επιμέτρηση</label>
                <div className="col-sm-2">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={epimetrisi}
                    onChange={epimetrisiHandler}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      epimetrisiList &&
                      epimetrisiList.length > 0 &&
                      productNameId.length > 0 &&
                      epimetrisiList.includes(productNameId[0].id)
                    }
                    onChange={() => {
                      if (!epimetrisiList.includes(productNameId[0].id)) {
                        setEpimetrisiList([
                          ...epimetrisiList,
                          productNameId[0].id,
                        ]);
                      } else {
                        setEpimetrisiList(
                          epimetrisiList.filter((item) => {
                            return item !== productNameId[0].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      epimetrisiList &&
                      epimetrisiList.length > 0 &&
                      productNameId.length > 0 &&
                      epimetrisiList.includes(productNameId[1].id)
                    }
                    onChange={() => {
                      if (!epimetrisiList.includes(productNameId[1].id)) {
                        setEpimetrisiList([
                          ...epimetrisiList,
                          productNameId[1].id,
                        ]);
                      } else {
                        setEpimetrisiList(
                          epimetrisiList.filter((item) => {
                            return item !== productNameId[1].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      epimetrisiList &&
                      epimetrisiList.length > 0 &&
                      productNameId.length > 0 &&
                      epimetrisiList.includes(productNameId[2].id)
                    }
                    onChange={() => {
                      if (!epimetrisiList.includes(productNameId[2].id)) {
                        setEpimetrisiList([
                          ...epimetrisiList,
                          productNameId[2].id,
                        ]);
                      } else {
                        setEpimetrisiList(
                          epimetrisiList.filter((item) => {
                            return item !== productNameId[2].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      epimetrisiList &&
                      epimetrisiList.length > 0 &&
                      productNameId.length > 0 &&
                      epimetrisiList.includes(productNameId[3].id)
                    }
                    onChange={() => {
                      if (!epimetrisiList.includes(productNameId[3].id)) {
                        setEpimetrisiList([
                          ...epimetrisiList,
                          productNameId[3].id,
                        ]);
                      } else {
                        setEpimetrisiList(
                          epimetrisiList.filter((item) => {
                            return item !== productNameId[3].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      epimetrisiList &&
                      epimetrisiList.length > 0 &&
                      productNameId.length > 0 &&
                      epimetrisiList.includes(productNameId[4].id)
                    }
                    onChange={() => {
                      if (!epimetrisiList.includes(productNameId[4].id)) {
                        setEpimetrisiList([
                          ...epimetrisiList,
                          productNameId[4].id,
                        ]);
                      } else {
                        setEpimetrisiList(
                          epimetrisiList.filter((item) => {
                            return item !== productNameId[4].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      epimetrisiList &&
                      epimetrisiList.length > 0 &&
                      productNameId.length > 0 &&
                      epimetrisiList.includes(productNameId[5].id)
                    }
                    onChange={() => {
                      if (!epimetrisiList.includes(productNameId[5].id)) {
                        setEpimetrisiList([
                          ...epimetrisiList,
                          productNameId[5].id,
                        ]);
                      } else {
                        setEpimetrisiList(
                          epimetrisiList.filter((item) => {
                            return item !== productNameId[5].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
              </div>
              <div className="row mb-2 justify-content-center">
                <label className="col-sm-2 col-form-label">
                  Έξοδα Συνεργείου
                </label>
                <div className="col-sm-2">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={crewCost}
                    onChange={crewCostHandler}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      crewCostList &&
                      crewCostList.length > 0 &&
                      productNameId.length > 0 &&
                      crewCostList.includes(productNameId[0].id)
                    }
                    onChange={() => {
                      if (!crewCostList.includes(productNameId[0].id)) {
                        setCrewCostList([...crewCostList, productNameId[0].id]);
                      } else {
                        setCrewCostList(
                          crewCostList.filter((item) => {
                            return item !== productNameId[0].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      crewCostList &&
                      crewCostList.length > 0 &&
                      productNameId.length > 0 &&
                      crewCostList.includes(productNameId[1].id)
                    }
                    onChange={() => {
                      if (!crewCostList.includes(productNameId[1].id)) {
                        setCrewCostList([...crewCostList, productNameId[1].id]);
                      } else {
                        setCrewCostList(
                          crewCostList.filter((item) => {
                            return item !== productNameId[1].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      crewCostList &&
                      crewCostList.length > 0 &&
                      productNameId.length > 0 &&
                      crewCostList.includes(productNameId[2].id)
                    }
                    onChange={() => {
                      if (!crewCostList.includes(productNameId[2].id)) {
                        setCrewCostList([...crewCostList, productNameId[2].id]);
                      } else {
                        setCrewCostList(
                          crewCostList.filter((item) => {
                            return item !== productNameId[2].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      crewCostList &&
                      crewCostList.length > 0 &&
                      productNameId.length > 0 &&
                      crewCostList.includes(productNameId[3].id)
                    }
                    onChange={() => {
                      if (!crewCostList.includes(productNameId[3].id)) {
                        setCrewCostList([...crewCostList, productNameId[3].id]);
                      } else {
                        setCrewCostList(
                          crewCostList.filter((item) => {
                            return item !== productNameId[3].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      crewCostList &&
                      crewCostList.length > 0 &&
                      productNameId.length > 0 &&
                      crewCostList.includes(productNameId[4].id)
                    }
                    onChange={() => {
                      if (!crewCostList.includes(productNameId[4].id)) {
                        setCrewCostList([...crewCostList, productNameId[4].id]);
                      } else {
                        setCrewCostList(
                          crewCostList.filter((item) => {
                            return item !== productNameId[4].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      crewCostList &&
                      crewCostList.length > 0 &&
                      productNameId.length > 0 &&
                      crewCostList.includes(productNameId[5].id)
                    }
                    onChange={() => {
                      if (!crewCostList.includes(productNameId[5].id)) {
                        setCrewCostList([...crewCostList, productNameId[5].id]);
                      } else {
                        setCrewCostList(
                          crewCostList.filter((item) => {
                            return item !== productNameId[5].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
              </div>
              <div className="row mb-2 justify-content-center">
                <label className="col-sm-2 col-form-label">Διάφορα Έξοδα</label>
                <div className="col-sm-2">
                  <input
                    type="text"
                    className="form-control"
                    defaultValue={extraCost}
                    onChange={extraCostHandler}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      extraCostList &&
                      extraCostList.length > 0 &&
                      productNameId.length > 0 &&
                      extraCostList.includes(productNameId[0].id)
                    }
                    onChange={() => {
                      if (!extraCostList.includes(productNameId[0].id)) {
                        setExtraCostList([
                          ...extraCostList,
                          productNameId[0].id,
                        ]);
                      } else {
                        setExtraCostList(
                          extraCostList.filter((item) => {
                            return item !== productNameId[0].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      extraCostList &&
                      extraCostList.length > 0 &&
                      productNameId.length > 0 &&
                      extraCostList.includes(productNameId[1].id)
                    }
                    onChange={() => {
                      if (!extraCostList.includes(productNameId[1].id)) {
                        setExtraCostList([
                          ...extraCostList,
                          productNameId[1].id,
                        ]);
                      } else {
                        setExtraCostList(
                          extraCostList.filter((item) => {
                            return item !== productNameId[1].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      extraCostList &&
                      extraCostList.length > 0 &&
                      productNameId.length > 0 &&
                      extraCostList.includes(productNameId[2].id)
                    }
                    onChange={() => {
                      if (!extraCostList.includes(productNameId[2].id)) {
                        setExtraCostList([
                          ...extraCostList,
                          productNameId[2].id,
                        ]);
                      } else {
                        setExtraCostList(
                          extraCostList.filter((item) => {
                            return item !== productNameId[2].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      extraCostList &&
                      extraCostList.length > 0 &&
                      productNameId.length > 0 &&
                      extraCostList.includes(productNameId[3].id)
                    }
                    onChange={() => {
                      if (!extraCostList.includes(productNameId[3].id)) {
                        setExtraCostList([
                          ...extraCostList,
                          productNameId[3].id,
                        ]);
                      } else {
                        setExtraCostList(
                          extraCostList.filter((item) => {
                            return item !== productNameId[3].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      extraCostList &&
                      extraCostList.length > 0 &&
                      productNameId.length > 0 &&
                      extraCostList.includes(productNameId[4].id)
                    }
                    onChange={() => {
                      if (!extraCostList.includes(productNameId[4].id)) {
                        setExtraCostList([
                          ...extraCostList,
                          productNameId[4].id,
                        ]);
                      } else {
                        setExtraCostList(
                          extraCostList.filter((item) => {
                            return item !== productNameId[4].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="col-sm-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={
                      extraCostList &&
                      extraCostList.length > 0 &&
                      productNameId.length > 0 &&
                      extraCostList.includes(productNameId[5].id)
                    }
                    onChange={() => {
                      if (!extraCostList.includes(productNameId[5].id)) {
                        setExtraCostList([
                          ...extraCostList,
                          productNameId[5].id,
                        ]);
                      } else {
                        setExtraCostList(
                          extraCostList.filter((item) => {
                            return item !== productNameId[5].id;
                          })
                        );
                      }
                    }}
                  />
                </div>
              </div>
            </section>
          )}
        </div>
        {/* Κουμπιά Δημιουργίας-Αποθήκευσης Ακύρωσης Προσφοράς */}
        <div className="row mt-3 justify-content-end">
          <div className="col-3">
            <button
              type="submit"
              // disabled={formState.isSubmitting}
              className="btn btn-primary"
            >
              {formState.isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              {!pageState.isLoading && !showCosting
                ? "Δημιουργία"
                : "Κοστολόγηση"}
            </button>
            {pageState.isLoading && <p>Loading...</p>}
            {pageState.error && <p>{pageState.error}</p>}
            <button
              type="button"
              onClick={returnBack}
              className="btn btn-sm btn-outline-danger ms-3 mt-1 ms-1 me-1"
            >
              Επιστροφή
            </button>
            {/* <Link to="/offers" className="btn btn-link">
              Επιστροφή
            </Link> */}
          </div>
        </div>
        {pageState.error && `${pageState.error}`}
      </form>
    </section>
  );
};

export default OfferCosting;
