import React, { useState, useEffect } from "react";
import { translationsService } from "../../_services/translationsService";
import config from "../../conf/Config";
import { Link } from "react-router-dom";

function ExtraYaloList() {
  const [translations, setTranslations] = useState({
    crystals: [],
    hasCrystals: false,
    sites: [],
    hasSites: false,
    rola: [],
    hasRola: false,
    mixanismoi: [],
    hasMixanismoi: false,
    skiadia: [],
    hasSkiadia: false,
    sideries: [],
    hasSideries: false,
    alarm: [],
    hasAlarm: false,
    kaitia: [],
    hasKaitia: false,
    tablades: [],
    hasTablades: false,
    colorCost: [],
    hasColorCost: false,
    extraCost: [],
    hasExtraCost: false,
    thermalEfficiency: [],
    hasThermalEfficiency: false,
    isLoading: false,
    hasResult: false,
    error: "",
  });

  useEffect(() => {
    setTranslations({
      ...translations,
      isLoading: true,
    });
    translationsService.getAll().then((data) => {
      // console.log("data is :" + JSON.stringify(data));
      if (data.message === undefined || data.message === null) {
        // Successful response
        let _crystals = [];
        let _sites = [];
        let _rola = [];
        let _mixanismoi = [];
        let _skiadia = [];
        let _sideries = [];
        let _alarm = [];
        let _kaitia = [];
        let _tablades = [];
        let _colorCost = [];
        let _extraCost = [];
        let _thermalEfficiency = [];
        data.map((translation, pos) => {
          // console.log("Translation.type is :" + JSON.stringify(translation.type));

          if (translation.type === config.product_extras_yalo.crystal) {
            _crystals = [..._crystals, translation];
          } else if (translation.type === config.product_extras_yalo.sita) {
            _sites = [..._sites, translation];
          } else if (translation.type === config.product_extras_yalo.rola) {
            _rola = [..._rola, translation];
          } else if (translation.type === config.product_extras_yalo.mechs) {
            _mixanismoi = [..._mixanismoi, translation];
          } else if (translation.type === config.product_extras_yalo.skia) {
            _skiadia = [..._skiadia, translation];
          } else if (translation.type === config.product_extras_yalo.iron) {
            _sideries = [..._sideries, translation];
          } else if (translation.type === config.product_extras_yalo.alarm) {
            _alarm = [..._alarm, translation];
          } else if (translation.type === config.product_extras_yalo.kaitia) {
            _kaitia = [..._kaitia, translation];
          } else if (
            translation.type === config.product_extras_yalo.tamplades
          ) {
            _tablades = [..._tablades, translation];
          } else if (translation.type === config.product_extras_yalo.color) {
            _colorCost = [..._colorCost, translation];
          } else if (translation.type === config.product_extras_yalo.wood) {
            _extraCost = [..._extraCost, translation];
          } else if (translation.type === config.product_extras_yalo.thermal) {
            _thermalEfficiency = [..._thermalEfficiency, translation];
          }
        });
        setTranslations({
          ...translations,
          crystals: _crystals,
          sites: _sites,
          rola: _rola,
          mixanismoi: _mixanismoi,
          skiadia: _skiadia,
          sideries: _sideries,
          alarm: _alarm,
          kaitia: _kaitia,
          tablades: _tablades,
          colorCost: _colorCost,
          extraCost: _extraCost,
          thermalEfficiency: _thermalEfficiency,
          isLoading: false,
          hasResult: true,
          error: "",
        });
      } else {
        setTranslations({
          ...translations,
          isLoading: false,
          error: data.message,
        });
      }
    });
  }, []);

  const showCrystals = () => {
    // if (translations.crystals.length > 0) {
    setTranslations({
      ...translations,
      hasCrystals: true,
      hasSites: false,
      hasRola: false,
      hasMixanismoi: false,
      hasSkiadia: false,
      hasSideries: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTablades: false,
      hasColorCost: false,
      hasExtraCost: false,
      hasThermalEfficiency: false,
    });
    // }
  };

  const showSites = () => {
    setTranslations({
      ...translations,
      hasCrystals: false,
      hasSites: true,
      hasRola: false,
      hasMixanismoi: false,
      hasSkiadia: false,
      hasSideries: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTablades: false,
      hasColorCost: false,
      hasExtraCost: false,
      hasThermalEfficiency: false,
    });
  };

  const showRola = () => {
    setTranslations({
      ...translations,
      hasCrystals: false,
      hasSites: false,
      hasRola: true,
      hasMixanismoi: false,
      hasSkiadia: false,
      hasSideries: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTablades: false,
      hasColorCost: false,
      hasExtraCost: false,
      hasThermalEfficiency: false,
    });
  };

  const showMixanismoi = () => {
    setTranslations({
      ...translations,
      hasCrystals: false,
      hasSites: false,
      hasRola: false,
      hasMixanismoi: true,
      hasSkiadia: false,
      hasSideries: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTablades: false,
      hasColorCost: false,
      hasExtraCost: false,
      hasThermalEfficiency: false,
    });
  };

  const showSkiadia = () => {
    setTranslations({
      ...translations,
      hasCrystals: false,
      hasSites: false,
      hasRola: false,
      hasMixanismoi: false,
      hasSkiadia: true,
      hasSideries: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTablades: false,
      hasColorCost: false,
      hasExtraCost: false,
      hasThermalEfficiency: false,
    });
  };

  const showSideries = () => {
    setTranslations({
      ...translations,
      hasCrystals: false,
      hasSites: false,
      hasRola: false,
      hasMixanismoi: false,
      hasSkiadia: false,
      hasSideries: true,
      hasAlarm: false,
      hasKaitia: false,
      hasTablades: false,
      hasColorCost: false,
      hasExtraCost: false,
      hasThermalEfficiency: false,
    });
  };

  const showAlarm = () => {
    setTranslations({
      ...translations,
      hasCrystals: false,
      hasSites: false,
      hasRola: false,
      hasMixanismoi: false,
      hasSkiadia: false,
      hasSideries: false,
      hasAlarm: true,
      hasKaitia: false,
      hasTablades: false,
      hasColorCost: false,
      hasExtraCost: false,
      hasThermalEfficiency: false,
    });
  };

  const showKaitia = () => {
    setTranslations({
      ...translations,
      hasCrystals: false,
      hasSites: false,
      hasRola: false,
      hasMixanismoi: false,
      hasSkiadia: false,
      hasSideries: false,
      hasAlarm: false,
      hasKaitia: true,
      hasTablades: false,
      hasColorCost: false,
      hasExtraCost: false,
      hasThermalEfficiency: false,
    });
  };

  const showTablades = () => {
    setTranslations({
      ...translations,
      hasCrystals: false,
      hasSites: false,
      hasRola: false,
      hasMixanismoi: false,
      hasSkiadia: false,
      hasSideries: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTablades: true,
      hasColorCost: false,
      hasExtraCost: false,
      hasThermalEfficiency: false,
    });
  };

  const showColorCost = () => {
    setTranslations({
      ...translations,
      hasCrystals: false,
      hasSites: false,
      hasRola: false,
      hasMixanismoi: false,
      hasSkiadia: false,
      hasSideries: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTablades: false,
      hasColorCost: true,
      hasExtraCost: false,
      hasThermalEfficiency: false,
    });
  };

  const showExtraCost = () => {
    setTranslations({
      ...translations,
      hasCrystals: false,
      hasSites: false,
      hasRola: false,
      hasMixanismoi: false,
      hasSkiadia: false,
      hasSideries: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTablades: false,
      hasColorCost: false,
      hasExtraCost: true,
      hasThermalEfficiency: false,
    });
  };

  const showThermalEfficiency = () => {
    setTranslations({
      ...translations,
      hasCrystals: false,
      hasSites: false,
      hasRola: false,
      hasMixanismoi: false,
      hasSkiadia: false,
      hasSideries: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTablades: false,
      hasColorCost: false,
      hasExtraCost: false,
      hasThermalEfficiency: true,
    });
  };
  const _delete = (id, type) => {
    console.log(id, type);
    setTranslations({
      ...translations,
      isLoading: true,
    });
    translationsService.delete(id).then((data) => {
      console.log(data);
      if (data.message === undefined || data.message === null) {
        if (type === config.product_extras_yalo.crystal) {
          setTranslations({
            ...translations,
            isLoading: false,
            crystals: translations.crystals.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.product_extras_yalo.sita) {
          setTranslations({
            ...translations,
            isLoading: false,
            sites: translations.sites.filter((x) => x.translation_id !== id),
          });
        } else if (type === config.product_extras_yalo.rola) {
          setTranslations({
            ...translations,
            isLoading: false,
            rola: translations.rola.filter((x) => x.translation_id !== id),
          });
        } else if (type === config.product_extras_yalo.mechs) {
          setTranslations({
            ...translations,
            isLoading: false,
            mixanismoi: translations.mixanismoi.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.product_extras_yalo.skia) {
          setTranslations({
            ...translations,
            isLoading: false,
            skiadia: translations.skiadia.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.product_extras_yalo.iron) {
          setTranslations({
            ...translations,
            isLoading: false,
            sideries: translations.sideries.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.product_extras_yalo.alarm) {
          setTranslations({
            ...translations,
            isLoading: false,
            alarm: translations.alarm.filter((x) => x.translation_id !== id),
          });
        } else if (type === config.product_extras_yalo.kaitia) {
          setTranslations({
            ...translations,
            isLoading: false,
            kaitia: translations.kaitia.filter((x) => x.translation_id !== id),
          });
        } else if (type === config.product_extras_yalo.tamplades) {
          setTranslations({
            ...translations,
            isLoading: false,
            tablades: translations.tablades.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.product_extras_yalo.color) {
          setTranslations({
            ...translations,
            isLoading: false,
            colorCost: translations.colorCost.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.product_extras_yalo.wood) {
          setTranslations({
            ...translations,
            isLoading: false,
            extraCost: translations.extraCost.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.product_extras_yalo.thermal) {
          setTranslations({
            ...translations,
            isLoading: false,
            thermalEfficiency: translations.thermalEfficiency.filter(
              (x) => x.translation_id !== id
            ),
          });
        }
      } else {
        setTranslations({
          ...translations,
          isLoading: false,
          error: data.message,
        });
      }
    });
  };

  const buttonName = [
    "Κρύσταλλα",
    "Σήτες",
    "Ρολά",
    "Μηχανισμοί",
    "Σκιάδια",
    "Σιδεριές",
    "Συαγερμός",
    "Καϊτια",
    "Ταμπλάδες",
    "Επιβάρυνση για Χρώμα",
    "Επιβαρύνσεις",
    "Θερμική Απόδοση",
  ];

  return (
    <section>
      <h1> Χαρακτηριστικά Υαλοστασίων - Εξώθυρων</h1>
      {translations.isLoading && <p>Loading...</p>}
      {translations.error && <p>{translations.error}</p>}
      {translations.hasResult && (
        <section>
          <button
            onClick={showCrystals}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[0]}
          </button>
          <button
            onClick={showSites}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[1]}
          </button>
          <button
            onClick={showRola}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[2]}
          </button>
          <button
            onClick={showMixanismoi}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[3]}
          </button>
          <button
            onClick={showSkiadia}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[4]}
          </button>
          <button
            onClick={showSideries}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[5]}
          </button>
          <button
            onClick={showAlarm}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[6]}
          </button>
          <button
            onClick={showKaitia}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[7]}
          </button>
          <button
            onClick={showTablades}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[8]}
          </button>
          <button
            onClick={showColorCost}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[9]}
          </button>
          <button
            onClick={showExtraCost}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[10]}
          </button>
          <button
            onClick={showThermalEfficiency}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[11]}
          </button>
        </section>
      )}
      <section className="table-responsive">
        {translations.hasCrystals && (
          <div>
            <h5 className="text-primary">{buttonName[0]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_yalo.crystal}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.crystals &&
                  translations.crystals.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_yalo.crystal}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_yalo.crystal
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasSites && (
          <div>
            <h5 className="text-primary">{buttonName[1]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_yalo.sita}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.sites &&
                  translations.sites.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_yalo.sita}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_yalo.sita
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasRola && (
          <div>
            <h5 className="text-primary">{buttonName[2]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_yalo.rola}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.rola &&
                  translations.rola.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_yalo.rola}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_yalo.rola
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasMixanismoi && (
          <div>
            <h5 className="text-primary">{buttonName[3]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_yalo.mechs}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.mixanismoi &&
                  translations.mixanismoi.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_yalo.mechs}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_yalo.mechs
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasSkiadia && (
          <div>
            <h5 className="text-primary">{buttonName[4]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_yalo.skia}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.skiadia &&
                  translations.skiadia.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_yalo.skia}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_yalo.skia
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasSideries && (
          <div>
            <h5 className="text-primary">{buttonName[5]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_yalo.iron}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.sideries &&
                  translations.sideries.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_yalo.iron}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_yalo.iron
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasAlarm && (
          <div>
            <h5 className="text-primary">{buttonName[6]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_yalo.alarm}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.alarm &&
                  translations.alarm.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_yalo.alarm}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_yalo.alarm
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasKaitia && (
          <div>
            <h5 className="text-primary">{buttonName[7]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_yalo.kaitia}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.kaitia &&
                  translations.kaitia.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_yalo.kaitia}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_yalo.kaitia
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasTablades && (
          <div>
            <h5 className="text-primary">{buttonName[8]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_yalo.tamplades}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.tablades &&
                  translations.tablades.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_yalo.tamplades}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_yalo.tamplades
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasColorCost && (
          <div>
            <h5 className="text-primary">{buttonName[9]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_yalo.color}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.colorCost &&
                  translations.colorCost.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_yalo.color}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_yalo.color
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasExtraCost && (
          <div>
            <h5 className="text-primary">{buttonName[10]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_yalo.wood}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.extraCost &&
                  translations.extraCost.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_yalo.wood}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_yalo.wood
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasThermalEfficiency && (
          <div>
            <h5 className="text-primary">{buttonName[11]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.product_extras_yalo.thermal}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.thermalEfficiency &&
                  translations.thermalEfficiency.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.product_extras_yalo.thermal}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.product_extras_yalo.thermal
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </section>
    </section>
  );
}

export default ExtraYaloList;
