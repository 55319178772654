import React, { useState, useEffect, useRef } from "react";
import config from "../../conf/Config";
import Select from "react-select";
import { productsService } from "../../_services/productsService";
import { translationsService } from "../../_services/translationsService";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import FavoriteProductsList from "../favoriteProducts/FavoriteProductsList";
import AWS from "aws-sdk";
import { v4 as uuid } from "uuid";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

function AddEditOfferProducts() {
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { offer_key, offerPass, favPass, imgPass } = location.state;
  // console.log(offer_key, offerPass, favPass);
  const { product_key } = useParams();
  const isAddMode = !product_key;
  // console.log(isAddMode, product_key);
  const [file, setFile] = useState();
  const [fileDimensions, setFileDimensions] = useState({});
  const [imgKey, setImgKey] = useState();
  const [typologyElOptions, setTypologyElOptions] = useState({});
  const [fillaElOptions, setFillaElOptions] = useState({});
  const [anoigmaElOptions, setAnoigmaElOptions] = useState({});
  const [tampladesElOptions, setTampladesElOptions] = useState({});
  const [kaitiaElOptions, setKaitiaElOptions] = useState({});
  const [sxedioElOptions, setSxedioElOptions] = useState({});
  const [koutiElOptions, setKoutiElOptions] = useState({});
  const [neededProductsOne, setNeededProductsOne] = useState(false);
  const [pageState, setPageState] = useState({
    options: "",
    goingForFav: false,
    fav_products: [],
    neededProducts: 1,
    selectedFavProduct: "",
    selectedProductKey: "",
    selectedProductHasSchema: false,
    // selectedProductNameId: "",
    typologies: [],
    hasTypologies: false,
    typologyFilla: [],
    hasFilla: false,
    typologyAnoigma: [],
    hasAnoigma: false,
    typologyTamplades: [],
    hasTamplades: false,
    typologyKaitia: [],
    hasKaitia: false,
    typologySxedio: [],
    hasSxedio: false,
    typologyKouti: [],
    hasKouti: false,
    allTranslationsPlacements: [],
    selectedProductPlacementH: "0",
    selectedProduct_typologies: "",
    selectedProduct_hasTypologies: false,
    selectedProduct_typologyFilla: "",
    selectedProduct_hasFilla: false,
    selectedProduct_typologyAnoigma: "",
    selectedProduct_hasAnoigma: false,
    selectedProduct_typologyTamplades: "",
    selectedProduct_hasTamplades: false,
    selectedProduct_typologyKaitia: "",
    selectedProduct_hasKaitia: false,
    selectedProduct_typologySxedio: "",
    selectedProduct_hasSxedio: false,
    selectedProduct_typologyKouti: "",
    selectedProduct_hasKouti: false,
    selectedProduct_img: "",
    selectedProduct_imgId: "",
    isLoading: false,
    // refresh: false,
    error: "",
  });

  // const imgKey = "";
  console.log(pageState.selectedProduct_typologies);
  useEffect(() => {
    console.log("UUID");
    if (imgKey === undefined && isAddMode) {
      setImgKey(uuid());
    }
    getAllTypologies();
  }, []);

  useEffect(() => {
    console.log(pageState.typologies);
    console.log(pageState.hasTypologies);

    if (isAddMode && pageState.typologies) {
      //required to fill Bootstrap Filter in Add mode
      handleFilterTypology(pageState.typologies);
      handleFilterFilla(pageState.typologyFilla);
      handleFilterAnoigma(pageState.typologyAnoigma);
      handleFilterTamplades(pageState.typologyTamplades);
      handleFilterKaitia(pageState.typologyKaitia);
      handleFilterSxedio(pageState.typologySxedio);
      handleFilterKouti(pageState.typologyKouti);
    }
    if (!isAddMode && pageState.typologies.length > 0) {
      getSelectionsTypologies();
      console.log(pageState.selectedProduct_typologies);
      handleFilterTypology(pageState.typologies);
      handleFilterFilla(pageState.typologyFilla);
    }
  }, [pageState.options]);

  const getAllFavorites = () => {
    if (pageState.neededProducts > 1 || neededProductsOne) {
      productsService.getFav().then((data) => {
        console.log("Get all getFav:" + JSON.stringify(data));
        if (data.message === undefined || data.message === null) {
          setPageState({
            ...pageState,
            goingForFav: true,
            fav_products: data,
            isLoading: false,
          });
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      });
    } else {
      alert(
        "Επιλέξτε πρώτα, πόσες φορές θέλετε να επαναληφθεί το αγαπημένο σας προϊόν!"
      );
      setNeededProductsOne(true);
    }
  };

  const selectChangeHandler = (e) => {
    // console.log(e);
    if (e.value) {
      setPageState({
        ...pageState,
        neededProducts: 1,
        goingForFav: false,
        selectedProductKey: e.value,
        // selectedProductNameId: e.label
      });
    }
  };

  const getAllTypologies = () => {
    setPageState({
      ...pageState,
      isLoading: true,
    });
    // offerProductsService
    // productsService
    translationsService.getAll().then((data) => {
      // console.log("Get all Translations:" + JSON.stringify(data));
      console.log(data);
      if (data.message === undefined || data.message === null) {
        // Successful response
        let _options = [];
        let _typologies = [];
        let _typologyFilla = [];
        let _typologyAnoigma = [];
        let _typologyTamplades = [];
        let _typologyKaitia = [];
        let _typologySxedio = [];
        let _typologyKouti = [];
        let _alltranslations = [];
        data.map((translation, pos) => {
          // console.log("translation.type is :" + JSON.stringify(translation.type));
          _alltranslations = [
            ..._alltranslations,
            {
              id: translation.translation_id,
              h: parseFloat(translation.placement_h) || 0,
            },
          ];
          // console.log(_alltranslations)
          if (translation.type === config.translationTypes.productName) {
            _options = [
              ..._options,
              {
                value: translation.translation_id,
                label: translation.el,
              },
            ];
          } else if (translation.type === config.translationTypes.typology) {
            _typologies = [..._typologies, translation];
          } else if (
            translation.type === config.translationTypes.typologyFilla
          ) {
            _typologyFilla = [..._typologyFilla, translation];
          } else if (
            translation.type === config.translationTypes.typologyAnoigma
          ) {
            _typologyAnoigma = [..._typologyAnoigma, translation];
          } else if (
            translation.type === config.translationTypes.typologyTamplades
          ) {
            _typologyTamplades = [..._typologyTamplades, translation];
          } else if (
            translation.type === config.translationTypes.typologyKaitia
          ) {
            _typologyKaitia = [..._typologyKaitia, translation];
          } else if (
            translation.type === config.translationTypes.typologySxedio
          ) {
            _typologySxedio = [..._typologySxedio, translation];
          } else if (
            translation.type === config.translationTypes.typologyKouti
          ) {
            _typologyKouti = [..._typologyKouti, translation];
          }
          return true;
        });
        setPageState({
          ...pageState,
          options: _options,
          typologies: _typologies,
          typologyFilla: _typologyFilla,
          typologyAnoigma: _typologyAnoigma,
          typologyTamplades: _typologyTamplades,
          typologyKaitia: _typologyKaitia,
          typologySxedio: _typologySxedio,
          typologyKouti: _typologyKouti,
          allTranslationsPlacements: _alltranslations,
          isLoading: false,
          error: "",
        });
        console.log(_typologies);
      } else {
        setPageState({
          ...pageState,
          isLoading: false,
          error: data.message,
        });
      }
    });
  };

  const getSelectionsTypologies = () => {
    // console.log(
    //   "typologies in select :" + JSON.stringify(pageState.typologies)
    // );
    setPageState({
      ...pageState,
      isLoading: true,
      error: "",
    });
    // console.log("product_key :" + JSON.stringify(product_key));
    productsService.getById(product_key).then((data) => {
      // console.log("data is :" + JSON.stringify(data));
      console.log(data);
      if (data.message === undefined || data.message === null) {
        setPageState({
          ...pageState,
          selectedProductKey: data.product_name && data.product_name.id,
          selectedProduct_img: data.img && data.img,
          selectedProduct_imgId: data.img_uid && data.img_uid,
          selectedProductPlacementH: data.placement_h && data.placement_h,
          selectedProduct_typologies: data.typology && data.typology.id,
          selectedProduct_typologyFilla: data.typology_1 && data.typology_1.id,
          selectedProduct_typologyAnoigma:
            data.typology_2 && data.typology_2.id,
          selectedProduct_typologyTamplades:
            data.typology_3 && data.typology_3.id,
          selectedProduct_typologyKaitia: data.typology_4 && data.typology_4.id,
          selectedProduct_typologySxedio: data.typology_5 && data.typology_5.id,
          selectedProduct_typologyKouti: data.typology_6 && data.typology_6.id,
          isLoading: false,
        });
      } else {
        if (data.message.startsWith("Product with product_key:")) {
          setPageState({
            ...pageState,
            selectedProductKey: "",
            selectedProduct_typologies: "",
            selectedProductPlacementH: "0",
            selectedProduct_typologyFilla: "",
            selectedProduct_typologyAnoigma: "",
            selectedProduct_typologyTamplades: "",
            selectedProduct_typologyKaitia: "",
            selectedProduct_typologySxedio: "",
            selectedProduct_typologyKouti: "",
            isLoading: false,
            error: data.message,
          });
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      }
    });
    // thelei By schemas_key
    // product_name.id
  };

  //---------- Bootstrap handleFilter ---------------------------------
  const handleFilterTypology = (data) => {
    console.log(data);
    const uniqueTypologyEl = [
      ...new Set(
        data
          .map((item) => (item.el === null ? "" : item.el))
          .filter((emptyItem) => emptyItem !== "")
      ),
    ];
    setTypologyElOptions(
      uniqueTypologyEl.map((data) => {
        return {
          value: data,
          label: data,
        };
      })
    );
  };

  const handleFilterFilla = (data) => {
    console.log(data);
    const uniqueTypologyEl = [
      ...new Set(
        data
          .map((item) => (item.el === null ? "" : item.el))
          .filter((emptyItem) => emptyItem !== "")
      ),
    ];
    setFillaElOptions(
      uniqueTypologyEl.map((data) => {
        return {
          value: data,
          label: data,
        };
      })
    );
  };

  const handleFilterAnoigma = (data) => {
    console.log(data);
    const uniqueTypologyEl = [
      ...new Set(
        data
          .map((item) => (item.el === null ? "" : item.el))
          .filter((emptyItem) => emptyItem !== "")
      ),
    ];
    setAnoigmaElOptions(
      uniqueTypologyEl.map((data) => {
        return {
          value: data,
          label: data,
        };
      })
    );
  };

  const handleFilterTamplades = (data) => {
    console.log(data);
    const uniqueTypologyEl = [
      ...new Set(
        data
          .map((item) => (item.el === null ? "" : item.el))
          .filter((emptyItem) => emptyItem !== "")
      ),
    ];
    setTampladesElOptions(
      uniqueTypologyEl.map((data) => {
        return {
          value: data,
          label: data,
        };
      })
    );
  };

  const handleFilterKaitia = (data) => {
    console.log(data);
    const uniqueTypologyEl = [
      ...new Set(
        data
          .map((item) => (item.el === null ? "" : item.el))
          .filter((emptyItem) => emptyItem !== "")
      ),
    ];
    setKaitiaElOptions(
      uniqueTypologyEl.map((data) => {
        return {
          value: data,
          label: data,
        };
      })
    );
  };

  const handleFilterSxedio = (data) => {
    console.log(data);
    const uniqueTypologyEl = [
      ...new Set(
        data
          .map((item) => (item.el === null ? "" : item.el))
          .filter((emptyItem) => emptyItem !== "")
      ),
    ];
    setSxedioElOptions(
      uniqueTypologyEl.map((data) => {
        return {
          value: data,
          label: data,
        };
      })
    );
  };

  const handleFilterKouti = (data) => {
    console.log(data);
    const uniqueTypologyEl = [
      ...new Set(
        data
          .map((item) => (item.el === null ? "" : item.el))
          .filter((emptyItem) => emptyItem !== "")
      ),
    ];
    setKoutiElOptions(
      uniqueTypologyEl.map((data) => {
        return {
          value: data,
          label: data,
        };
      })
    );
  };

  //----------- Bootstrap changeHandler ---------------------------------------
  const changeHandlerTypology = (cellContent) => {
    let selection = "";
    const newTypologies = pageState.typologies.map((tra) => {
      if (tra.translation_id === cellContent) {
        if (pageState.selectedProduct_typologies === cellContent)
          return { ...tra, help: uuid() };
        selection = tra.translation_id;
        return { ...tra, help: uuid() };
      } else if (tra.translation_id === pageState.selectedProduct_typologies) {
        return { ...tra, help: uuid() };
      } else return tra;
    });
    setPageState({
      ...pageState,
      typologies: newTypologies,
      selectedProduct_typologies: selection,
    });
  };

  const changeHandlerFilla = (cellContent) => {
    let selection = "";
    const newTypologies = pageState.typologyFilla.map((tra) => {
      if (tra.translation_id === cellContent) {
        if (pageState.selectedProduct_typologyFilla === cellContent)
          return { ...tra, help: uuid() };
        selection = tra.translation_id;
        return { ...tra, help: uuid() };
      } else if (
        tra.translation_id === pageState.selectedProduct_typologyFilla
      ) {
        return { ...tra, help: uuid() };
      } else return tra;
      // return tra;
    });
    setPageState({
      ...pageState,
      typologyFilla: newTypologies,
      selectedProduct_typologyFilla: selection,
    });
  };

  const changeHandlerAnoigma = (cellContent) => {
    let selection = "";
    const newTypologies = pageState.typologyAnoigma.map((tra) => {
      if (tra.translation_id === cellContent) {
        if (pageState.selectedProduct_typologyAnoigma === cellContent)
          return { ...tra, help: uuid() };
        selection = tra.translation_id;
        return { ...tra, help: uuid() };
      } else if (
        tra.translation_id === pageState.selectedProduct_typologyAnoigma
      ) {
        return { ...tra, help: uuid() };
      } else return tra;
    });
    setPageState({
      ...pageState,
      typologyAnoigma: newTypologies,
      selectedProduct_typologyAnoigma: selection,
    });
  };

  const changeHandlerTamplades = (cellContent) => {
    let selection = "";
    const newTypologies = pageState.typologyTamplades.map((tra) => {
      if (tra.translation_id === cellContent) {
        if (pageState.selectedProduct_typologyTamplades === cellContent)
          return { ...tra, help: uuid() };
        selection = tra.translation_id;
        return { ...tra, help: uuid() };
      } else if (
        tra.translation_id === pageState.selectedProduct_typologyTamplades
      ) {
        return { ...tra, help: uuid() };
      } else return tra;
    });
    setPageState({
      ...pageState,
      typologyTamplades: newTypologies,
      selectedProduct_typologyTamplades: selection,
    });
  };

  const changeHandlerKaitia = (cellContent) => {
    let selection = "";
    const newTypologies = pageState.typologyKaitia.map((tra) => {
      if (tra.translation_id === cellContent) {
        if (pageState.selectedProduct_typologyKaitia === cellContent)
          return { ...tra, help: uuid() };
        selection = tra.translation_id;
        return { ...tra, help: uuid() };
      } else if (
        tra.translation_id === pageState.selectedProduct_typologyKaitia
      ) {
        return { ...tra, help: uuid() };
      } else return tra;
    });
    setPageState({
      ...pageState,
      typologyKaitia: newTypologies,
      selectedProduct_typologyKaitia: selection,
    });
  };

  const changeHandlerSxedio = (cellContent) => {
    let selection = "";
    const newTypologies = pageState.typologySxedio.map((tra) => {
      if (tra.translation_id === cellContent) {
        if (pageState.selectedProduct_typologySxedio === cellContent)
          return { ...tra, help: uuid() };
        selection = tra.translation_id;
        return { ...tra, help: uuid() };
      } else if (
        tra.translation_id === pageState.selectedProduct_typologySxedio
      ) {
        return { ...tra, help: uuid() };
      } else return tra;
    });
    setPageState({
      ...pageState,
      typologySxedio: newTypologies,
      selectedProduct_typologySxedio: selection,
    });
  };

  const changeHandlerKouti = (cellContent) => {
    let selection = "";
    const newTypologies = pageState.typologyKouti.map((tra) => {
      if (tra.translation_id === cellContent) {
        if (pageState.selectedProduct_typologyKouti === cellContent)
          return { ...tra, help: uuid() };
        selection = tra.translation_id;
        return { ...tra, help: uuid() };
      } else if (
        tra.translation_id === pageState.selectedProduct_typologyKouti
      ) {
        return { ...tra, help: uuid() };
      } else return tra;
    });
    setPageState({
      ...pageState,
      typologyKouti: newTypologies,
      selectedProduct_typologyKouti: selection,
    });
  };
  //----------- Bootstrap columns ---------------------------------------
  const columnsTypology = [
    // {
    //   dataField: "translation_id",
    //   text: "ID",
    //   hidden: "true",
    // },
    {
      dataField: "el",
      text: "Ελληνικά",
      style: { width: "30%" },
      sort: true,
      filter: selectFilter({
        options: typologyElOptions,
      }),
    },
    {
      dataField: "en",
      text: "Αγγλικά",
      style: { width: "30%" },
      sort: true,
      // filter: selectFilter({
      //   options: typologyEnOptions,
      // }),
    },
    {
      dataField: "it",
      text: "Ιταλικά",
      style: { width: "30%" },
      sort: true,
      // filter: selectFilter({
      //   options: typologyItOptions,
      // }),
    },
    {
      dataField: "translation_id",
      text: "Επιλογή",
      style: { width: "10%" },
      formatter: (cellContent, row) => (
        <input
          disabled={favPass}
          // type="switch"
          type="checkbox"
          className="form-check-input"
          onChange={(e) => changeHandlerTypology(cellContent)}
          // onChange={(e) => changeHandlerTypology(row, e.target.value)}
          checked={pageState.selectedProduct_typologies === cellContent}
        // checked={checkedHandlerTypology(cellContent)}
        />
      ),
    },
  ];

  const columnsFilla = [
    {
      dataField: "el",
      text: "Ελληνικά",
      style: { width: "30%" },
      sort: true,
      filter: selectFilter({
        options: fillaElOptions,
      }),
    },
    {
      dataField: "en",
      text: "Αγγλικά",
      style: { width: "30%" },
      sort: true,
    },
    {
      dataField: "it",
      text: "Ιταλικά",
      style: { width: "30%" },
      sort: true,
    },
    {
      dataField: "translation_id",
      text: "Επιλογή",
      style: { width: "10%" },
      formatter: (cellContent) => (
        <input
          disabled={favPass}
          type="checkbox"
          className="form-check-input"
          onChange={() => changeHandlerFilla(cellContent)}
          checked={pageState.selectedProduct_typologyFilla === cellContent}
        />
      ),
    },
  ];

  const columnsAnoigma = [
    {
      dataField: "el",
      text: "Ελληνικά",
      style: { width: "30%" },
      sort: true,
      filter: selectFilter({
        options: anoigmaElOptions,
      }),
    },
    {
      dataField: "en",
      text: "Αγγλικά",
      style: { width: "30%" },
      sort: true,
    },
    {
      dataField: "it",
      text: "Ιταλικά",
      style: { width: "30%" },
      sort: true,
    },
    {
      dataField: "translation_id",
      text: "Επιλογή",
      style: { width: "10%" },
      formatter: (cellContent) => (
        <input
          disabled={favPass}
          type="checkbox"
          className="form-check-input"
          onChange={() => changeHandlerAnoigma(cellContent)}
          checked={pageState.selectedProduct_typologyAnoigma === cellContent}
        />
      ),
    },
  ];

  const columnsTamplades = [
    {
      dataField: "el",
      text: "Ελληνικά",
      style: { width: "30%" },
      sort: true,
      filter: selectFilter({
        options: tampladesElOptions,
      }),
    },
    {
      dataField: "en",
      text: "Αγγλικά",
      style: { width: "30%" },
      sort: true,
    },
    {
      dataField: "it",
      text: "Ιταλικά",
      style: { width: "30%" },
      sort: true,
    },
    {
      dataField: "translation_id",
      text: "Επιλογή",
      style: { width: "10%" },
      formatter: (cellContent) => (
        <input
          disabled={favPass}
          type="checkbox"
          className="form-check-input"
          onChange={() => changeHandlerTamplades(cellContent)}
          checked={pageState.selectedProduct_typologyTamplades === cellContent}
        />
      ),
    },
  ];

  const columnsKaitia = [
    {
      dataField: "el",
      text: "Ελληνικά",
      style: { width: "30%" },
      sort: true,
      filter: selectFilter({
        options: kaitiaElOptions,
      }),
    },
    {
      dataField: "en",
      text: "Αγγλικά",
      style: { width: "30%" },
      sort: true,
    },
    {
      dataField: "it",
      text: "Ιταλικά",
      style: { width: "30%" },
      sort: true,
    },
    {
      dataField: "translation_id",
      text: "Επιλογή",
      style: { width: "10%" },
      formatter: (cellContent) => (
        <input
          disabled={favPass}
          type="checkbox"
          className="form-check-input"
          onChange={() => changeHandlerKaitia(cellContent)}
          checked={pageState.selectedProduct_typologyKaitia === cellContent}
        />
      ),
    },
  ];

  const columnsSxedio = [
    {
      dataField: "el",
      text: "Ελληνικά",
      style: { width: "30%" },
      sort: true,
      filter: selectFilter({
        options: sxedioElOptions,
      }),
    },
    {
      dataField: "en",
      text: "Αγγλικά",
      style: { width: "30%" },
      sort: true,
    },
    {
      dataField: "it",
      text: "Ιταλικά",
      style: { width: "30%" },
      sort: true,
    },
    {
      dataField: "translation_id",
      text: "Επιλογή",
      style: { width: "10%" },
      formatter: (cellContent) => (
        <input
          disabled={favPass}
          type="checkbox"
          className="form-check-input"
          onChange={() => changeHandlerSxedio(cellContent)}
          checked={pageState.selectedProduct_typologySxedio === cellContent}
        />
      ),
    },
  ];

  const columnsKouti = [
    {
      dataField: "el",
      text: "Ελληνικά",
      style: { width: "30%" },
      sort: true,
      filter: selectFilter({
        options: koutiElOptions,
      }),
    },
    {
      dataField: "en",
      text: "Αγγλικά",
      style: { width: "30%" },
      sort: true,
    },
    {
      dataField: "it",
      text: "Ιταλικά",
      style: { width: "30%" },
      sort: true,
    },
    {
      dataField: "translation_id",
      text: "Επιλογή",
      style: { width: "10%" },
      formatter: (cellContent) => (
        <input
          disabled={favPass}
          type="checkbox"
          className="form-check-input"
          onChange={() => changeHandlerKouti(cellContent)}
          checked={pageState.selectedProduct_typologyKouti === cellContent}
        />
      ),
    },
  ];
  //------------------- Bootstrap end --------------------------------------

  const showTypologies = () => {
    // console.log(pageState.selectedProduct_typologies);
    // console.log(pageState.typologies);
    if (pageState.typologies.length > 0) {
      setPageState({
        ...pageState,

        hasTypologies: true,
        hasFilla: false,
        hasAnoigma: false,
        hasTamplades: false,
        hasKaitia: false,
        hasSxedio: false,
        hasKouti: false,
      });
    }
  };

  const showFilla = () => {
    if (pageState.typologyFilla.length > 0) {
      setPageState({
        ...pageState,

        hasTypologies: false,
        hasFilla: true,
        hasAnoigma: false,
        hasTamplades: false,
        hasKaitia: false,
        hasSxedio: false,
        hasKouti: false,
      });
    }
  };

  const showAnoigma = () => {
    if (pageState.typologyAnoigma.length > 0) {
      setPageState({
        ...pageState,

        hasTypologies: false,
        hasFilla: false,
        hasAnoigma: true,
        hasTamplades: false,
        hasKaitia: false,
        hasSxedio: false,
        hasKouti: false,
      });
    }
  };

  const showTamplades = () => {
    if (pageState.typologyTamplades.length > 0) {
      setPageState({
        ...pageState,

        hasTypologies: false,
        hasFilla: false,
        hasAnoigma: false,
        hasTamplades: true,
        hasKaitia: false,
        hasSxedio: false,
        hasKouti: false,
      });
    }
  };

  const showKaitia = () => {
    if (pageState.typologyKaitia.length > 0) {
      setPageState({
        ...pageState,

        hasTypologies: false,
        hasFilla: false,
        hasAnoigma: false,
        hasTamplades: false,
        hasKaitia: true,
        hasSxedio: false,
        hasKouti: false,
      });
    }
  };

  const showSxedio = () => {
    if (pageState.typologySxedio.length > 0) {
      setPageState({
        ...pageState,

        hasTypologies: false,
        hasFilla: false,
        hasAnoigma: false,
        hasTamplades: false,
        hasKaitia: false,
        hasSxedio: true,
        hasKouti: false,
      });
    }
  };

  const showKouti = () => {
    if (pageState.typologyKouti.length > 0) {
      setPageState({
        ...pageState,

        hasTypologies: false,
        hasFilla: false,
        hasAnoigma: false,
        hasTamplades: false,
        hasKaitia: false,
        hasSxedio: false,
        hasKouti: true,
      });
    }
  };
  let myKeyId = process.env.REACT_APP_ENV === 'production'
    ? config.production.aws.keyId
    : process.env.REACT_APP_ENV === 'nikos-aws'
      ? config["nikos-aws"].aws.keyId
      : '';

  let myAccessKey = process.env.REACT_APP_ENV === 'production'
    ? config.production.aws.accesskey
    : process.env.REACT_APP_ENV === 'nikos-aws'
      ? config["nikos-aws"].aws.accesskey
      : '';


  AWS.config.update({
    accessKeyId: myKeyId,
    secretAccessKey: myAccessKey,
  });

  console.log(process.env.REACT_APP_ENV)
  let bucketName;
  if (process.env.REACT_APP_ENV === 'production') {
    bucketName = 'prod-sylorimgbucket';
  } else if (process.env.REACT_APP_ENV === 'nikos-aws') {
    bucketName = 'sylorimgbucket';
  } else {
    bucketName = 'sylorimgbucket';
  }


  const myBucket = new AWS.S3({
    params: { Bucket: bucketName },
    region: "eu-central-1",
  });

  const upload_img = (fav_product_key = "", isFav = false, my_file) => {
    console.log(my_file);
    if (my_file === undefined) {
      saveProductSchema(fav_product_key, isFav, "");
    } else {
      // console.log(`myKeyId selected is ${myKeyId}`)
      // console.log(`myAccessKey selected is ${myAccessKey}`)
      // console.log(`BucketName selected is ${bucketName}`)
      const params = {
        ACL: "public-read",
        Body: my_file,
        Bucket: bucketName,
      };
      console.log(pageState.selectedProduct_imgId);
      console.log(
        "current pageState.selectedProduct_imgId: " +
        pageState.selectedProduct_imgId
      );
      if (
        pageState.selectedProduct_imgId !== undefined &&
        pageState.selectedProduct_imgId !== "" &&
        pageState.selectedProduct_imgId !== null
      ) {
        params.Key = pageState.selectedProduct_imgId;
        setImgKey(pageState.selectedProduct_imgId);
      } else {
        if (imgKey !== undefined) {
          params.Key = imgKey;
        } else {
          let temp_key = uuid();
          params.Key = temp_key;
          setImgKey(temp_key);
        }
      }


      // console.log(myKeyId);
      // console.log(myBucket);
      // console.log(params);

      myBucket
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100));
        })
        .send((err) => {
          if (err) {
            console.log(err);
            setPageState({
              ...pageState,
              isLoading: false,
              error: err,
            });
          } else {
            saveProductSchema(fav_product_key, isFav, params.Key);
          }
        });
    }
  };

  const saveProductSchema = (
    fav_product_key = "",
    isFav = false,
    imageUniqueKey
  ) => {
    setPageState({
      ...pageState,
      hasTypologies: false,
      hasFilla: false,
      hasAnoigma: false,
      hasTamplades: false,
      hasKaitia: false,
      hasSxedio: false,
      hasKouti: false,
      isLoading: true,
    });
    let temp_h = 0;
    console.log(pageState.allTranslationsPlacements);
    console.log(pageState.allTranslationsPlacements.id);

    pageState.allTranslationsPlacements &&
      pageState.allTranslationsPlacements.map((kv) => {
        if (kv.id === pageState.selectedProductKey) {
          temp_h += kv.h;
        }
        if (kv.id === pageState.selectedProduct_typologies) {
          temp_h += kv.h;
        }
        if (kv.id === pageState.selectedProduct_typologyFilla) {
          temp_h += kv.h;
        }
        if (kv.id === pageState.selectedProduct_typologyAnoigma) {
          temp_h += kv.h;
        }
        if (kv.id === pageState.selectedProduct_typologyTamplades) {
          temp_h += kv.h;
        }
        if (kv.id === pageState.selectedProduct_typologyKaitia) {
          temp_h += kv.h;
        }
        if (kv.id === pageState.selectedProduct_typologySxedio) {
          temp_h += kv.h;
        }
        if (kv.id === pageState.selectedProduct_typologyKouti) {
          temp_h += kv.h;
        }
      });

    console.log(temp_h);
    const request_body = {
      product_name: pageState.selectedProductKey,
      typology: pageState.selectedProduct_typologies,
      placement_h: `${temp_h}`,
      typology_1: pageState.selectedProduct_typologyFilla,
      typology_2: pageState.selectedProduct_typologyAnoigma,
      typology_3: pageState.selectedProduct_typologyTamplades,
      typology_4: pageState.selectedProduct_typologyKaitia,
      typology_5: pageState.selectedProduct_typologySxedio,
      typology_6: pageState.selectedProduct_typologyKouti,
    };
    if (isFav === true) {
      request_body.fav = true;
    }
    if (file !== undefined) {
      request_body.img = `https://${bucketName}.s3.eu-central-1.amazonaws.com/${imageUniqueKey}`;
      request_body.img_uid = `${imageUniqueKey}`;
      request_body.img_h = `${fileDimensions.height}`;
      request_body.img_w = `${fileDimensions.width}`;
    }
    if (!isAddMode) {
      console.log(
        "Sending Update request" +
        JSON.stringify(request_body) +
        "key is: " +
        product_key
      );
      productsService.update(product_key, request_body).then((data) => {
        console.log("productsService.update resp is :" + JSON.stringify(data));
        if (data.message === undefined || data.message === null) {
          setPageState({
            ...pageState,
            isLoading: false,
          });
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      });
    } else {
      //is Add mode now
      console.log(fav_product_key);
      request_body.offer = offer_key;

      if (fav_product_key !== "") {
        request_body.product = fav_product_key;
      }
      request_body.product_name = pageState.selectedProductKey;
      console.log(request_body);
      console.log(pageState.neededProducts);
      productsService
        .create(pageState.neededProducts, request_body)
        .then((data) => {
          console.log(
            "productsService.create resp is :" + JSON.stringify(data)
          );
          if (data.message === undefined || data.message === null) {
            setImgKey(uuid());
            setPageState({
              ...pageState,
              selectedProductKey: "",
              selectedProductHasSchema: false,
              selectedProduct_typologies: "",
              selectedProductPlacementH: "0",
              selectedProduct_typologyFilla: "",
              selectedProduct_typologyAnoigma: "",
              selectedProduct_typologyTamplades: "",
              selectedProduct_typologyKaitia: "",
              selectedProduct_typologySxedio: "",
              selectedProduct_typologyKouti: "",
              isLoading: false,
            });
          } else {
            setPageState({
              ...pageState,
              isLoading: false,
              error: data.message,
            });
          }
        });
    }
  };

  // }

  // const deleteProductSchema = () => {
  //   if (isAddMode) {
  //     setPageState({
  //       ...pageState,
  //       hasTypologies: false,
  //       hasFilla: false,
  //       hasAnoigma: false,
  //       hasTamplades: false,
  //       hasKaitia: false,
  //       hasSxedio: false,
  //       hasKouti: false,
  //       isLoading: false,
  //       selectedProductKey: "",
  //       selectedProduct_typologies: "",
  //       selectedProduct_typologyFilla: "",
  //       selectedProduct_typologyAnoigma: "",
  //       selectedProduct_typologyTamplades: "",
  //       selectedProduct_typologyKaitia: "",
  //       selectedProduct_typologySxedio: "",
  //       selectedProduct_typologyKouti: "",
  //     });
  //   } else {
  //     navigate(`/offers/edit/${offer_key}`);
  //   }
  // };

  const handleNeededProductsChange = (event) => {
    console.log(event.target.value);
    setPageState({
      ...pageState,
      neededProducts: event.target.value,
    });
  };

  const buttonName = [
    "Τυπολογίες",
    "Φύλλα",
    "Άνοιγμα",
    "Ταμπλάδες",
    "Καϊτια",
    "Σχέδιο",
    "Κουτί",
  ];

  const returnBack = () => {
    navigate(`/offers/edit/${offer_key}`);
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      console.log(e.target.files[0]);
      // upload_img(e.target.files[0]);
      setFile(e.target.files[0]);
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = (e) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = () => {
          const { height, width } = image;
          console.log(height, width);
          setFileDimensions({ height: height, width: width });
          // return [height, width];
        };
      };
    }
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  // const handleChange = (event) => {
  //   const fileUploaded = event.target.files[0];
  //   props.handleFile(fileUploaded);
  // };

  return (
    <section>
      {isAddMode && <h1>Προσθήκη προϊόντων προσφοράς</h1>}
      {!isAddMode && <h1>Επεξεργασία προϊόντος προσφοράς</h1>}
      {/* Πρώτη γραμμή πεδίων προσφοράς */}
      <div className="row mb-2 shadow p-2 bg-light rounded justify-content-center">
        <div className="col-md-2 g-0">
          <label className="form-label">Προσφορά</label>
          <input
            type="text"
            defaultValue={offerPass.offerIdPass}
            disabled={true}
            placeholder="Προσφορά"
            className="form-control"
          />
        </div>
        <div className="col-md-2 g-0">
          <label className="form-label">Ημερομηνία</label>
          <input
            type="text"
            defaultValue={offerPass.offerDatePass}
            disabled={true}
            placeholder="Ημερομηνία"
            className="form-control"
          />
        </div>
        <div className="col-md-2 g-0">
          <label className="form-label">Χρήστης</label>
          <input
            type="text"
            defaultValue={offerPass.offerUserPass}
            disabled={true}
            placeholder="Χρήστης"
            className="form-control"
          />
        </div>
        <div className="col-md-3 g-0">
          <label className="form-label">Πελάτης</label>
          <input
            type="text"
            defaultValue={offerPass.offerCustomerPass}
            disabled={true}
            placeholder="Πελάτης"
            className="form-control"
          />
        </div>
        <div className="col-md-2 g-0">
          <label className="form-label">Κατασκευαστής</label>
          <input
            type="text"
            defaultValue={offerPass.offerConstructorPass}
            disabled={true}
            placeholder="Κατασκευαστής"
            className="form-control"
          />
        </div>
      </div>
      {isAddMode && (
        <section className="form-inline">
          <div className="form-group">
            <label>Επανάληψη προϊόντος</label>
          </div>
          <div className="form-control mx-sm-2">
            <small className="text-mutted me-2">
              Εισάγετε πόσες φορές θέλετε να επαναληφθεί το προϊόν στην προσφορά
            </small>
            <input
              type="number"
              value={pageState.neededProducts}
              // className="form-control-sm"
              onChange={handleNeededProductsChange}
            />
          </div>
        </section>
      )}
      {/* Δημιουργία νέου προϊόντος */}
      <section>
        {isAddMode ? (
          <div>
            <h3>Δημιουργία νέου προϊόντος</h3>
            <div className="rounded d-flex justify-content-center mb-2">
              {pageState.options && (
                <Select
                  placeholder="Επιλέξτε ένα προϊόν από την λίστα "
                  options={pageState.options}
                  name="product_key"
                  // value={pageState.options.find(item => item.value === "product_key")}
                  onChange={selectChangeHandler}
                />
              )}
            </div>
          </div>
        ) : (
          // Edit Mode
          <div className="rounded d-flex justify-content-center mb-2">
            {pageState.options && (
              <Select
                isDisabled={favPass}
                options={pageState.options}
                name="product_key"
                value={pageState.options.find((item) => {
                  if (item.value === pageState.selectedProductKey) {
                    return item.label;
                  }
                })}
                onChange={selectChangeHandler}
              />
            )}
          </div>
        )}
        {pageState.isLoading && <p>Loading...</p>}
        {pageState.error && <p>{pageState.error}</p>}
        {(pageState.selectedProductKey || !isAddMode) && (
          <div>
            <section>
              <button
                onClick={showTypologies}
                className="btn btn-outline-secondary btn-sm m-1"
                style={{ backgroundColor: pageState.hasTypologies && "white" }}
              >
                {buttonName[0]}
              </button>
              <button
                onClick={showFilla}
                className="btn btn-outline-secondary btn-sm m-1"
                style={{ backgroundColor: pageState.hasFilla && "white" }}
              >
                {buttonName[1]}
              </button>
              <button
                onClick={showAnoigma}
                className="btn btn-outline-secondary btn-sm m-1"
                style={{ backgroundColor: pageState.hasAnoigma && "white" }}
              >
                {buttonName[2]}
              </button>
              <button
                onClick={showTamplades}
                className="btn btn-outline-secondary btn-sm m-1"
                style={{ backgroundColor: pageState.hasTamplades && "white" }}
              >
                {buttonName[3]}
              </button>
              <button
                onClick={showKaitia}
                className="btn btn-outline-secondary btn-sm m-1"
                style={{ backgroundColor: pageState.hasKaitia && "white" }}
              >
                {buttonName[4]}
              </button>
              <button
                onClick={showSxedio}
                className="btn btn-outline-secondary btn-sm m-1"
                style={{ backgroundColor: pageState.hasSxedio && "white" }}
              >
                {buttonName[5]}
              </button>
              <button
                onClick={showKouti}
                className="btn btn-outline-secondary btn-sm m-1"
                style={{ backgroundColor: pageState.hasKouti && "white" }}
              >
                {buttonName[6]}
              </button>
            </section>
            <section>
              {pageState.hasTypologies && (
                <div>
                  <h5 className="text-primary">{buttonName[0]}</h5>
                  <BootstrapTable
                    bootstrap4
                    striped
                    hover
                    condensed
                    keyField="translation_id"
                    data={pageState.typologies}
                    columns={columnsTypology}
                    filter={filterFactory()}
                    pagination={paginationFactory()}
                  // wrapperClasses="table-responsive"
                  />
                  {/* <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "30%" }}>Ιταλικά</th>
                        <th style={{ width: "10%" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageState.typologies &&
                        pageState.typologies.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              {t.translation_id}
                              <p></p>
                              {pageState.selectedProduct_typologies}
                              <Input
                                disabled={favPass}
                                type="switch"
                                checked={
                                  pageState.selectedProduct_typologies ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageState.selectedProduct_typologies ===
                                    t.translation_id
                                  ) {
                                    setPageState({
                                      ...pageState,
                                      selectedProduct_typologies: "",
                                    });
                                  } else {
                                    setPageState({
                                      ...pageState,
                                      selectedProduct_typologies:
                                        t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table> */}
                </div>
              )}
              {pageState.hasFilla && (
                <div>
                  <h5 className="text-primary">{buttonName[1]}</h5>
                  <BootstrapTable
                    bootstrap4
                    striped
                    hover
                    condensed
                    keyField="translation_id"
                    data={pageState.typologyFilla}
                    columns={columnsFilla}
                    filter={filterFactory()}
                    pagination={paginationFactory()}
                  />
                </div>
              )}
              {pageState.hasAnoigma && (
                <div>
                  <h5 className="text-primary">{buttonName[2]}</h5>
                  <BootstrapTable
                    bootstrap4
                    striped
                    hover
                    condensed
                    keyField="translation_id"
                    data={pageState.typologyAnoigma}
                    columns={columnsAnoigma}
                    filter={filterFactory()}
                    pagination={paginationFactory()}
                  />
                </div>
              )}
              {pageState.hasTamplades && (
                <div>
                  <h5 className="text-primary">{buttonName[3]}</h5>
                  <BootstrapTable
                    bootstrap4
                    striped
                    hover
                    condensed
                    keyField="translation_id"
                    data={pageState.typologyTamplades}
                    columns={columnsTamplades}
                    filter={filterFactory()}
                    pagination={paginationFactory()}
                  />
                </div>
              )}
              {pageState.hasKaitia && (
                <div>
                  <h5 className="text-primary">{buttonName[4]}</h5>
                  <BootstrapTable
                    bootstrap4
                    striped
                    hover
                    condensed
                    keyField="translation_id"
                    data={pageState.typologyKaitia}
                    columns={columnsKaitia}
                    filter={filterFactory()}
                    pagination={paginationFactory()}
                  />
                </div>
              )}
              {pageState.hasSxedio && (
                <div>
                  <h5 className="text-primary">{buttonName[5]}</h5>
                  <BootstrapTable
                    bootstrap4
                    striped
                    hover
                    condensed
                    keyField="translation_id"
                    data={pageState.typologySxedio}
                    columns={columnsSxedio}
                    filter={filterFactory()}
                    pagination={paginationFactory()}
                  />
                </div>
              )}
              {pageState.hasKouti && (
                <div>
                  <h5 className="text-primary">{buttonName[6]}</h5>
                  <BootstrapTable
                    bootstrap4
                    striped
                    hover
                    condensed
                    keyField="translation_id"
                    data={pageState.typologyKouti}
                    columns={columnsKouti}
                    filter={filterFactory()}
                    pagination={paginationFactory()}
                  />
                </div>
              )}
            </section>
            <section>
              <div>
                {/* <input type="file" onChange={handleFileChange} /> */}
              </div>
              {imgPass !== null && (
                <img
                  src={imgPass}
                  style={{
                    width: "100px",
                    height: "100px",
                  }}
                />
              )}
              <button
                // type="file"
                onClick={handleClick}
                className="btn btn-sm btn-info ms-2 mb-3 me-5"
              >
                Επιλογή φωτογραφίας
              </button>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <button
                onClick={() => {
                  upload_img("", false, file);
                  // saveProductSchema("", false);
                }}
                className="btn btn-sm btn-success mb-3 me-1"
              >
                Αποθήκευση
              </button>
              <button
                // onClick={deleteProductSchema}
                onClick={() => {
                  upload_img("", true, file);
                  // saveProductSchema("", true);
                }}
                className="btn btn-sm btn-warning ms-5 mb-3"
                disabled={favPass}
              >
                {favPass ? "Αγαπημένο προϊόν" : "Δηλώστε αν είναι αγαπημένο"}
              </button>
              {/* <button
                // onClick={deleteProductSchema}
                onClick={() => {
                  upload_img(file);
                }}
                className="btn btn-sm btn-secondary ms-5 mb-3"
              >
                dcds
              </button> */}
              {/* <img
                // src="https://s3-eu-central-1.amazonaws.com/sylorimgbucket/cc728b96-7e07-4969-9646-231347b7dc86"
                src="https://sylorimgbucket.s3.eu-central-1.amazonaws.com/Screenshot+2023-02-07+at+11.15.57+AM.png"
              > */}
              {/* </img> */}
            </section>
          </div>
        )}
      </section>
      {/* Επιλογή αγαπημένου προϊόντος */}
      {isAddMode && (
        <section>
          <div className="table-responsive">
            <h3>Επιλογή αγαπημένου προϊόντος</h3>
            {!pageState.goingForFav && (
              <button
                onClick={() => {
                  // setPageState({
                  //   ...pageState,
                  //   goingForFav: true
                  // });
                  getAllFavorites();
                }}
                className="btn btn-sm btn-warning mb"
              >
                Επιλογή από αγαπημένα
              </button>
            )}
            {pageState.goingForFav && (
              <FavoriteProductsList
                neededProducts={pageState.neededProducts}
                offer_key={offer_key}
                isOffer={true}
                saveFav={saveProductSchema}
              />
            )}
          </div>
        </section>
      )}
      <div className="row mt-3 justify-content-end">
        <div className="col-3">
          <button
            type="submit"
            onClick={returnBack}
            className="btn btn-sm btn-outline-danger ms-3 mt-1 ms-1 me-1"
          >
            Επιστροφή
          </button>
        </div>
      </div>
    </section>
  );
}

export default AddEditOfferProducts;
