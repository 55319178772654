import React, { useEffect, useState } from "react";
import TableRowsCost from "./TableRowsCost";

const AddDeleteTableRowsCost = (props) => {
  const [rowsDataCost, setRowsDataCost] = useState([]);

  // console.log(props.costsDescPass);
  // console.log(props.costsAmountPass);

  let costsObject = [];
  if (
    props.costsDescPass &&
    props.costsDescPass.length > 0 &&
    props.costsAmountPass &&
    props.costsAmountPass.length > 0
  ) {
    costsObject = props.costsDescPass.map((desc, index) => {
      return {
        desc: desc,
        value: props.costsAmountPass[index],
      };
    });
  }

  useEffect(() => {
    if (!props.isAddMode && costsObject.length > 0) {
      if (rowsDataCost.length > 0) {
        setRowsDataCost([...rowsDataCost, costsObject]);
      } else {
        setRowsDataCost(costsObject);
      }
    }
  }, [costsObject.length]);

  const addTableRows = () => {
    const rowsInputCost = {
      desc: "",
      value: 0,
    };
    // console.log(rowsInputCost);
    setRowsDataCost([...rowsDataCost, rowsInputCost]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsDataCost];
    rows.splice(index, 1);
    console.log(rows);
    setRowsDataCost(rows);
    props.getAllCosts(rows);
  };

  const handleChange = (index, e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const rowsInputCost = [...rowsDataCost];
    rowsInputCost[index][name] = value;
    // console.log(rowsInputCost);
    setRowsDataCost(rowsInputCost);
    props.getAllCosts(rowsInputCost);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <table className="table">
            <thead>
              <tr>
                <th>Περιγραφή</th>
                <th>Ποσό</th>
                <th>
                  <button
                    className="btn btn-outline-success"
                    onClick={addTableRows}
                  >
                    +
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <TableRowsCost
                rowsDataCost={rowsDataCost}
                deleteTableRows={deleteTableRows}
                handleChange={handleChange}
              />
            </tbody>
          </table>
        </div>
        <div className="col-sn-12"></div>
      </div>
    </div>
  );
};

export default AddDeleteTableRowsCost;
