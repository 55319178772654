import { fetchWrapper } from "../_helpers/fetch-wrapper";
import config from "../conf/Config.json";

let apiUrl;
if (process.env.REACT_APP_ENV === 'production') {
  apiUrl = `https://${config.production.api_id}.execute-api.${config.production.region}.amazonaws.com`;
} else if (process.env.REACT_APP_ENV === 'nikos-aws') {
  apiUrl = `https://${config["nikos-aws"].api_id}.execute-api.${config["nikos-aws"].region}.amazonaws.com`;
} else {
  apiUrl = '';
}
const baseUrl = `${apiUrl}/products`;

const getAll = () => {
    return fetchWrapper.get(baseUrl);
};

const getFav = () => {
    return fetchWrapper.get(`${baseUrl}?fav=True`);
};

const getById = (product_key) => {
    return fetchWrapper.get(`${baseUrl}/id?product_key=${product_key}`);
};

const create = (replicas, params) => {
    console.log(`${baseUrl}/registration?replicas=${replicas}`)
    return fetchWrapper.post(`${baseUrl}/registration?replicas=${replicas}`, params);
};

const schema = (params) => {
    console.log(`/product_schema/id?schemas_key=${params}`)
    return fetchWrapper.get(`/product_schema/id?schemas_key=${params}`);
};

const update = (product_key, params) => {
    console.log(`${baseUrl}/edit?product_key=${product_key}`)
    return fetchWrapper.put(`${baseUrl}/edit?product_key=${product_key}`, params);
};

const update_img = (product_key, params) => {
    console.log(`${baseUrl}/edit/img?product_key=${product_key}`)
    return fetchWrapper.put_img(`${baseUrl}/edit/img?product_key=${product_key}`, params);
};

const _delete = (product_key) => {
    return fetchWrapper.delete(`${baseUrl}/delete?product_key=${product_key}`);
};

export const productsService = {
    getAll,
    getFav,
    getById,
    create,
    schema,
    update,
    update_img,
    delete: _delete,
};
