import React, { useState, useEffect } from "react";
import { customersService } from "../../_services/customersService";
import { Link } from "react-router-dom";

const CustomersList = () => {
  const [customers, setCustomers] = useState(null);
  const [hasResult, setHasResult] = useState(false);

  // const fetchData = () => {
  useEffect(() => {
    customersService.getAll().then((data) => {
      console.log("Get all customers:" + JSON.stringify(data));
      if (data.message === undefined || data.message === null) {
        // Successful response
        setCustomers(data);
        setHasResult(true);
      } else setHasResult(false);
    });
  }, []);
  // };

  const deleteCustomer = (client_key) => {
    setCustomers(
      customers.map((x) => {
        if (x.client_key === client_key) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    customersService.delete(client_key).then(() => {
      setCustomers((customers) =>
        customers.filter((x) => x.client_key !== client_key)
      );
    });
  };

  return (
    <section>
      <h1>Λίστα Πελατών</h1>
      {/* <button onClick={fetchData} className="btn btn-sm btn-primary mb-3">
        Εμφάνιση Δεδομένων
      </button> */}
      {hasResult && (
        <div className="table-responsive">
          <table className="table table-striped table-hover table-bordered table-sm">
            <thead className="thead-light">
              <tr>
                <th style={{ width: "10%" }}>Όνομα</th>
                <th style={{ width: "10%" }}>Επώνυμο</th>
                <th style={{ width: "10%" }}>Κινητό</th>
                <th style={{ width: "10%" }}>email</th>
                <th style={{ width: "10%" }}>Αρχιτέκτονας</th>
                <th style={{ width: "10%" }}>Αφμ</th>
                <th style={{ width: "10%" }}>Διεύθυνση</th>
                <th style={{ width: "10%" }}>Πόλη</th>
                <th style={{ width: "10%" }}>Χώρα</th>
                <th style={{ width: "10%" }}>
                  <Link to="add" className="btn btn-sm btn-success mb">
                    Δημιουργία
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody className="align-middle">
              {customers &&
                customers.map((customer) => (
                  <tr key={customer.client_key}>
                    <td>{customer.firstName}</td>
                    <td>{customer.lastName}</td>
                    <td>{customer.mobile}</td>
                    <td>{customer.email}</td>
                    <td>{customer.architect}</td>
                    <td>{customer.afm}</td>
                    <td>{customer.city}</td>
                    <td>{customer.address}</td>
                    <td>{customer.country}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <Link
                        to={`edit/${customer.client_key}`}
                        className="btn btn-sm btn-primary me-1"
                      >
                        Επεξεργασία
                      </Link>
                      <button
                        onClick={() => deleteCustomer(customer.client_key)}
                        className="btn btn-sm btn-danger btn-delete-customer"
                        disabled={customer.isDeleting}
                      >
                        {customer.isDeleting ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          <span>Διαγραφή</span>
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
              {!customers && (
                <tr>
                  <td colSpan="4" className="text-center">
                    <div className="spinner-border spinner-border-lg align-center"></div>
                  </td>
                </tr>
              )}
              {customers && !customers.length && (
                <tr>
                  <td colSpan="4" className="text-center">
                    <div className="p-2">No Customers To Display</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </section>
  );
};

export default CustomersList;
