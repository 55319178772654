import { fetchWrapper } from "../_helpers/fetch-wrapper";
import config from "../conf/Config.json";

let apiUrl;
if (process.env.REACT_APP_ENV === 'production') {
  apiUrl = `https://${config.production.api_id}.execute-api.${config.production.region}.amazonaws.com`;
} else if (process.env.REACT_APP_ENV === 'nikos-aws') {
  apiUrl = `https://${config["nikos-aws"].api_id}.execute-api.${config["nikos-aws"].region}.amazonaws.com`;
} else {
  apiUrl = '';
}
const baseUrl = `${apiUrl}/extra_costings`;

const getAll = () => {
    return fetchWrapper.get(baseUrl);
};

// const getById = (id) => {
//     return fetchWrapper.get(`${baseUrl}/id?translation_id=${id}`);
// };

const getByType = (type) => {
    return fetchWrapper.get(`${baseUrl}/type?type=${type}`);
};

const create = (params) => {
    return fetchWrapper.post(`${baseUrl}/registration`, params);
};

const update = (params) => {
    return fetchWrapper.post(`${baseUrl}/registration`, params);
};

// const update = (id, params) => {
//     return fetchWrapper.put(`${baseUrl}/edit?translation_id=${id}`, params);
// };

// const _delete = (id) => {
//     return fetchWrapper.delete(`${baseUrl}/delete?translation_id=${id}`);
// };

export const extraCostingService = {
    getAll,
    // getById,
    getByType,
    // create,
    update,
    // delete: _delete,
};