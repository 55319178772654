import { Routes, Route } from "react-router-dom";

import Layout from "./components/layout/Layout";
import UsersPage from "./pages/UsersPage";
import LoginPage from "./pages/LoginPage";
import CustomersPage from "./pages/CustomersPage";
import OffersPage from "./pages/OffersPage";
import FavoriteProducts from "./pages/FavoriteProducts";
import HomePage from "./pages/HomePage";
import AddEditUsers from "./components/users/AddEditUsers";
import AddEditCustomers from "./components/customers/AddEditCustomers";
import TypologyPage from "./pages/TypologyPage";
import AddEditTypology from "./components/initialization/AddEditTypology";
import ExtraYaloPage from "./pages/ExtraYaloPage";
import ExtraPatzouList from "./components/initialization/ExtraPatzouList";
import AddEditExtraYalo from "./components/initialization/AddEditExtraYalo";
import AddEditExtraPatzou from "./components/initialization/AddEditExtraPatzou";
import AddEditOffers from "./components/offers/AddEditOffers";
import AddEditOfferProducts from "./components/offers/AddEditOfferProducts";
import AddEditOfferProductsSpecifics from "./components/offers/AddEditOfferProductsSpecifics";
import OfferInspection from "./components/offers/OfferInspection";
import AddEditOfferProductsDimensions from "./components/offers/AddEditOfferProductsDimensions";
import Costing from "./components/initialization/Costing";
import OfferCosting from "./components/offers/OfferCosting";

const App = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="homepage" element={<HomePage />} />
        <Route path="customers/*" element={<CustomersPage />} />
        <Route path="customers/add" element={<AddEditCustomers />} />
        <Route path="customers/edit/:client_key" element={<AddEditCustomers />} />
        <Route path="offers/*" element={<OffersPage />} /> 
        <Route path="offers/add" element={<AddEditOffers />} />
        <Route path="offers/edit/:offer_key" element={<AddEditOffers />} />
        <Route path="offers/edit/:offer_key/inspect" element={<OfferInspection />} />
        <Route path="offers/edit/:offer_key/offercosting" element={<OfferCosting />} />
        <Route path="offers/edit/:offer_key/add" element={<AddEditOfferProducts />} />
        <Route path="offers/edit/:offer_key/edit/:product_key" element={<AddEditOfferProducts />} /> 
        <Route path="offers/edit/:offer_key/edit/specifics/:offer_product_key" element={<AddEditOfferProductsSpecifics />} /> 
        <Route path="offers/edit/:offer_key/edit/dimensions/:offer_product_key" element={<AddEditOfferProductsDimensions />} /> 
        <Route path="favorites/*" element={<FavoriteProducts />} />
        <Route path="users/*" element={<UsersPage />} />
        <Route path="users/add" element={<AddEditUsers />} />
        <Route path="users/edit/:username" element={<AddEditUsers />} />
        <Route path="typology/*" element={<TypologyPage />} />
        <Route path="typology/add/:typologyName" element={<AddEditTypology />} />
        <Route path="typology/edit/:typologyName/:translationID" element={<AddEditTypology />} />
        <Route path="extrayalo/*" element={<ExtraYaloPage />} />
        <Route path="extrayalo/add/:extraYaloName" element={<AddEditExtraYalo />} />
        <Route path="extrayalo/edit/:extraYaloName/:translationID" element={<AddEditExtraYalo />} />
        <Route path="extrapatzou/*" element={<ExtraPatzouList />} />
        <Route path="extrapatzou/add/:extraPatzouName" element={<AddEditExtraPatzou />} />
        <Route path="extrapatzou/edit/:extraPatzouName/:translationID" element={<AddEditExtraPatzou />} />
        <Route path="costing/*" element={<Costing />} />
      </Routes>
    </Layout>
  );
};

export default App;
