import React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { userService } from "../../_services/userService";

const NavBar = () => {
  const isLoggedin = !!localStorage.getItem("token");
  const navigate = useNavigate();

  const logoutHandler = () => {
    userService.logout();
    navigate("/login");
  };
  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-primary">
      <div className="container-fluid">
        <a className="navbar-brand ms-5" href="/">
          SYLOR.gr
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ms-auto me-auto">
            {isLoggedin && (
              <li className="nav-item">
                <NavLink
                  to="/offers"
                  className="nav-link pe-3"
                  aria-current="page"
                >
                  Προσφορές
                </NavLink>
              </li>
            )}
            {isLoggedin && (
              <li className="nav-item">
                <NavLink
                  to="/favorites"
                  className="nav-link pe-3"
                  aria-current="page"
                >
                  Προϊόντα
                </NavLink>
              </li>
            )}
            {isLoggedin && (
              <li className="nav-item">
                <NavLink
                  to="/customers"
                  className="nav-link pe-3"
                  aria-current="page"
                >
                  Πελάτες
                </NavLink>
              </li>
            )}
            {isLoggedin && (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Αρχικοποίηση
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <NavLink to="/typology" className="dropdown-item">
                      Τυπολογίες Προϊόντων
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/extrayalo" className="dropdown-item ">
                      Χαρακτηριστικά Υαλοστασίων
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/extrapatzou" className="dropdown-item ">
                      Χαρακτηριστικά Πατζουριών
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/costing" className="dropdown-item ">
                      Κοστολόγηση
                    </NavLink>
                  </li>
                </ul>
              </li>
            )}
            {isLoggedin && (
              <li className="nav-item">
                <NavLink
                  to="/users"
                  className="nav-link pe-3"
                  aria-current="page"
                >
                  Χρήστες
                </NavLink>
              </li>
            )}
          </ul>
          {isLoggedin && (
            <button
              type="button"
              className="btn btn-sm btn-danger me-5"
              onClick={logoutHandler}
            >
              Αποσύνδεση
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
