import { fetchWrapper } from "../_helpers/fetch-wrapper";
import config from "../conf/Config.json";

let apiUrl;
if (process.env.REACT_APP_ENV === 'production') {
  apiUrl = `https://${config.production.api_id}.execute-api.${config.production.region}.amazonaws.com`;
} else if (process.env.REACT_APP_ENV === 'nikos-aws') {
  apiUrl = `https://${config["nikos-aws"].api_id}.execute-api.${config["nikos-aws"].region}.amazonaws.com`;
} else {
  apiUrl = '';
}
const baseUrl = `${apiUrl}/costing`;

const getAll = () => {
  return fetchWrapper.get(baseUrl);
};

const update = (params) => {
  console.log(params);
  return fetchWrapper.post(`${baseUrl}/registration`, params);
};

const reset = () => {
  return fetchWrapper.post(`${baseUrl}/registration`);
};

export const costingService = {
  getAll,
  update,
  reset,
};
