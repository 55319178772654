import { fetchWrapper } from "../_helpers/fetch-wrapper";
import config from "../conf/Config.json";

let apiUrl;
if (process.env.REACT_APP_ENV === 'production') {
  apiUrl = `https://${config.production.api_id}.execute-api.${config.production.region}.amazonaws.com`;
} else if (process.env.REACT_APP_ENV === 'nikos-aws') {
  apiUrl = `https://${config["nikos-aws"].api_id}.execute-api.${config["nikos-aws"].region}.amazonaws.com`;
} else {
  apiUrl = '';
}
const baseUrl = `${apiUrl}/offer_costing`;

const updateInitial = (params) => {
  console.log(params);
  return fetchWrapper.post(`${baseUrl}/registration`, params);
};

const update = (offer_costing_id, params) => {
  // console.log(`${baseUrl}/edit?offer_costing_id=${offer_costing_id}`);
  return fetchWrapper.put(
    `${baseUrl}/edit?offer_costing_id=${offer_costing_id}`,
    params
  );
};

const getById = (offer_id) => {
  return fetchWrapper.get(`${baseUrl}/offer_id?offer_id=${offer_id}`);
};

export const offerCostingService = {
  getById,
  updateInitial,
  update,
};
