import React from "react";
import { Navigate } from "react-router-dom";
import TypologyList from "../components/initialization/TypologyList";

const TypologyPage = () => {
  const isLoggedin = !!localStorage.getItem("token");
  
  return (
    <section>
      {isLoggedin && <TypologyList />}
      {!isLoggedin && <Navigate to="/login" />}
    </section>
  );
};

export default TypologyPage;
