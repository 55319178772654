import React, { useEffect, useState } from "react";
import { translationsService } from "../../_services/translationsService";
import { extraCostingService } from "../../_services/extraCostingService";
import TableRowsExtraCost from "./TableRowsExtraCost";
import config from "../../conf/Config.json";

const AddDeleteTableRowsExtraCost = () => {
  const [rowsData, setRowsData] = useState([]);
  const [optionsProduct, setOptionsProduct] = useState([]);
  const [optionsTypology, setOptionsTypology] = useState([]);
  const [optionsTypology_1, setOptionsTypology_1] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState("");
  const [rowsUpdated, setRowUpdated] = useState([]);
  const [rowsDelete, setRowsDelete] = useState([]);

  const fetchTranslationData = () => {
    setIsLoading(true);
    translationsService.getAll().then((data) => {
      console.log(data);
      if (data.message === undefined || data.message === null) {
        // Successful response
        let _optionsProducts = [];
        let _optionsTypology = [];
        let _optionsTypology_1 = [];
        data.map((translation) => {
          if (translation.type === config.translationTypes.productName) {
            _optionsProducts = [
              ..._optionsProducts,
              {
                value: translation.translation_id,
                label: translation.el,
              },
            ];
          } else if (translation.type === config.translationTypes.typology) {
            _optionsTypology = [
              ..._optionsTypology,
              {
                value: translation.translation_id,
                label: translation.el,
              },
            ];
          } else if (
            translation.type === config.translationTypes.typologyFilla
          ) {
            _optionsTypology_1 = [
              ..._optionsTypology_1,
              {
                value: translation.translation_id,
                label: translation.el,
              },
            ];
          }
        });
        setOptionsProduct(_optionsProducts);
        setOptionsTypology(_optionsTypology);
        setOptionsTypology_1(_optionsTypology_1);
        setIsLoading(false);
        setIsError("");
        console.log(_optionsTypology_1);
      } else {
        setIsLoading(false);
        setIsError(data.message);
      }
    });
  };

  const fetchExtraCostingData = () => {
    setIsLoading(true);
    extraCostingService.getAll().then((data) => {
      console.log(data);
      if (data.message === undefined || data.message === null) {
        // Successful response
        // data.map((item) => {});
        setRowsData(data);
        setIsLoading(false);
        setIsError("");
      } else {
        setIsLoading(false);
        setIsError(data.message);
      }
    });
  };

  useEffect(() => {
    fetchTranslationData();
    fetchExtraCostingData();
  }, []);

  const onSubmitTableRows = () => {
    setIsLoading(true);
    console.log(rowsData);

    console.log(rowsUpdated);
    let uniqueRowsUpdated = Array.from(
      new Set(rowsUpdated.map(JSON.stringify))
    ).map(JSON.parse);
    console.log(uniqueRowsUpdated);

    let rowsCreated = uniqueRowsUpdated.filter((x) => {
      return x.extra_costing_id === undefined;
    });
    console.log(rowsCreated);

    let newRowsUpdated = uniqueRowsUpdated.filter(
      (x) => x.extra_costing_id !== undefined
    );
    console.log(newRowsUpdated);

    console.log(rowsDelete);

    let submitData = {
      create: rowsCreated,
      update: newRowsUpdated,
      delete: rowsDelete,
    };
    console.log(submitData);

    extraCostingService.update(submitData).then((data) => {
      console.log(data);
      if (data.message === undefined || data.message === null) {
        // Successful response
        setIsLoading(false);
        setIsError("");
      } else {
        setIsLoading(false);
        setIsError(data.message);
      }
    });
  };

  const addTableRows = () => {
    const rowsInput = {
      productId: 0,
      typologyId: 0,
      typology_1: 0,
      m2_limit: "",
      extra_cost: "",
    };
    console.log(rowsInput);
    setRowsData([...rowsData, rowsInput]);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    console.log(rows[index]);
    setRowsDelete([...rowsDelete, rows[index]]);
    rows.splice(index, 1);
    // console.log(rows);
    setRowsData(rows);
  };

  const handleChangeProduct = (index) => (selected) => {
    // console.log(index, selected)
    const rowsInput = [...rowsData];
    rowsInput[index]["productId"] = selected.value;
    console.log(rowsInput);
    setRowsData(rowsInput);
    setRowUpdated([...rowsUpdated, rowsInput[index]]);
  };

  const handleChangeTypology = (index) => (selected) => {
    const { label, value } = selected;
    const rowsInput = [...rowsData];
    rowsInput[index]["typologyId"] = value;
    console.log(rowsInput);
    setRowsData(rowsInput);
    setRowUpdated([...rowsUpdated, rowsInput[index]]);
  };

  const handleChangeTypology_1 = (index) => (selected) => {
    const { label, value } = selected;
    const rowsInput = [...rowsData];
    rowsInput[index]["typology_1"] = value;
    console.log(rowsInput);
    setRowsData(rowsInput);
    setRowUpdated([...rowsUpdated, rowsInput[index]]);
  };

  const handleChange = (index, e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    console.log(rowsInput[index]);
    setRowsData(rowsInput);
    setRowUpdated([...rowsUpdated, rowsInput[index]]);
  };

  return (
    <div className="container">
      {isLoading && "Loading.."}
      {isError && isError}
      <div className="row">
        <div className="table-responsive col-sm-12">
          <table className="table table-sm">
            <thead>
              <tr>
                <th style={{ width: "25%" }}>Προϊόν</th>
                <th style={{ width: "40%" }}>Τυπολογία</th>
                <th style={{ width: "20%" }}>Φύλλα</th>
                <th style={{ width: "10%" }}>Τετραγωνικά</th>
                <th style={{ width: "5%" }}>Ώρες</th>
                <th style={{ whiteSpace: "nowrap" }}>
                  <button
                    className="btn btn-outline-success"
                    onClick={addTableRows}
                  >
                    +
                  </button>
                  <button
                    className="ms-2 btn btn-success"
                    onClick={onSubmitTableRows}
                  >
                    √
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <TableRowsExtraCost
                rowsData={rowsData}
                deleteTableRows={deleteTableRows}
                handleChange={handleChange}
                handleOptionsProduct={optionsProduct}
                handleOptionsTypology={optionsTypology}
                handleOptionsTypology_1={optionsTypology_1}
                handleChangeProduct={handleChangeProduct}
                handleChangeTypology={handleChangeTypology}
                handleChangeTypology_1={handleChangeTypology_1}
              />
            </tbody>
          </table>
        </div>
        <div className="col-sm-12"></div>
      </div>
    </div>
  );
};

export default AddDeleteTableRowsExtraCost;
