import React, { useState, useEffect } from "react";
import config from "../../conf/Config";
import Select from "react-select";
// import { Input } from "reactstrap";
import { offerProductsService } from "../../_services/offerProductsService";
// import { productsService } from "../../_services/productsService";
import { translationsService } from "../../_services/translationsService";
import { useLocation, useParams, useNavigate } from "react-router-dom";
// import FavoriteProductsList from "../favoriteProducts/FavoriteProductsList";

function AddEditOfferProductsSpecifics() {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    offer_key,
    offerPass,
    productNamePass,
    productTypologyPass,
    productFyllaPass,
    productOpenPass,
    product_key,
  } = location.state;
  const { offer_product_key } = useParams();
  // const isAddMode = !offer_product_key;
  console.log(offer_product_key);
  const isAddMode = false;

  const [pageState, setPageState] = useState({
    extrasYalo: false,
    extrasPatzoy: false,
    isLoading: false,
    error: "",
  });

  const [pageStateYalo, setPageStateYalo] = useState({
    crystal: [],
    hasCrystal: true,
    sita: [],
    hasSita: false,
    rola: [],
    hasRola: false,
    mechs: [],
    hasMechs: false,
    skia: [],
    hasSkia: false,
    iron: [],
    hasIron: false,
    alarm: [],
    hasAlarm: false,
    kaitia: [],
    hasKaitia: false,
    tamplades: [],
    hasTamplades: false,
    color: [],
    hasColor: false,
    wood: [],
    hasWood: false,
    thermal: [],
    hasThermal: false,
    selectedProductHasSchema: false,
    selectedProduct_crystal: "",
    selectedProduct_crystal_amount: 0,
    selectedProduct_hasCrystal: false,
    selectedProduct_sita: "",
    selectedProduct_sita_amount: 0,
    selectedProduct_hasSita: false,
    selectedProduct_rola: "",
    selectedProduct_rola_amount: 0,
    selectedProduct_hasRola: false,
    selectedProduct_mechs: "",
    selectedProduct_mechs_amount: 0,
    selectedProduct_hasMechs: false,
    selectedProduct_skia: "",
    selectedProduct_skia_amount: 0,
    selectedProduct_hasSkia: false,
    selectedProduct_iron: "",
    selectedProduct_iron_amount: 0,
    selectedProduct_hasIron: false,
    selectedProduct_alarm: "",
    selectedProduct_alarm_amount: 0,
    selectedProduct_hasAlarm: false,
    selectedProduct_kaitia: "",
    selectedProduct_kaitia_amount: 0,
    selectedProduct_hasKaitia_amount: false,
    selectedProduct_tamplades: "",
    selectedProduct_tamplades_amount: 0,
    selectedProduct_hasTamplades: false,
    selectedProduct_color: "",
    selectedProduct_color_amount: 0,
    selectedProduct_hasColor: false,
    selectedProduct_wood: "",
    selectedProduct_wood_amount: 0,
    selectedProduct_hasWood: false,
    selectedProduct_thermal: "",
    selectedProduct_thermal_amount: 0,
    selectedProduct_hasThermal: false,
  });

  const [pageStatePatzoy, setPageStatePatzoy] = useState({
    metalMech: [],
    hasMetalMech: true,
    metalColor: [],
    hasMetalColor: false,
    sitaPatzoy: [],
    hasSitaPatzoy: false,
    mech: [],
    hasMech: false,
    kaitiaPatzoy: [],
    hasKaitiaPatzoy: false,
    tampladesPatzoy: [],
    hasTampladesPatzoy: false,
    colorAdded: [],
    hasColorAdded: false,
    woodAdded: [],
    hasWoodAdded: false,
    selectedProduct_metalMech: "",
    selectedProduct_metalMech_amount: 0,
    selectedProduct_hasMetalMech: false,
    selectedProduct_metalColor: "",
    selectedProduct_metalColor_amount: 0,
    selectedProduct_hasMetalColor: false,
    selectedProduct_sitaPatzoy: "",
    selectedProduct_sitaPatzoy_amount: 0,
    selectedProduct_hasSitaPatzoy: false,
    selectedProduct_mech: "",
    selectedProduct_mech_amount: 0,
    selectedProduct_hasMech: false,
    selectedProduct_kaitiaPatzoy: "",
    selectedProduct_kaitiaPatzoy_amount: 0,
    selectedProduct_hasKaitiaPatzoy: false,
    selectedProduct_tampladesPatzoy: "",
    selectedProduct_tampladesPatzoy_amount: 0,
    selectedProduct_hasTampladesPatzoy: false,
    selectedProduct_colorAdded: "",
    selectedProduct_colorAdded_amount: 0,
    selectedProduct_hasColorAdded: false,
    selectedProduct_woodAdded: "",
    selectedProduct_woodAdded_amount: 0,
    selectedProduct_hasWoodAdded: false,
  });

  useEffect(() => {
    getAllExtras();
  }, []);

  useEffect(() => {
    if (!isAddMode && pageStateYalo.crystal.length > 0) {
      // && pageStateYalo.crystal.length > 0) {
      console.log("second effect run");
      getSelectionsExtras();
    }
  }, [pageStateYalo.crystal]); //[pageStateYalo.crystal]);

  const getAllExtras = () => {
    setPageState({
      ...pageState,
      isLoading: true,
    });
    translationsService.getAll().then((data) => {
      console.log("Get all Translations:" + JSON.stringify(data));
      if (data.message === undefined || data.message === null) {
        // extras_yalo
        let _crystal = [];
        let _sita = [];
        let _rola = [];
        let _mechs = [];
        let _skia = [];
        let _iron = [];
        let _alarm = [];
        let _kaitia = [];
        let _tamplades = [];
        let _color = [];
        let _wood = [];
        let _thermal = [];
        //extra_patzoy
        let _metalMech = [];
        let _metalColor = [];
        let _sitaPatzoy = [];
        let _mech = [];
        let _kaitiaPatzoy = [];
        let _tampladesPatzoy = [];
        let _colorAdded = [];
        let _woodAdded = [];

        data.map((translation, pos) => {
          // console.log("translation.type is :" + JSON.stringify(translation.type));
          if (translation.type === config.product_extras_yalo.crystal) {
            _crystal = [..._crystal, translation];
          } else if (translation.type === config.product_extras_yalo.sita) {
            _sita = [..._sita, translation];
          } else if (translation.type === config.product_extras_yalo.rola) {
            _rola = [..._rola, translation];
          } else if (translation.type === config.product_extras_yalo.mechs) {
            _mechs = [..._mechs, translation];
          } else if (translation.type === config.product_extras_yalo.skia) {
            _skia = [..._skia, translation];
          } else if (translation.type === config.product_extras_yalo.iron) {
            _iron = [..._iron, translation];
          } else if (translation.type === config.product_extras_yalo.alarm) {
            _alarm = [..._alarm, translation];
          } else if (translation.type === config.product_extras_yalo.kaitia) {
            _kaitia = [..._kaitia, translation];
          } else if (
            translation.type === config.product_extras_yalo.tamplades
          ) {
            _tamplades = [..._tamplades, translation];
          } else if (translation.type === config.product_extras_yalo.color) {
            _color = [..._color, translation];
          } else if (translation.type === config.product_extras_yalo.wood) {
            _wood = [..._wood, translation];
          } else if (translation.type === config.product_extras_yalo.thermal) {
            _thermal = [..._thermal, translation];
          } else if (
            translation.type === config.product_extras_patzoy.metalMech
          ) {
            _metalMech = [..._metalMech, translation];
          } else if (
            translation.type === config.product_extras_patzoy.metalColor
          ) {
            _metalColor = [..._metalColor, translation];
          } else if (translation.type === config.product_extras_patzoy.sita) {
            _sitaPatzoy = [..._sitaPatzoy, translation];
          } else if (translation.type === config.product_extras_patzoy.mech) {
            _mech = [..._mech, translation];
          } else if (translation.type === config.product_extras_patzoy.kaitia) {
            _kaitiaPatzoy = [..._kaitiaPatzoy, translation];
          } else if (
            translation.type === config.product_extras_patzoy.tamplades
          ) {
            _tampladesPatzoy = [..._tampladesPatzoy, translation];
          } else if (
            translation.type === config.product_extras_patzoy.colorAdded
          ) {
            _colorAdded = [..._colorAdded, translation];
          } else if (
            translation.type === config.product_extras_patzoy.woodAdded
          ) {
            _woodAdded = [..._woodAdded, translation];
          }
          return true;
        });
        setPageState({
          ...pageState,
          isLoading: false,
          error: "",
        });
        setPageStateYalo({
          ...pageStateYalo,
          crystal: _crystal,
          sita: _sita,
          rola: _rola,
          mechs: _mechs,
          skia: _skia,
          iron: _iron,
          alarm: _alarm,
          kaitia: _kaitia,
          tamplades: _tamplades,
          color: _color,
          wood: _wood,
          thermal: _thermal,
        });
        setPageStatePatzoy({
          ...pageStatePatzoy,
          metalMech: _metalMech,
          metalColor: _metalColor,
          sitaPatzoy: _sitaPatzoy,
          mech: _mech,
          kaitiaPatzoy: _kaitiaPatzoy,
          tampladesPatzoy: _tampladesPatzoy,
          colorAdded: _colorAdded,
          woodAdded: _woodAdded,
        });
      } else {
        setPageState({
          ...pageState,
          isLoading: false,
          error: data.message,
        });
      }
    });
  };

  const getSelectionsExtras = () => {
    console.log("Extras in select :" + JSON.stringify(pageStateYalo.crystal));
    setPageState({
      ...pageState,
      isLoading: true,
      error: "",
    });
    console.log("offer_product_key :" + JSON.stringify(offer_product_key));
    offerProductsService.getById(offer_product_key).then((data) => {
      console.log("data is :" + JSON.stringify(data));
      if (data.message === undefined || data.message === null) {
        console.log("in");
        setPageState({
          ...pageState,
          isLoading: false,
        });
        console.log(data.extra_patzoy_1_1.id);
        setPageStateYalo({
          ...pageStateYalo,
          selectedProduct_crystal: data.extra_yalo_1 && data.extra_yalo_1.id,
          selectedProduct_sita: data.extra_yalo_2 && data.extra_yalo_2.id,
          selectedProduct_rola: data.extra_yalo_3 && data.extra_yalo_3.id,
          selectedProduct_mechs: data.extra_yalo_4 && data.extra_yalo_4.id,
          selectedProduct_skia: data.extra_yalo_5 && data.extra_yalo_5.id,
          selectedProduct_iron: data.extra_yalo_6 && data.extra_yalo_6.id,
          selectedProduct_alarm: data.extra_yalo_7 && data.extra_yalo_7.id,
          selectedProduct_kaitia: data.extra_yalo_8 && data.extra_yalo_8.id,
          selectedProduct_tamplades: data.extra_yalo_9 && data.extra_yalo_9.id,
          selectedProduct_color: data.extra_yalo_10 && data.extra_yalo_10.id,
          selectedProduct_wood: data.extra_yalo_11 && data.extra_yalo_11.id,
          selectedProduct_thermal: data.extra_yalo_12 && data.extra_yalo_12.id,
          selectedProduct_crystal_amount: data.extra_yalo_1_amount,
          selectedProduct_sita_amount: data.extra_yalo_2_amount,
          selectedProduct_rola_amount: data.extra_yalo_3_amount,
          selectedProduct_mechs_amount: data.extra_yalo_4_amount,
          selectedProduct_skia_amount: data.extra_yalo_5_amount,
          selectedProduct_iron_amount: data.extra_yalo_6_amount,
          selectedProduct_alarm_amount: data.extra_yalo_7_amount,
          selectedProduct_kaitia_amount: data.extra_yalo_8_amount,
          selectedProduct_tamplades_amount: data.extra_yalo_9_amount,
          selectedProduct_color_amount: data.extra_yalo_10_amount,
          selectedProduct_wood_amount: data.extra_yalo_11_amount,
          selectedProduct_thermal_amount: data.extra_yalo_12_amount,
        });
        console.log("in");
        setPageStatePatzoy({
          ...pageStatePatzoy,
          selectedProduct_metalMech:
            data.extra_patzoy_1_1 && data.extra_patzoy_1_1.id,
          selectedProduct_metalColor:
            data.extra_patzoy_1_2 && data.extra_patzoy_1_2.id,
          selectedProduct_sitaPatzoy:
            data.extra_patzoy_2 && data.extra_patzoy_2.id,
          selectedProduct_mech: data.extra_patzoy_3 && data.extra_patzoy_3.id,
          selectedProduct_kaitiaPatzoy:
            data.extra_patzoy_4 && data.extra_patzoy_4.id,
          selectedProduct_tampladesPatzoy:
            data.extra_patzoy_5 && data.extra_patzoy_5.id,
          selectedProduct_colorAdded:
            data.extra_patzoy_6 && data.extra_patzoy_6.id,
          selectedProduct_woodAdded:
            data.extra_patzoy_7 && data.extra_patzoy_7.id,
          selectedProduct_metalMech_amount: data.extra_patzoy_1_1_amount,
          selectedProduct_metalColor_amount: data.extra_patzoy_1_2_amount,
          selectedProduct_sitaPatzoy_amount: data.extra_patzoy_2_amount,
          selectedProduct_mech_amount: data.extra_patzoy_3_amount,
          selectedProduct_kaitiaPatzoy_amount: data.extra_patzoy_4_amount,
          selectedProduct_tampladesPatzoy_amount: data.extra_patzoy_5_amount,
          selectedProduct_colorAdded_amount: data.extra_patzoy_6_amount,
          selectedProduct_woodAdded_amount: data.extra_patzoy_7_amount,
        });
        console.log("in");
      } else {
        console.log(data.message);
        if (data.message.startsWith("Product with offer_product_key:")) {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
          setPageStateYalo({
            ...pageStateYalo,
            selectedProduct_crystal: "",
            selectedProduct_sita: "",
            selectedProduct_rola: "",
            selectedProduct_mechs: "",
            selectedProduct_skia: "",
            selectedProduct_iron: "",
            selectedProduct_alarm: "",
            selectedProduct_kaitia: "",
            selectedProduct_tamplades: "",
            selectedProduct_color: "",
            selectedProduct_wood: "",
            selectedProduct_thermal: "",
          });
          setPageStatePatzoy({
            ...pageStatePatzoy,
            selectedProduct_metalMech: "",
            selectedProduct_metalColor: "",
            selectedProduct_sitaPatzoy: "",
            selectedProduct_mech: "",
            selectedProduct_kaitiaPatzoy: "",
            selectedProduct_tampladesPatzoy: "",
            selectedProduct_colorAdded: "",
            selectedProduct_woodAdded: "",
          });
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      }
    });
  };

  //------------------------------- show product_extras_yalo ----------------------//
  const showCrystal = () => {
    // console.log(pageStateYalo.selectedProduct_typologies);
    console.log(pageStateYalo.crystal);
    setPageStateYalo({
      ...pageStateYalo,

      hasCrystal: true,
      hasSita: false,
      hasRola: false,
      hasMechs: false,
      hasSkia: false,
      hasIron: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTamplades: false,
      hasColor: false,
      hasWood: false,
      hasThermal: false,
    });
  };

  const showSita = () => {
    setPageStateYalo({
      ...pageStateYalo,

      hasCrystal: false,
      hasSita: true,
      hasRola: false,
      hasMechs: false,
      hasSkia: false,
      hasIron: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTamplades: false,
      hasColor: false,
      hasWood: false,
      hasThermal: false,
    });
  };

  const showRola = () => {
    setPageStateYalo({
      ...pageStateYalo,

      hasCrystal: false,
      hasSita: false,
      hasRola: true,
      hasMechs: false,
      hasSkia: false,
      hasIron: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTamplades: false,
      hasColor: false,
      hasWood: false,
      hasThermal: false,
    });
  };

  const showMechs = () => {
    setPageStateYalo({
      ...pageStateYalo,

      hasCrystal: false,
      hasSita: false,
      hasRola: false,
      hasMechs: true,
      hasSkia: false,
      hasIron: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTamplades: false,
      hasColor: false,
      hasWood: false,
      hasThermal: false,
    });
  };

  const showSkia = () => {
    setPageStateYalo({
      ...pageStateYalo,

      hasCrystal: false,
      hasSita: false,
      hasRola: false,
      hasMechs: false,
      hasSkia: true,
      hasIron: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTamplades: false,
      hasColor: false,
      hasWood: false,
      hasThermal: false,
    });
  };

  const showIron = () => {
    setPageStateYalo({
      ...pageStateYalo,

      hasCrystal: false,
      hasSita: false,
      hasRola: false,
      hasMechs: false,
      hasSkia: false,
      hasIron: true,
      hasAlarm: false,
      hasKaitia: false,
      hasTamplades: false,
      hasColor: false,
      hasWood: false,
      hasThermal: false,
    });
  };

  const showAlarm = () => {
    setPageStateYalo({
      ...pageStateYalo,

      hasCrystal: false,
      hasSita: false,
      hasRola: false,
      hasMechs: false,
      hasSkia: false,
      hasIron: false,
      hasAlarm: true,
      hasKaitia: false,
      hasTamplades: false,
      hasColor: false,
      hasWood: false,
      hasThermal: false,
    });
  };

  const showKaitia = () => {
    setPageStateYalo({
      ...pageStateYalo,

      hasCrystal: false,
      hasSita: false,
      hasRola: false,
      hasMechs: false,
      hasSkia: false,
      hasIron: false,
      hasAlarm: false,
      hasKaitia: true,
      hasTamplades: false,
      hasColor: false,
      hasWood: false,
      hasThermal: false,
    });
  };

  const showTamplades = () => {
    setPageStateYalo({
      ...pageStateYalo,

      hasCrystal: false,
      hasSita: false,
      hasRola: false,
      hasMechs: false,
      hasSkia: false,
      hasIron: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTamplades: true,
      hasColor: false,
      hasWood: false,
      hasThermal: false,
    });
  };

  const showColor = () => {
    setPageStateYalo({
      ...pageStateYalo,

      hasCrystal: false,
      hasSita: false,
      hasRola: false,
      hasMechs: false,
      hasSkia: false,
      hasIron: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTamplades: false,
      hasColor: true,
      hasWood: false,
      hasThermal: false,
    });
  };

  const showWood = () => {
    setPageStateYalo({
      ...pageStateYalo,

      hasCrystal: false,
      hasSita: false,
      hasRola: false,
      hasMechs: false,
      hasSkia: false,
      hasIron: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTamplades: false,
      hasColor: false,
      hasWood: true,
      hasThermal: false,
    });
  };

  const showThermal = () => {
    setPageStateYalo({
      ...pageStateYalo,

      hasCrystal: false,
      hasSita: false,
      hasRola: false,
      hasMechs: false,
      hasSkia: false,
      hasIron: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTamplades: false,
      hasColor: false,
      hasWood: false,
      hasThermal: true,
    });
  };

  //-------------------------------- show product_extras_patzoy -------------------
  const showMetalMech = () => {
    setPageStatePatzoy({
      ...pageStatePatzoy,
      hasMetalMech: true,
      hasMetalColor: false,
      hasSitaPatzoy: false,
      hasMech: false,
      hasKaitiaPatzoy: false,
      hasTampladesPatzoy: false,
      hasColorAdded: false,
      hasWoodAdded: false,
    });
  };

  const showMetalColor = () => {
    setPageStatePatzoy({
      ...pageStatePatzoy,
      hasMetalMech: false,
      hasMetalColor: true,
      hasSitaPatzoy: false,
      hasMech: false,
      hasKaitiaPatzoy: false,
      hasTampladesPatzoy: false,
      hasColorAdded: false,
      hasWoodAdded: false,
    });
  };

  const showSitaPatzoy = () => {
    setPageStatePatzoy({
      ...pageStatePatzoy,
      hasMetalMech: false,
      hasMetalColor: false,
      hasSitaPatzoy: true,
      hasMech: false,
      hasKaitiaPatzoy: false,
      hasTampladesPatzoy: false,
      hasColorAdded: false,
      hasWoodAdded: false,
    });
  };

  const showMech = () => {
    setPageStatePatzoy({
      ...pageStatePatzoy,
      hasMetalMech: false,
      hasMetalColor: false,
      hasSitaPatzoy: false,
      hasMech: true,
      hasKaitiaPatzoy: false,
      hasTampladesPatzoy: false,
      hasColorAdded: false,
      hasWoodAdded: false,
    });
  };

  const showKaitiaPatzoy = () => {
    setPageStatePatzoy({
      ...pageStatePatzoy,
      hasMetalMech: false,
      hasMetalColor: false,
      hasSitaPatzoy: false,
      hasMech: false,
      hasKaitiaPatzoy: true,
      hasTampladesPatzoy: false,
      hasColorAdded: false,
      hasWoodAdded: false,
    });
  };

  const showTampladesPatzoy = () => {
    setPageStatePatzoy({
      ...pageStatePatzoy,
      hasMetalMech: false,
      hasMetalColor: false,
      hasSitaPatzoy: false,
      hasMech: false,
      hasKaitiaPatzoy: false,
      hasTampladesPatzoy: true,
      hasColorAdded: false,
      hasWoodAdded: false,
    });
  };

  const showColorAdded = () => {
    setPageStatePatzoy({
      ...pageStatePatzoy,
      hasMetalMech: false,
      hasMetalColor: false,
      hasSitaPatzoy: false,
      hasMech: false,
      hasKaitiaPatzoy: false,
      hasTampladesPatzoy: false,
      hasColorAdded: true,
      hasWoodAdded: false,
    });
  };

  const showWoodAdded = () => {
    setPageStatePatzoy({
      ...pageStatePatzoy,
      hasMetalMech: false,
      hasMetalColor: false,
      hasSitaPatzoy: false,
      hasMech: false,
      hasKaitiaPatzoy: false,
      hasTampladesPatzoy: false,
      hasColorAdded: false,
      hasWoodAdded: true,
    });
  };
  ///////////////////////////////// end both show //////////////////////////////////////////

  const saveProductSchema = () => {
    setPageState({
      ...pageState,
      isLoading: true,
    });
    setPageStateYalo({
      ...pageStateYalo,
      hasCrystal: false,
      hasSita: false,
      hasRola: false,
      hasMechs: false,
      hasSkia: false,
      hasIron: false,
      hasAlarm: false,
      hasKaitia: false,
      hasTamplades: false,
      hasColor: false,
      hasWood: false,
      hasThermal: false,
    });
    setPageStatePatzoy({
      ...pageStatePatzoy,
      hasMetalMech: false,
      hasMetalColor: false,
      hasSitaPatzoy: false,
      hasMech: false,
      hasKaitiaPatzoy: false,
      hasTampladesPatzoy: false,
      hasColorAdded: false,
      hasWoodAdded: false,
    });
    const request_body = {
      extra_yalo_1: pageStateYalo.selectedProduct_crystal,
      extra_yalo_1_amount: pageStateYalo.selectedProduct_crystal_amount,
      extra_yalo_2: pageStateYalo.selectedProduct_sita,
      extra_yalo_3: pageStateYalo.selectedProduct_rola,
      extra_yalo_4: pageStateYalo.selectedProduct_mechs,
      extra_yalo_5: pageStateYalo.selectedProduct_skia,
      extra_yalo_6: pageStateYalo.selectedProduct_iron,
      extra_yalo_7: pageStateYalo.selectedProduct_alarm,
      extra_yalo_8: pageStateYalo.selectedProduct_kaitia,
      extra_yalo_9: pageStateYalo.selectedProduct_tamplades,
      extra_yalo_10: pageStateYalo.selectedProduct_color,
      extra_yalo_11: pageStateYalo.selectedProduct_wood,
      extra_yalo_12: pageStateYalo.selectedProduct_thermal,
      extra_yalo_2_amount: pageStateYalo.selectedProduct_sita_amount,
      extra_yalo_3_amount: pageStateYalo.selectedProduct_rola_amount,
      extra_yalo_4_amount: pageStateYalo.selectedProduct_mechs_amount,
      extra_yalo_5_amount: pageStateYalo.selectedProduct_skia_amount,
      extra_yalo_6_amount: pageStateYalo.selectedProduct_iron_amount,
      extra_yalo_7_amount: pageStateYalo.selectedProduct_alarm_amount,
      extra_yalo_8_amount: pageStateYalo.selectedProduct_kaitia_amount,
      extra_yalo_9_amount: pageStateYalo.selectedProduct_tamplades_amount,
      extra_yalo_10_amount: pageStateYalo.selectedProduct_color_amount,
      extra_yalo_11_amount: pageStateYalo.selectedProduct_wood_amount,
      extra_yalo_12_amount: pageStateYalo.selectedProduct_thermal_amount,
      extra_patzoy_1_1: pageStatePatzoy.selectedProduct_metalMech,
      extra_patzoy_1_2: pageStatePatzoy.selectedProduct_metalColor,
      extra_patzoy_2: pageStatePatzoy.selectedProduct_sitaPatzoy,
      extra_patzoy_3: pageStatePatzoy.selectedProduct_mech,
      extra_patzoy_4: pageStatePatzoy.selectedProduct_kaitiaPatzoy,
      extra_patzoy_5: pageStatePatzoy.selectedProduct_tampladesPatzoy,
      extra_patzoy_6: pageStatePatzoy.selectedProduct_colorAdded,
      extra_patzoy_7: pageStatePatzoy.selectedProduct_woodAdded,
      extra_patzoy_1_1_amount: pageStatePatzoy.selectedProduct_metalMech_amount,
      extra_patzoy_1_2_amount:
        pageStatePatzoy.selectedProduct_metalColor_amount,
      extra_patzoy_2_amount: pageStatePatzoy.selectedProduct_sitaPatzoy_amount,
      extra_patzoy_3_amount: pageStatePatzoy.selectedProduct_mech_amount,
      extra_patzoy_4_amount:
        pageStatePatzoy.selectedProduct_kaitiaPatzoy_amount,
      extra_patzoy_5_amount:
        pageStatePatzoy.selectedProduct_tampladesPatzoy_amount,
      extra_patzoy_6_amount: pageStatePatzoy.selectedProduct_colorAdded_amount,
      extra_patzoy_7_amount: pageStatePatzoy.selectedProduct_woodAdded_amount,
    };
    if (!isAddMode) {
      offerProductsService
        .update(offer_product_key, request_body)
        .then((data) => {
          console.log(
            "productsService.update resp is :" + JSON.stringify(data)
          );
          if (data.message === undefined || data.message === null) {
            console.log("Updated Offer Product" + { offer_product_key });
            setPageState({
              ...pageState,
              isLoading: false,
            });
          } else {
            setPageState({
              ...pageState,
              isLoading: false,
              error: data.message,
            });
          }
        });
    } else {
      console.log("WTF INSERT MODE ???");
    }
    // else {
    //   //Insert mode
    //   request_body.offer = offer_key;
    //   console.log(request_body);
    //   productsService.create(request_body).then((data) => {
    //     console.log("productsService.create resp is :" + JSON.stringify(data));
    //     if (data.message === undefined || data.message === null) {
    //       console.log("Created New Product");
    //       setPageStateYalo({
    //         ...pageStateYalo,
    //         selectedProductHasSchema: false,
    //         selectedProduct_crystal: "",
    //         selectedProduct_sita: "",
    //         selectedProduct_rola: "",
    //         selectedProduct_mechs: "",
    //         selectedProduct_skia: "",
    //         selectedProduct_iron: "",
    //         selectedProduct_alarm: "",
    //         selectedProduct_kaitia: "",
    //         selectedProduct_tamplades: "",
    //         selectedProduct_color: "",
    //         selectedProduct_wood: "",
    //         selectedProduct_thermal: "",
    //       });
    //       setPageStatePatzoy({
    //         ...pageStatePatzoy,
    //         selectedProduct_metalMech: "",
    //         selectedProduct_metalColor: "",
    //         selectedProduct_sitaPatzoy: "",
    //         selectedProduct_mech: "",
    //         selectedProduct_kaitiaPatzoy: "",
    //         selectedProduct_tampladesPatzoy: "",
    //         selectedProduct_colorAdded: "",
    //         selectedProduct_woodAdded: "",
    //       });
    //       setPageState({
    //         ...pageState,
    //         isLoading: false,
    //       });
    //     } else {
    //       setPageState({
    //         ...pageState,
    //         isLoading: false,
    //         error: data.message,
    //       });
    //     }
    //   });
    // }
    // console.log("request_body is :" + JSON.stringify(request_body));
  };

  // const deleteProductSchema = () => {
  //   if (isAddMode) {
  //     setPageState({
  //       ...pageState,
  //       isLoading: false,
  //     });
  //     setPageStateYalo({
  //       ...pageStateYalo,
  //       hasCrystal: false,
  //       hasSita: false,
  //       hasRola: false,
  //       hasMechs: false,
  //       hasSkia: false,
  //       hasIron: false,
  //       hasAlarm: false,
  //       hasKaitia: false,
  //       hasTamplades: false,
  //       hasColor: false,
  //       hasWood: false,
  //       hasThermal: false,
  //       selectedProduct_crystal: "",
  //       selectedProduct_sita: "",
  //       selectedProduct_rola: "",
  //       selectedProduct_mechs: "",
  //       selectedProduct_skia: "",
  //       selectedProduct_iron: "",
  //       selectedProduct_alarm: "",
  //       selectedProduct_kaitia: "",
  //       selectedProduct_tamplades: "",
  //       selectedProduct_color: "",
  //       selectedProduct_wood: "",
  //       selectedProduct_thermal: "",
  //     });
  //     setPageStatePatzoy({
  //       ...pageStatePatzoy,
  //       hasMetalMech: false,
  //       hasMetalColor: false,
  //       hasSitaPatzoy: false,
  //       hasMech: false,
  //       hasKaitiaPatzoy: false,
  //       hasTampladesPatzoy: false,
  //       hasColorAdded: false,
  //       hasWoodAdded: false,
  //       selectedProduct_metalMech: "",
  //       selectedProduct_metalColor: "",
  //       selectedProduct_sitaPatzoy: "",
  //       selectedProduct_mech: "",
  //       selectedProduct_kaitiaPatzoy: "",
  //       selectedProduct_tampladesPatzoy: "",
  //       selectedProduct_colorAdded: "",
  //       selectedProduct_woodAdded: "",
  //     });
  //   } else {
  //     navigate(`/offers/edit/${offer_key}`);
  //   }
  // };

  const displayExtrasYalo = () => {
    setPageState({
      ...pageState,
      extrasYalo: true,
      extrasPatzoy: false,
    });
  };

  const displayExtrasPatzoy = () => {
    setPageState({
      ...pageState,
      extrasPatzoy: true,
      extrasYalo: false,
    });
  };

  const buttonName = [
    "Κρύσταλλα",
    "Σήτες",
    "Ρολά",
    "Μηχανισμοί",
    "Σκιάδια",
    "Σιδεριές",
    "Συναγερμός",
    "Καΐτια",
    "Ταμπλάδες",
    "Χρώμα",
    "Ξυλουργικά",
    "Θερμική Απόδοση",
    "Μεταλλικά Μέρη - Τύπος",
    "Μεταλλικά Μέρη - Χρώμα",
    "Ξυλουργικά",
  ];

  const returnBack = () => {
    navigate(`/offers/edit/${offer_key}`);
  };

  return (
    <section>
      {isAddMode && <h1>Προσθήκη χαρακτηριστικών</h1>}
      {!isAddMode && <h1>Επεξεργασία χαρακτηριστικών</h1>}
      {/* Πρώτη γραμμή πεδίων προσφοράς */}
      <div className="row mb-2 shadow p-2 bg-light rounded justify-content-center">
        <h3>Προσφορά</h3>
        <div className="col-md-2 g-0">
          {/* <label className="form-label">Προσφορά</label> */}
          <input
            type="text"
            value={offerPass.offerIdPass}
            className="form-control"
            disabled={true}
          />
        </div>
        <div className="col-md-2 g-0">
          {/* <label className="form-label">Ημερομηνία</label> */}
          <input
            type="text"
            value={offerPass.offerDatePass}
            className="form-control"
            disabled={true}
          />
        </div>
        <div className="col-md-2 g-0">
          {/* <label className="form-label">Χρήστης</label> */}
          <input
            type="text"
            value={offerPass.offerUserPass}
            className="form-control"
            disabled={true}
          />
        </div>
        <div className="col-md-3 g-0">
          {/* <label className="form-label">Πελάτης</label> */}
          <input
            type="text"
            value={offerPass.offerCustomerPass}
            className="form-control"
            disabled={true}
          />
        </div>
        <div className="col-md-2 g-0">
          {/* <label className="form-label">Κατασκευαστής</label> */}
          <input
            type="text"
            value={offerPass.offerConstructorPass}
            className="form-control"
            disabled={true}
          />
        </div>
      </div>
      <div className="row mb-2 shadow p-2 bg-light rounded justify-content-center">
        <h3>Προϊόν</h3>
        <div>
          <div className="col-md-12 g-0">
            <input
              type="text"
              value={productNamePass}
              className="form-control"
              disabled={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 g-0">
            <input
              type="text"
              value={productTypologyPass}
              className="form-control"
              disabled={true}
            />
          </div>
          <div className="col-md-4 g-0">
            <input
              type="text"
              value={productFyllaPass}
              className="form-control"
              disabled={true}
            />
          </div>
          <div className="col-md-4 g-0">
            <input
              type="text"
              value={productOpenPass}
              className="form-control"
              disabled={true}
            />
          </div>
        </div>
      </div>
      {/* Δημιουργία νέου χαρακτηριστικού */}
      <section>
        <div className="row justify-content-around">
          <div className="col-4">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={displayExtrasYalo}
            >
              Χαρακτηριστικά Υαλοστασίων - Εξώθυρων
            </button>
          </div>
          <div className="col-4">
            <button
              type="button"
              className="btn btn-outline-secondary"
              onClick={displayExtrasPatzoy}
            >
              Χαρακτηριστικά Πατζουριών
            </button>
          </div>
        </div>
        {pageState.extrasYalo && (
          <div className="mt-1">
            <section>
              <button
                onClick={showCrystal}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[0]}
              </button>
              <button
                onClick={showSita}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[1]}
              </button>
              <button
                onClick={showRola}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[2]}
              </button>
              <button
                onClick={showMechs}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[3]}
              </button>
              <button
                onClick={showSkia}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[4]}
              </button>
              <button
                onClick={showIron}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[5]}
              </button>
              <button
                onClick={showAlarm}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[6]}
              </button>
              <button
                onClick={showKaitia}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[7]}
              </button>
              <button
                onClick={showTamplades}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[8]}
              </button>
              <button
                onClick={showColor}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[9]}
              </button>
              <button
                onClick={showWood}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[10]}
              </button>
              <button
                onClick={showThermal}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[11]}
              </button>
            </section>
            <section>
              {pageStateYalo.hasCrystal && (
                <div>
                  <h5 className="text-primary">{buttonName[0]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              // onChange={handleCrystal}
                              value={
                                pageStateYalo.selectedProduct_crystal_amount
                              }
                              onChange={(e) => {
                                console.log(e.target.value);
                                setPageStateYalo({
                                  ...pageStateYalo,
                                  selectedProduct_crystal_amount:
                                    e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStateYalo.crystal &&
                        pageStateYalo.crystal.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStateYalo.selectedProduct_crystal ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStateYalo.selectedProduct_crystal ===
                                    t.translation_id
                                  ) {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_crystal: "",
                                    });
                                  } else {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_crystal: t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStateYalo.hasSita && (
                <div>
                  <h5 className="text-primary">{buttonName[1]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={pageStateYalo.selectedProduct_sita_amount}
                              onChange={(e) => {
                                setPageStateYalo({
                                  ...pageStateYalo,
                                  selectedProduct_sita_amount: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStateYalo.sita &&
                        pageStateYalo.sita.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStateYalo.selectedProduct_sita ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStateYalo.selectedProduct_sita ===
                                    t.translation_id
                                  ) {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_sita: "",
                                    });
                                  } else {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_sita: t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStateYalo.hasRola && (
                <div>
                  <h5 className="text-primary">{buttonName[2]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={pageStateYalo.selectedProduct_rola_amount}
                              onChange={(e) => {
                                setPageStateYalo({
                                  ...pageStateYalo,
                                  selectedProduct_rola_amount: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStateYalo.rola &&
                        pageStateYalo.rola.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStateYalo.selectedProduct_rola ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStateYalo.selectedProduct_rola ===
                                    t.translation_id
                                  ) {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_rola: "",
                                    });
                                  } else {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_rola: t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStateYalo.hasMechs && (
                <div>
                  <h5 className="text-primary">{buttonName[3]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={pageStateYalo.selectedProduct_mechs_amount}
                              onChange={(e) => {
                                setPageStateYalo({
                                  ...pageStateYalo,
                                  selectedProduct_mechs_amount: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStateYalo.mechs &&
                        pageStateYalo.mechs.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStateYalo.selectedProduct_mechs ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStateYalo.selectedProduct_mechs ===
                                    t.translation_id
                                  ) {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_mechs: "",
                                    });
                                  } else {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_mechs: t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStateYalo.hasSkia && (
                <div>
                  <h5 className="text-primary">{buttonName[4]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={pageStateYalo.selectedProduct_skia_amount}
                              onChange={(e) => {
                                setPageStateYalo({
                                  ...pageStateYalo,
                                  selectedProduct_skia_amount: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStateYalo.skia &&
                        pageStateYalo.skia.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStateYalo.selectedProduct_skia ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStateYalo.selectedProduct_skia ===
                                    t.translation_id
                                  ) {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_skia: "",
                                    });
                                  } else {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_skia: t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStateYalo.hasIron && (
                <div>
                  <h5 className="text-primary">{buttonName[5]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={pageStateYalo.selectedProduct_iron_amount}
                              onChange={(e) => {
                                setPageStateYalo({
                                  ...pageStateYalo,
                                  selectedProduct_iron_amount: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStateYalo.iron &&
                        pageStateYalo.iron.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStateYalo.selectedProduct_iron ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStateYalo.selectedProduct_iron ===
                                    t.translation_id
                                  ) {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_iron: "",
                                    });
                                  } else {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_iron: t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStateYalo.hasAlarm && (
                <div>
                  <h5 className="text-primary">{buttonName[6]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={pageStateYalo.selectedProduct_alarm_amount}
                              onChange={(e) => {
                                setPageStateYalo({
                                  ...pageStateYalo,
                                  selectedProduct_alarm_amount: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStateYalo.alarm &&
                        pageStateYalo.alarm.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStateYalo.selectedProduct_alarm ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStateYalo.selectedProduct_alarm ===
                                    t.translation_id
                                  ) {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_alarm: "",
                                    });
                                  } else {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_alarm: t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStateYalo.hasKaitia && (
                <div>
                  <h5 className="text-primary">{buttonName[7]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={
                                pageStateYalo.selectedProduct_kaitia_amount
                              }
                              onChange={(e) => {
                                setPageStateYalo({
                                  ...pageStateYalo,
                                  selectedProduct_kaitia_amount: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStateYalo.kaitia &&
                        pageStateYalo.kaitia.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStateYalo.selectedProduct_kaitia ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStateYalo.selectedProduct_kaitia ===
                                    t.translation_id
                                  ) {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_kaitia: "",
                                    });
                                  } else {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_kaitia: t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStateYalo.hasTamplades && (
                <div>
                  <h5 className="text-primary">{buttonName[8]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={
                                pageStateYalo.selectedProduct_tamplades_amount
                              }
                              onChange={(e) => {
                                setPageStateYalo({
                                  ...pageStateYalo,
                                  selectedProduct_tamplades_amount:
                                    e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStateYalo.tamplades &&
                        pageStateYalo.tamplades.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStateYalo.selectedProduct_tamplades ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStateYalo.selectedProduct_tamplades ===
                                    t.translation_id
                                  ) {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_tamplades: "",
                                    });
                                  } else {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_tamplades:
                                        t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStateYalo.hasColor && (
                <div>
                  <h5 className="text-primary">{buttonName[9]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={pageStateYalo.selectedProduct_color_amount}
                              onChange={(e) => {
                                setPageStateYalo({
                                  ...pageStateYalo,
                                  selectedProduct_color_amount: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStateYalo.color &&
                        pageStateYalo.color.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStateYalo.selectedProduct_color ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStateYalo.selectedProduct_color ===
                                    t.translation_id
                                  ) {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_color: "",
                                    });
                                  } else {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_color: t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStateYalo.hasWood && (
                <div>
                  <h5 className="text-primary">{buttonName[10]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={pageStateYalo.selectedProduct_wood_amount}
                              onChange={(e) => {
                                setPageStateYalo({
                                  ...pageStateYalo,
                                  selectedProduct_wood_amount: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStateYalo.wood &&
                        pageStateYalo.wood.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStateYalo.selectedProduct_wood ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStateYalo.selectedProduct_wood ===
                                    t.translation_id
                                  ) {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_wood: "",
                                    });
                                  } else {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_wood: t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStateYalo.hasThermal && (
                <div>
                  <h5 className="text-primary">{buttonName[11]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={
                                pageStateYalo.selectedProduct_thermal_amount
                              }
                              onChange={(e) => {
                                setPageStateYalo({
                                  ...pageStateYalo,
                                  selectedProduct_thermal_amount:
                                    e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStateYalo.thermal &&
                        pageStateYalo.thermal.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStateYalo.selectedProduct_thermal ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStateYalo.selectedProduct_thermal ===
                                    t.translation_id
                                  ) {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_thermal: "",
                                    });
                                  } else {
                                    setPageStateYalo({
                                      ...pageStateYalo,
                                      selectedProduct_thermal: t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </section>
            <section>
              <button
                onClick={() => {
                  saveProductSchema();
                }}
                className="btn btn-sm btn-success mb-3 me-1"
              >
                Αποθήκευση
              </button>
              {/* <button
                onClick={deleteProductSchema}
                className="btn btn-sm btn-danger mb-3"
              >
                Ακύρωση
              </button> */}
            </section>
          </div>
        )}
        {pageState.extrasPatzoy && (
          <div className="mt-1">
            <section>
              <button
                onClick={showMetalMech}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[12]}
              </button>
              <button
                onClick={showMetalColor}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[13]}
              </button>
              <button
                onClick={showSitaPatzoy}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[1]}
              </button>
              <button
                onClick={showMech}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[3]}
              </button>
              <button
                onClick={showKaitiaPatzoy}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[7]}
              </button>
              <button
                onClick={showTampladesPatzoy}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[8]}
              </button>
              <button
                onClick={showColorAdded}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[9]}
              </button>
              <button
                onClick={showWoodAdded}
                className="btn btn-outline-secondary btn-sm m-1"
              >
                {buttonName[14]}
              </button>
            </section>
            <section>
              {pageStatePatzoy.hasMetalMech && (
                <div>
                  <h5 className="text-primary">{buttonName[12]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={
                                pageStatePatzoy.selectedProduct_metalMech_amount
                              }
                              onChange={(e) => {
                                setPageStatePatzoy({
                                  ...pageStatePatzoy,
                                  selectedProduct_metalMech_amount:
                                    e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStatePatzoy.metalMech &&
                        pageStatePatzoy.metalMech.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStatePatzoy.selectedProduct_metalMech ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStatePatzoy.selectedProduct_metalMech ===
                                    t.translation_id
                                  ) {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_metalMech: "",
                                    });
                                  } else {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_metalMech:
                                        t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStatePatzoy.hasMetalColor && (
                <div>
                  <h5 className="text-primary">{buttonName[13]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={
                                pageStatePatzoy.selectedProduct_metalColor_amount
                              }
                              onChange={(e) => {
                                setPageStatePatzoy({
                                  ...pageStatePatzoy,
                                  selectedProduct_metalColor_amount:
                                    e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStatePatzoy.metalColor &&
                        pageStatePatzoy.metalColor.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStatePatzoy.selectedProduct_metalColor ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStatePatzoy.selectedProduct_metalColor ===
                                    t.translation_id
                                  ) {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_metalColor: "",
                                    });
                                  } else {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_metalColor:
                                        t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStatePatzoy.hasSitaPatzoy && (
                <div>
                  <h5 className="text-primary">{buttonName[1]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={
                                pageStatePatzoy.selectedProduct_sitaPatzoy_amount
                              }
                              onChange={(e) => {
                                setPageStatePatzoy({
                                  ...pageStatePatzoy,
                                  selectedProduct_sitaPatzoy_amount:
                                    e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStatePatzoy.sitaPatzoy &&
                        pageStatePatzoy.sitaPatzoy.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStatePatzoy.selectedProduct_sitaPatzoy ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStatePatzoy.selectedProduct_sitaPatzoy ===
                                    t.translation_id
                                  ) {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_sitaPatzoy: "",
                                    });
                                  } else {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_sitaPatzoy:
                                        t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStatePatzoy.hasMech && (
                <div>
                  <h5 className="text-primary">{buttonName[3]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={
                                pageStatePatzoy.selectedProduct_mech_amount
                              }
                              onChange={(e) => {
                                setPageStatePatzoy({
                                  ...pageStatePatzoy,
                                  selectedProduct_mech_amount: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStatePatzoy.mech &&
                        pageStatePatzoy.mech.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStatePatzoy.selectedProduct_mech ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStatePatzoy.selectedProduct_mech ===
                                    t.translation_id
                                  ) {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_mech: "",
                                    });
                                  } else {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_mech: t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStatePatzoy.hasKaitiaPatzoy && (
                <div>
                  <h5 className="text-primary">{buttonName[7]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={
                                pageStatePatzoy.selectedProduct_kaitiaPatzoy_amount
                              }
                              onChange={(e) => {
                                setPageStatePatzoy({
                                  ...pageStatePatzoy,
                                  selectedProduct_kaitiaPatzoy_amount:
                                    e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStatePatzoy.kaitiaPatzoy &&
                        pageStatePatzoy.kaitiaPatzoy.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStatePatzoy.selectedProduct_kaitiaPatzoy ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStatePatzoy.selectedProduct_kaitiaPatzoy ===
                                    t.translation_id
                                  ) {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_kaitiaPatzoy: "",
                                    });
                                  } else {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_kaitiaPatzoy:
                                        t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStatePatzoy.hasTampladesPatzoy && (
                <div>
                  <h5 className="text-primary">{buttonName[8]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={
                                pageStatePatzoy.selectedProduct_tampladesPatzoy_amount
                              }
                              onChange={(e) => {
                                setPageStatePatzoy({
                                  ...pageStatePatzoy,
                                  selectedProduct_tampladesPatzoy_amount:
                                    e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStatePatzoy.tampladesPatzoy &&
                        pageStatePatzoy.tampladesPatzoy.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStatePatzoy.selectedProduct_tampladesPatzoy ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStatePatzoy.selectedProduct_tampladesPatzoy ===
                                    t.translation_id
                                  ) {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_tampladesPatzoy: "",
                                    });
                                  } else {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_tampladesPatzoy:
                                        t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStatePatzoy.hasColorAdded && (
                <div>
                  <h5 className="text-primary">{buttonName[9]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={
                                pageStatePatzoy.selectedProduct_colorAdded_amount
                              }
                              onChange={(e) => {
                                setPageStatePatzoy({
                                  ...pageStatePatzoy,
                                  selectedProduct_colorAdded_amount:
                                    e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStatePatzoy.colorAdded &&
                        pageStatePatzoy.colorAdded.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStatePatzoy.selectedProduct_colorAdded ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStatePatzoy.selectedProduct_colorAdded ===
                                    t.translation_id
                                  ) {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_colorAdded: "",
                                    });
                                  } else {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_colorAdded:
                                        t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
              {pageStatePatzoy.hasWoodAdded && (
                <div>
                  <h5 className="text-primary">{buttonName[14]}</h5>
                  <table className="table table-striped table-hover table-bordered table-sm">
                    <thead className="thead-light">
                      <tr>
                        <th style={{ width: "30%" }}>Ελληνικά</th>
                        <th style={{ width: "30%" }}>Αγγλικά</th>
                        <th style={{ width: "25%" }}>Ιταλικά</th>
                        <th style={{ width: "15%" }}>
                          <div style={{ display: "flex" }}>
                            <label>Ποσό: </label>
                            <input
                              type="number"
                              placeholder="ποσό"
                              className="form-control form-control-sm"
                              value={
                                pageStatePatzoy.selectedProduct_woodAdded_amount
                              }
                              onChange={(e) => {
                                setPageStatePatzoy({
                                  ...pageStatePatzoy,
                                  selectedProduct_woodAdded_amount:
                                    e.target.value,
                                });
                              }}
                            />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageStatePatzoy.woodAdded &&
                        pageStatePatzoy.woodAdded.map((t) => (
                          <tr key={t.translation_id}>
                            <td>{t.el}</td>
                            <td>{t.en}</td>
                            <td>{t.it}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={
                                  pageStatePatzoy.selectedProduct_woodAdded ===
                                  t.translation_id
                                }
                                onChange={() => {
                                  if (
                                    pageStatePatzoy.selectedProduct_woodAdded ===
                                    t.translation_id
                                  ) {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_woodAdded: "",
                                    });
                                  } else {
                                    setPageStatePatzoy({
                                      ...pageStatePatzoy,
                                      selectedProduct_woodAdded:
                                        t.translation_id,
                                    });
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </section>
            <section>
              <button
                onClick={() => {
                  saveProductSchema();
                }}
                className="btn btn-sm btn-success mb-3 me-1"
              >
                Αποθήκευση
              </button>
              {/* <button
                onClick={deleteProductSchema}
                className="btn btn-sm btn-danger mb-3"
              >
                Ακύρωση
              </button> */}
            </section>
          </div>
        )}
      </section>
      <div className="row mt-3 justify-content-end">
        <div className="col-3">
          <button
            type="submit"
            onClick={returnBack}
            className="btn btn-sm btn-outline-danger ms-3 mt-1 ms-1 me-1"
          >
            Επιστροφή
          </button>
        </div>
      </div>
    </section>
  );
}

export default AddEditOfferProductsSpecifics;
