import React, { useState, useEffect } from "react";
import { userService } from "../../_services/userService";
import { Link, useNavigate } from "react-router-dom";

const UsersList = () => {
  const [users, setUsers] = useState(null);
  const [hasResult, setHasResult] = useState(false);
  const navigate = useNavigate();

  // const fetchData = () => {
  useEffect(() => {
    userService.getAll().then((x) => {
      if (x.message === undefined || x.message === null) {
        setUsers(x)
      }
      else if(x.statusCode === 401){
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/login");
      }
    });
    setHasResult(true);
  }, []);
  // };

  const deleteUser = (username) => {
    setUsers(
      users.map((x) => {
        if (x.username === username) {
          x.isDeleting = true;
        }
        return x;
      })
    );
    userService.delete(username).then(() => {
      setUsers((users) => users.filter((x) => x.username !== username));
    });
  };

  return (
    <section>
      <h1>Λίστα Χρηστών</h1>
      {/* <button
      // { !hasResult ? '' : disabled }
        onClick={fetchData}
        className="btn btn-sm btn-primary mb-3 "
      >
        Εμφάνιση Δεδομένων
      </button> */}
      {hasResult && (
        <div className="table-responsive">
          <table className="table table-striped table-hover table-bordered table-sm">
            <thead className="thead-light">
              <tr>
                <th style={{ width: "30%" }}>Χρήστης</th>
                <th style={{ width: "30%" }}>Όνομα</th>
                <th style={{ width: "30%" }}>Επώνυμο</th>
                <th style={{ width: "10%" }}>
                  <Link to="add" className="btn btn-sm btn-success mb">
                    Δημιουργία
                  </Link>
                </th>
              </tr>
            </thead>
            <tbody className="align-middle">
              {users &&
                users.map((user) => (
                  <tr key={user.username}>
                    <td>{user.username}</td>
                    <td>{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td style={{ whiteSpace: "nowrap" }}>
                      <Link
                        to={`edit/${user.username}`}
                        className="btn btn-sm btn-primary me-1"
                      >
                        Επεξεργασία
                      </Link>
                      <button
                        onClick={() => deleteUser(user.username)}
                        className="btn btn-sm btn-danger btn-delete-user"
                        disabled={user.isDeleting}
                      >
                        {user.isDeleting ? (
                          <span className="spinner-border spinner-border-sm"></span>
                        ) : (
                          <span>Διαγραφή</span>
                        )}
                      </button>
                    </td>
                  </tr>
                ))}
              {!users && (
                <tr>
                  <td colSpan="4" className="text-center">
                    <div className="spinner-border spinner-border-lg align-center"></div>
                  </td>
                </tr>
              )}
              {users && !users.length && (
                <tr>
                  <td colSpan="4" className="text-center">
                    <div className="p-2">No Users To Display</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </section>
  );
};

export default UsersList;
