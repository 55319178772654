const get = (url) => {
  console.log(url);
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "x_access_token": localStorage.getItem("token"),
    },
  };
  return fetch(url, requestOptions).then(jsonResponse).then(handleResponse);
};

const post = (url, body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x_access_token": localStorage.getItem("token"),
    },
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(jsonResponse).then(handleResponse);
};

const put = (url, body) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "x_access_token": localStorage.getItem("token"),
    },
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(jsonResponse).then(handleResponse);
};

const put_img = (url, file) => {
  console.log(file)
  const requestOptions = {
    method: "PUT",
    headers: {
      'content-type': 'multipart/form-data',
      'content-length': `${file.size}`,
      "x_access_token": localStorage.getItem("token"),
    },
    body: file,
  };
  return fetch(url, requestOptions).then(jsonResponse).then(handleResponse);
};

// prefixed with underscored because delete is a reserved word in javascript
const _delete = (url) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "x_access_token": localStorage.getItem("token"),
    },
  };
  return fetch(url, requestOptions).then(jsonResponse).then(handleResponse);
};

// helper functions
const jsonResponse = (resp) => {
  // console.log("API resp: " + JSON.stringify(resp));
  return resp.json();
};

const handleResponse = (response) => {
  console.log("API response.statusCode: " + response.statusCode);
  if (response.statusCode === 200) {
    // console.log("API response.data: " + JSON.stringify(response.data));
    return response.data;
  } else {
    // console.log("API response.message: " + JSON.stringify(response.message));
    return { 'message': response.message, 'statusCode': response.statusCode };
  }
};

export const fetchWrapper = {
  get,
  post,
  put,
  put_img,
  delete: _delete,
};
