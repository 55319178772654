import React from "react";
import UsersList from "../components/users/UsersList";
import { Navigate } from "react-router-dom";

const UsersPage = () => {
  const isLoggedin = !!localStorage.getItem("token");

  return (
    <section>
      {isLoggedin && <UsersList />}
      {!isLoggedin && <Navigate to="/login" />}
    </section>
  );
};

export default UsersPage;
