import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { translationsService } from "../../_services/translationsService";

const AddEditExtraYalo = () => {
  const [pageState, setPageState] = useState({
    extraYaloName: "",
    translationID: "",
    el: "",
    en: "",
    it: "",
    isLoading: false,
    error: "",
  });
  const navigate = useNavigate();
  const { extraYaloName, translationID } = useParams();
  const isAddMode = !translationID;
  console.log(extraYaloName, translationID);
  console.log(isAddMode);
  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, setValue, formState } = useForm();

  const onSubmit = (data) => {
    console.log("Save button sends data: " + JSON.stringify(data));
    setPageState({
      ...pageState,
      isLoading: true,
      error: "",
    });
    const params = {
      "el":data.el,
      "en":data.en,
      "it":data.it,
      "type": extraYaloName
    }
    if (isAddMode) {
      translationsService.create(params).then((data) => {
        if (data.message === undefined || data.message === null) {
          // setTimeout(() => {
          setPageState({
            ...pageState,
            isLoading: false,
            error: "",
          });
          navigate("/extrayalo");
          // }, 2000)
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      });
    } else {
      // console.log("extraYaloName" + extraYaloName);
      translationsService.update(translationID, params).then((data) => {
        // console.log('Updating');
        // console.log("Resp" + JSON.stringify(data));
        if (data.message === undefined || data.message === null) {
          // setTimeout(() => {
          setPageState({
            ...pageState,
            isLoading: false,
            error: "",
          });
          navigate("/extrayalo");
          // }, 2000)
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      // get user and set form fields
      translationsService.getById(translationID).then((x) => {
        const fields = ["el", "en", "it"];
        fields.forEach((field) => setValue(field, x[field]));
      });
    }
  }, []);

  let typologyHeader = "";
  if (extraYaloName === "extra_yalo_1") {
    typologyHeader = " για Κρύσταλλα";
  } else if (extraYaloName === "extra_yalo_2") {
    typologyHeader = " για Σήτες";
  } else if (extraYaloName === "extra_yalo_3") {
    typologyHeader = " για Ρολά";
  } else if (extraYaloName === "extra_yalo_4") {
    typologyHeader = " για Μηχανισμούς";
  } else if (extraYaloName === "extra_yalo_5") {
    typologyHeader = " για Σκιάδια";
  } else if (extraYaloName === "extra_yalo_6") {
    typologyHeader = " για Σιδεριές";
  } else if (extraYaloName === "extra_yalo_7") {
    typologyHeader = " για Συαγερμούς";
  } else if (extraYaloName === "extra_yalo_8") {
    typologyHeader = " για Καϊτια";
  } else if (extraYaloName === "extra_yalo_9") {
    typologyHeader = " για Ταμπλάδες";
  } else if (extraYaloName === "extra_yalo_10") {
    typologyHeader = " για Επιβαρύνσεις σε Χρώμα";
  } else if (extraYaloName === "extra_yalo_11") {
    typologyHeader = " για Επιβαρύνσεις";
  } else if (extraYaloName === "extra_yalo_12") {
    typologyHeader = " για Θερμική Απόδοση";
  }

  return (
    <form
      className="row g-1"
      // style={{ width: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
      onReset={reset}
    >
      <h1>
        {isAddMode
          ? `Δημιουργία χαρακτηριστικών υαλοστασίων ${typologyHeader}`
          : `Επεξεργασία χαρακτηριστικών υαλοστασίων ${typologyHeader}`}
      </h1>
      <div className="row mb-3 justify-content-center">
        <div className="col-md-3">
          <label className="form-label">Ελληνικά</label>
          <input
            type="text"
            {...register("el")}
            placeholder="Ελληνικά"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label className="form-label">Αγγλικά</label>
          <input
            type="text"
            {...register("en")}
            placeholder="Αγγλικά"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label className="form-label">Ιταλικά</label>
          <input
            type="text"
            {...register("it")}
            placeholder="Ιταλικά"
            className="form-control"
          />
        </div>
      </div>
      <div className="col-12">
        <button
          type="submit"
          disabled={formState.isSubmitting}
          className="btn btn-success"
        >
          {formState.isSubmitting && (
            <span className="spinner-border spinner-border-sm mr-1"></span>
          )}
          {!pageState.isLoading && "Αποθήκευση"}
          {pageState.isLoading && "Loading.."}
        </button>
        <Link to="/extrayalo" className="btn btn-link">
          Ακύρωση
        </Link>
      </div>
      {pageState.error && `${pageState.error}`}
    </form>
  );
};

export default AddEditExtraYalo;
