import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { customersService } from "../../_services/customersService";

const AddEditCustomers = () => {
  const [pageState, setPageState] = useState({
    client_key: "",
    email: "",
    firstName: "",
    lastName: "",
    afm: "",
    mobile: "",
    city: "",
    country: "",
    address: "",
    architect: "",
    isLoading: false,
    error: "",
  });

  const navigate = useNavigate();
  const { client_key } = useParams();
  const isAddMode = !client_key;

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, setValue, formState } = useForm();

  const onSubmit = (data) => {
    // console.log("Save button sends data: " + JSON.stringify(data));
    setPageState({
      ...pageState,
      isLoading: true,
      error: "",
    });
    if (isAddMode) {
      customersService.create(data).then((data) => {
        if (data.message === undefined || data.message === null) {
          // setTimeout(() => {
          setPageState({
            ...pageState,
            isLoading: false,
            error: "",
          });
          navigate("/customers");
          // }, 2000)
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      });
    } else {
      // console.log("update data" + JSON.stringify(data));
      customersService.update(client_key, data).then((data) => {
        // console.log('Updating');
        // console.log("Resp" + JSON.stringify(data));
        if (data.message === undefined || data.message === null) {
          // setTimeout(() => {
          setPageState({
            ...pageState,
            isLoading: false,
            error: "",
          });
          navigate("/customers");
          // }, 2000)
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      // get customer and set form fields
      customersService.getById(client_key).then((customer) => {
        const fields = [
          "client_key",
          "email",
          "firstName",
          "lastName",
          "afm",
          "mobile",
          "country",
          "address",
          "city",
          "architect",
        ];
        fields.forEach((field) => setValue(field, customer[field]));
      });
    }
  }, []);

  return (
    <form className="row g-1" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
      <h1>{isAddMode ? "Δημιουργία Πελάτη" : "Επεξεργασία Πελάτη"}</h1>
      <div className="row mb-3 justify-content-center">
        <div className="col-md-3">
          <label>Όνομα</label>
          <input
            type="text"
            {...register("firstName")}
            placeholder="Όνομα"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label>Επώνυμο (Υποχρεωτικό)</label>
          <input
            type="text"
            {...register("lastName", { required: "Required" })}
            placeholder="Επώνυμο"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label>Κινητό</label>
          <input
            type="text"
            {...register("mobile")}
            placeholder="Κινητό"
            className="form-control"
          />
        </div>
      </div>
      <div className="row mb-3 justify-content-center">
        <div className="col-md-3">
          <label>email</label>
          <input
            type="text"
            {...register("email")}
            placeholder="email"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label>Αρχιτέκτονας</label>
          <input
            type="text"
            {...register("architect")}
            placeholder="Αρχιτέκτονας"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label>ΑΦΜ</label>
          <input
            type="text"
            {...register("afm")}
            placeholder="ΑΦΜ"
            className="form-control"
          />
        </div>
      </div>
      <div className="row mb-3 justify-content-center">
        <div className="col-md-3">
          <label>Διεύθυνση</label>
          <input
            type="text"
            {...register("address")}
            placeholder="Διεύθυνση"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label>Πόλη</label>
          <input
            type="text"
            {...register("city")}
            placeholder="Πόλη"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label>Χώρα</label>
          <input
            type="text"
            {...register("country")}
            placeholder="Χώρα"
            className="form-control"
          />
        </div>
      </div>
      <div className="form-group">
        <button
          type="submit"
          disabled={formState.isSubmitting}
          className="btn btn-success"
        >
          {formState.isSubmitting && (
            <span className="spinner-border spinner-border-sm mr-1"></span>
          )}
          {!pageState.isLoading && "Αποθήκευση"}
          {pageState.isLoading && "Loading.."}
        </button>
        <Link to="/customers" className="btn btn-link">
          Ακύρωση
        </Link>
      </div>
      {pageState.error && `${pageState.error}`}
    </form>
  );
};

export default AddEditCustomers;
