import React from "react";
import { Navigate } from "react-router-dom";
import ExtraYaloList from "../components/initialization/ExtraYaloList";

const ExtraYaloPage = () => {
  const isLoggedin = !!localStorage.getItem("token");

  return (
    <section>
      {isLoggedin && <ExtraYaloList />}
      {!isLoggedin && <Navigate to="/login" />}
    </section>
  );
};

export default ExtraYaloPage;
