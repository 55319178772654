import { fetchWrapper } from "../_helpers/fetch-wrapper";
import config from "../conf/Config.json";

let apiUrl;
if (process.env.REACT_APP_ENV === 'production') {
  apiUrl = `https://${config.production.api_id}.execute-api.${config.production.region}.amazonaws.com`;
} else if (process.env.REACT_APP_ENV === 'nikos-aws') {
  apiUrl = `https://${config["nikos-aws"].api_id}.execute-api.${config["nikos-aws"].region}.amazonaws.com`;
} else {
  apiUrl = '';
}
const baseUrl = `${apiUrl}/clients`;

const getAll = () => {
  return fetchWrapper.get(baseUrl);
};

const getById = (id) => {
  console.log(`${baseUrl}/id?client_key=${id}`);
  return fetchWrapper.get(`${baseUrl}/id?client_key=${id}`);
};

const create = (params) => {
  return fetchWrapper.post(`${baseUrl}/registration`, params);
};

const update = (id, params) => {
  console.log(`${baseUrl}/edit?client_key=${id}`);
  return fetchWrapper.put(`${baseUrl}/edit?client_key=${id}`, params);
};

// prefixed with underscored because delete is a reserved word in javascript
const _delete = (id) => {
  console.log(`Delete user with client_key=${id}`);
  return fetchWrapper.delete(`${baseUrl}/delete?client_key=${id}`);
};

export const customersService = {
  getAll,
  getById,
  create,
  update,
  delete: _delete,
};
