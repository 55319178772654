import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { userService } from "../../_services/userService";

const AddEditUsers = () => {
  const [pageState, setPageState] = useState({
    username: "",
    firstName: "",
    lastName: "",
    isLoading: false,
    error: "",
  });
  const navigate = useNavigate();
  const { username } = useParams();
  const isAddMode = !username;

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, setValue, formState } = useForm();

  const onSubmit = (data) => {
    // console.log("Save button sends data: " + JSON.stringify(data));
    setPageState({
      ...pageState,
      isLoading: true,
      error: "",
    });
    if (isAddMode) {
      userService.create(data).then((data) => {
        if (data.message === undefined || data.message === null) {
          // setTimeout(() => {
          setPageState({
            ...pageState,
            isLoading: false,
            error: "",
          });
          navigate("/users");
          // }, 2000)
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      });
    } else {
      // console.log("username" + username);
      userService.update(username, data).then((data) => {
        // console.log('Updating');
        // console.log("Resp" + JSON.stringify(data));
        if (data.message === undefined || data.message === null) {
          // setTimeout(() => {
          setPageState({
            ...pageState,
            isLoading: false,
            error: "",
          });
          navigate("/users");
          // }, 2000)
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (!isAddMode) {
      // get user and set form fields
      userService.getById(username).then((user) => {
        const fields = ["username", "firstName", "lastName"];
        fields.forEach((field) => setValue(field, user[field]));
      });
    }
  }, []);

  return (
    <form
      className="row g-1"
      // style={{ width: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
      onReset={reset}
    >
      <h1>{isAddMode ? "Δημιουργία Χρήστη" : "Επεξεργασία χρήστη"}</h1>
      <div className="row mb-3 justify-content-center">
        <div className="col-md-3">
          <label className="form-label">Όνομα</label>
          <input
            type="text"
            {...register("firstName")}
            placeholder="Όνομα"
            className="form-control"
          />
        </div>
        <div className="col-md-3">
          <label className="form-label">Επώνυμο</label>
          <input
            type="text"
            {...register("lastName", { minLength: 2 })}
            placeholder="Επώνυμο"
            className="form-control"
          />
        </div>
      </div>
      <div className="row mb-3 justify-content-center">
        <div className="col-md-3">
          <label className="form-label">Όνομα Χρήστη (Υποχρεωτικό)</label>
          {!isAddMode && (
            <input
              type="text"
              {...register("username", { required: "Required" })}
              className="form-control"
              disabled
              
            />
          )}
          {isAddMode && (
            <input
              type="text"
              {...register("username", { required: "Required" })}
              placeholder="Όνομα χρήστη"
              className="form-control"
            />
          )}
        </div>
        <div className="col-md-3">
          <label className="form-label">Password</label>
          <input
            type="password"
            {...register("password")}
            className="form-control"
          />

          <div className="invalid-feedback">Error Username</div>
        </div>
        {!isAddMode && (
          <div className="pt-3">
            {/* <h4 >Αλλαγή Password</h4> */}
            <p>Αφήστε κενό για να διατηρήσετε τον ίδιο κωδικό πρόσβασης</p>
          </div>
        )}
      </div>
      <div className="col-12">
        <button
          type="submit"
          disabled={formState.isSubmitting}
          className="btn btn-success"
        >
          {formState.isSubmitting && (
            <span className="spinner-border spinner-border-sm mr-1"></span>
          )}
          {!pageState.isLoading && "Αποθήκευση"}
          {pageState.isLoading && "Loading.."}
        </button>
        <Link to="/users" className="btn btn-link">
          Ακύρωση
        </Link>
      </div>
      {pageState.error && `${pageState.error}`}
    </form>
  );
};

export default AddEditUsers;
