import React from "react";
import { Navigate } from "react-router-dom";

const HomePage = () => {
  const isLoggedin = !!localStorage.getItem("token");
  
  return (
    <section>
      <h1 className="mb-5">Sylor.gr Web Application</h1>
      {/* {isLoggedin && <InitializationPageContent />} */}
      {!isLoggedin && <Navigate to="/login" />}
      <img src={process.env.PUBLIC_URL + "/logo-sylor.png"} />
    </section>
  );
};

export default HomePage;
