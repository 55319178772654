import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { customersService } from "../../_services/customersService";
import { offersService } from "../../_services/offersService";
import OfferProductsList from "./OfferProductsList";
// import { offerProductsService } from "../../_services/offerProductsService";
import AddDeleteTableRowsCost from "../../_helpers/add-delete-table-rows/AddDeleteTableRowsCost";
import AddDeleteTableRowsCharge from "../../_helpers/add-delete-table-rows/AddDeleteTableRowsCharge";
import { useLocation } from "react-router-dom";
import config from "../../conf/Config";

const AddEditOffers = () => {
  const [products, setProducts] = useState([]);
  const [costs, setCosts] = useState([]);
  const [charges, setCharges] = useState([]);
  const [costsDescPass, setCostsDescPass] = useState([]);
  const [costsAmountPass, setCostsAmountPass] = useState([]);
  const [chargesDescPass, setChargesDescPass] = useState([]);
  const [chargesAmountPass, setChargesAmountPass] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedCustomerID, setSelectedCustomerID] = useState("");

  const [pageState, setPageState] = useState({
    offer_key: "",
    offer_id: "",
    offer_date: "",
    offer_to: "",
    customer: "",
    customer_name: "",
    options: "",
    info_el: "",
    info_en: "",
    info_it: "",
    offer_constructor: "",
    username: "",
    charge: "",
    discount: "",
    offer_amount: "",
    isLoading: false,
    error: "",
  });

  const navigate = useNavigate();
  const location = useLocation();

  const { offer_key } = useParams();
  const isAddMode = !offer_key;
  const [offerPass, setOfferPass] = useState({
    offerIdPass: "",
    offerDatePass: "",
    offerUserPass: "",
    offerCustomerPass: "",
    offerCustomerNamePass: "",
    offerConstructorPass: "",
    offerToPass: "",
    offerInfoelPass: "",
    offerInfoenPass: "",
    offerInfoitPass: "",
    offerAmountPass: "",
    offerDiscountPass: "",
    offerDiscountAmountPass: "",
    offerFpaPass: "",
    offerFpaAmountPass: "",
    costsDescPass: "",
    costsAmountPass: "",
    chargesDescPass: "",
    chargesAmountPass: "",
  });

  // Default values for Date and User
  const userApp = localStorage.getItem("user");
  let newDate = new Date().toLocaleDateString();

  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, setValue, formState } = useForm();

  const onSubmit = (data, e) => {
    if (costs.length === 0) {
      data.costs = [];
    } else if (costs.length > 0) {
      console.log(data.costs);
      if (data.costs === undefined) {
        data.costs = [];
      }
      costs.map((desc) => {
        data.costs = [...data.costs, { desc: desc.desc, value: desc.value }];
      });
    }
    if (charges.length === 0) {
      data.charges = [];
    } else if (charges.length > 0) {
      if (data.charges === undefined) {
        data.charges = [];
      }
      charges.map((desc) => {
        data.charges = [
          ...data.charges,
          { desc: desc.desc, value: desc.value },
        ];
      });
    }
    console.log("Save button sends data: " + JSON.stringify(data));

    let form_data = new FormData(e.target);
    const info_el = form_data.get("info_el");
    const info_en = form_data.get("info_en");
    const info_it = form_data.get("info_it");
    console.log(info_el);
    setPageState({
      ...pageState,
      isLoading: true,
      error: "",
    });
    data.info_el = info_el;
    data.info_en = info_en;
    data.info_it = info_it;
    if (isAddMode) {
      const { maxOfferId } = location.state;
      data.offer_id = maxOfferId;
      offersService.create(data).then((data) => {
        console.log(data);
        if (data.message === undefined || data.message === null) {
          setPageState({
            ...pageState,
            isLoading: false,
            offer_key: data.offer_key,
          });
          navigate(`/offers/edit/${data.offer_key}`);
          window.location.reload(false);
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      });
    } else {
      // console.log("offer_key" + offer_key);
      offersService.update(offer_key, data).then((data) => {
        // console.log('Updating');
        // console.log("Resp" + JSON.stringify(data));
        if (data.message === undefined || data.message === null) {
          // setTimeout(() => {
          setPageState({
            ...pageState,
            isLoading: false,
            error: "",
          });
          // }, 2000)
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      });
    }
  };

  useEffect(() => {
    // Get Customer data to fill customer select field
    customersService.getAll().then((data) => {
      console.log("Get all customers:" + JSON.stringify(data));
      if (data.message === undefined || data.message === null) {
        console.log(data);
        // Successful response
        let _options = [];
        data.map((customer) => {
          _options = [
            ..._options,
            {
              value: customer.client_key,
              label: customer.lastName,
            },
          ];
        });
        setPageState({
          ...pageState,
          options: _options,
        });
        console.log(_options);
      } else {
        setPageState({
          ...pageState,
          isLoading: false,
          error: data.message,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (!isAddMode && pageState.options.length > 0) {
      // get user and set form fields
      offersService.getById(offer_key).then((offer) => {
        console.log(offer);

        const fields = [
          "offer_key",
          "offer_id",
          "offer_date",
          "offer_to",
          "info_el",
          "info_en",
          "info_it",
          "customer",
          "customer_name",
          "offer_constructor",
          "username",
          "charge",
          "discount",
          "offer_amount",
          "offer_discount_amount",
          "offer_fpa_amount",
          "costs_desc",
          "costs_amount",
          "charges_desc",
          "charges_amount",
          "fpa",
          "offer_products_chars_amount",
        ];
        fields.forEach((field) => {
          // console.log(field, offer[field])
          if (field === "costs_desc") setCostsDescPass(offer[field]);
          if (field === "costs_amount") setCostsAmountPass(offer[field]);
          if (field === "charges_desc") setChargesDescPass(offer[field]);
          if (field === "charges_amount") setChargesAmountPass(offer[field]);
          if (field === "customer_name") setSelectedCustomer(offer[field]);
          if (field === "customer") setSelectedCustomerID(offer[field]);
          setValue(field, offer[field]);
        });
        setValue("username", userApp);
        setValue("offer_date", newDate);
        // setValue("offer_amount", 0);
        console.log(costsDescPass);
        console.log(costsAmountPass);
        console.log(chargesDescPass);
        console.log(chargesAmountPass);
        setOfferPass({
          offerIdPass: offer.offer_id,
          offerDatePass: offer.offer_date,
          offerUserPass: offer.username,
          offerCustomerPass: offer.customer_name,
          offerConstructorPass: offer.offer_constructor,
          offerToPass: offer.offer_to,
          offerInfoelPass: offer.info_el,
          offerInfoenPass: offer.info_en,
          offerInfoitPass: offer.info_it,
          offerAmountPass: offer.offer_amount,
          offerDiscountPass: offer.discount,
          offerDiscountAmountPass: offer.offer_discount_amount,
          offerFpaPass: offer.fpa,
          offerFpaAmountPass: offer.offer_fpa_amount,
          costsDescPass: offer.costs_desc,
          costsAmountPass: offer.costs_amount,
          chargesDescPass: offer.charges_desc,
          chargesAmountPass: offer.charges_amount,
        });
        console.log(offerPass.offerIdPass);
        setPageState({
          ...pageState,

          info_el: offer["info_el"],
          info_en: offer["info_en"],
          info_it: offer["info_it"],
        });
      });
    }
    // Update default values
    setValue("username", userApp);
    setValue("offer_date", newDate);
    // setValue("offer_amount", 0);
  }, [pageState.options]);

  const getAllOfferProducts = (allProducts) => {
    console.log(allProducts);
    setProducts(allProducts);
  };

  const selectChangeHandler = (e) => {
    if (e.value) {
      setValue("customer", e.value);
      setValue("customer_name", e.label);
      setSelectedCustomerID(e.value);
    }
  };

  const getAllCosts = (allCosts) => {
    setCosts(allCosts);
  };

  const getAllCharges = (allCharges) => {
    setCharges(allCharges);
  };

  const handleChangeInfoEl = (e) => {
    setPageState({
      ...pageState,
      info_el: e.target.value,
    });
  };

  const handleChangeInfoEn = (e) => {
    setPageState({
      ...pageState,
      info_en: e.target.value,
    });
  };

  const handleChangeInfoIt = (e) => {
    setPageState({
      ...pageState,
      info_it: e.target.value,
    });
  };

  //-----------------------------------------------------------------------------//
  return (
    <section>
      <form
        className="row g-3"
        onSubmit={handleSubmit(onSubmit)}
        onReset={reset}
      >
        <h1>{isAddMode ? "Δημιουργία Προσφοράς" : "Επεξεργασία Προσφοράς"}</h1>
        {/* Πρώτη γραμμή πεδίων προσφοράς */}
        <div className="row mb-2 shadow p-2 bg-light rounded justify-content-center">
          <div className="col-md-2 g-0">
            <label className="form-label">Προσφορά</label>
            <input
              type="text"
              {...register("offer_id")}
              // value={pageState.offer_id}
              disabled={true}
              className="form-control"
            />
          </div>
          <div className="col-md-2 g-0">
            <label className="form-label">Ημερομηνία</label>
            <input
              type="text"
              // {...register("offer_date")}
              defaultValue={newDate}
              disabled={true}
              placeholder="Ημερομηνία"
              className="form-control"
            />
          </div>
          <div className="col-md-2 g-0">
            <label className="form-label">Χρήστης</label>
            <input
              type="text"
              // {...register("username")}
              defaultValue={userApp}
              disabled={true}
              placeholder="Χρήστης"
              className="form-control"
            />
          </div>
          <div className="col-md-3 g-0">
            <label className="form-label">Πελάτης</label>
            {pageState.options && (
              <Select
                placeholder="Πελάτης"
                name="customer"
                className="form-contro"
                options={pageState.options}
                // value={pageState.options.find(item => item.value === "product_key")}
                value={pageState.options.find((c) => {
                  if (c.value === selectedCustomerID) {
                    return c.label;
                  }
                })}
                onChange={selectChangeHandler}
              />
            )}
            {/* <input
              type="text"
              {...register("customer")}
              placeholder="Πελάτης"
              className="form-control" 
            />*/}
          </div>
          <div className="col-md-2 g-0">
            <label className="form-label">Κατασκευαστής</label>
            <input
              type="text"
              {...register("offer_constructor")}
              placeholder="Κατασκευαστής"
              className="form-control"
            />
          </div>
        </div>
        {offer_key && (
          <OfferProductsList
            getAllOfferProducts={getAllOfferProducts}
            offer_key={offer_key}
            offerPass={offerPass}
            isAddEditOffer={true}
            lang={config.lang.hellenic}
          />
        )}
        {/* Περισσότερα */}
        <div className="row mt-2 shadow p-1 bg-body rounded">
          {/* Δεύτερη γραμμή πεδίων προσφοράς */}
          <div className="row mb-2 justify-content-center">
            <h6>Περισσότερα</h6>
            <div className="col-md-5 g-0">
              <label className="form-label">Προς</label>
              <input
                type="text"
                {...register("offer_to")}
                placeholder="Προς"
                className="form-control"
              />
            </div>
            <div className="col-md-1 g-0 ms-1">
              <label className="form-label">Επιβάρυνση</label>
              <input
                type="number"
                {...register("charge")}
                placeholder="Επιβάρυνση"
                className="form-control"
              />
            </div>
            <div className="col-md-1 g-0 ms-1">
              <label className="form-label">Έκπτωση</label>
              <input
                type="number"
                {...register("discount")}
                placeholder="Έκπτωση"
                className="form-control"
              />
            </div>
            <div className="col-md-1 g-0 ms-1">
              <label className="form-label">ΦΠΑ</label>
              <input
                type="number"
                {...register("fpa")}
                placeholder="ΦΠΑ"
                className="form-control"
              />
            </div>
            <div className="col-md-2 g-0 ms-1">
              <label className="form-label">Ποσό</label>
              <input
                type="text"
                {...register("offer_amount")}
                disabled={true}
                placeholder="Ποσό"
                className="form-control"
              />
            </div>
          </div>
          {/* Γενικες Πληροφορίες */}
          <div className="row justify-content-center">
            <h6>Γενικες Πληροφορίες</h6>
            <div className="col-md-4 mb-3 justify-content-center">
              <h6>Ελληνικά</h6>
              <div className="col-md-12 input-group-sm">
                <textarea
                  className="form-control"
                  name="info_el"
                  rows={4}
                  cols={40}
                  value={pageState.info_el}
                  onChange={handleChangeInfoEl}
                />
              </div>
            </div>
            <div className="col-md-4 mb-3 justify-content-center">
              <h6>Αγγλικά</h6>
              <div className="col-md-12 input-group-sm">
                <textarea
                  className="form-control"
                  name="info_en"
                  rows={4}
                  cols={40}
                  value={pageState.info_en}
                  onChange={handleChangeInfoEn}
                />
              </div>
            </div>
            <div className="col-md-4 mb-3 justify-content-center">
              <h6>Ιταλικά</h6>
              <div className="col-md-12 input-group-sm">
                <textarea
                  className="form-control"
                  name="info_it"
                  rows={4}
                  cols={40}
                  value={pageState.info_it}
                  onChange={handleChangeInfoIt}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Κουμπιά Αποθήκευσης Ακύρωσης Προσφοράς */}
        <div className="row mt-3 justify-content-center">
          <div className="col">
            <button
              type="submit"
              disabled={formState.isSubmitting}
              className="btn btn-primary"
            >
              {formState.isSubmitting && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              {!pageState.isLoading && "Αποθήκευση"}
              {pageState.isLoading && "Loading.."}
            </button>
            <Link
              to={"offercosting"}
              className="btn btn-sm btn-outline-primary ms-5 me-5"
            >
              Κοστολόγηση Προσφοράς
            </Link>
            <Link
              to={"inspect"}
              className="btn btn-sm btn-outline-primary me-5"
              state={{
                offerProducts: products,
                offer_key: offer_key,
                offerPass: offerPass,
              }}
            >
              Επισκόπηση Προσφοράς
            </Link>
            <Link to="/offers" className="btn btn-outline-danger">
              Επιστροφή
            </Link>
          </div>
        </div>
        {pageState.error && `${pageState.error}`}
      </form>
      {/* Επιπλέον Κόστη και Επιβαρύνσεις */}
      <div className="row mt-2 shadow p-1 bg-body rounded justify-content-center">
        <div className="row">
          <div className="col-sm-6">
            <h6 className="mb-0">Επιπλέον Κόστη</h6>
            <AddDeleteTableRowsCost
              getAllCosts={getAllCosts}
              costsDescPass={costsDescPass}
              costsAmountPass={costsAmountPass}
              isAddMode={isAddMode}
            />
          </div>
          <div className="col-sm-6">
            <h6 className="mb-0">Επιπλέον Επιβαρύνσεις</h6>
            <AddDeleteTableRowsCharge
              getAllCharges={getAllCharges}
              chargesDescPass={chargesDescPass}
              chargesAmountPass={chargesAmountPass}
              isAddMode={isAddMode}
            />
          </div>
        </div>
      </div>
    </section>
    // </PDFViewer>
  );
};

export default AddEditOffers;
