const TableRowsCharge = ({ rowsDataCharge, deleteTableRows, handleChange }) => {
  return rowsDataCharge.map((data, index) => {
    const { desc, value } = data;
    // console.log(data);
    return (
      <tr key={index}>
        <td>
          <input
            type="text"
            value={desc}
            onChange={(e) => handleChange(index, e)}
            name="desc"
            className="form-control"
          />
        </td>
        <td>
          <input
            type="number"
            value={value}
            onChange={(e) => handleChange(index, e)}
            name="value"
            className="form-control"
          />
        </td>
        <td>
          <button
            className="btn btn-outline-danger"
            onClick={() => deleteTableRows(index)}
          >
            x
          </button>
        </td>
      </tr>
    );
  });
};

export default TableRowsCharge;
