import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
// import { customersService } from "../../_services/customersService";
import { offerProductsService } from "../../_services/offerProductsService";

const AddEditOfferProductsDimensions = () => {
  const location = useLocation();
  const {
    offer_key,
    offerPass,
    productNamePass,
    productTypologyPass,
    productFyllaPass,
    productOpenPass,
    product_key,
  } = location.state;
  const [pageState, setPageState] = useState({
    isLoading: false,
    error: "",
  });
  // const navigate = useNavigate();
  const { offer_product_key } = useParams();
  const isAddMode = !offer_product_key;

  // const [currentValue, setCurrentValue] = useState(undefined);

  // const checkValue = (event) => {
  //   setCurrentValue(handleDecimalsOnValue(event.target.value));
  // };

  // const handleDecimalsOnValue = (value) => {
  //   const regex = /([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s;
  //   return value.match(regex)[0];
  // };
  console.log(productNamePass);
  console.log(offer_key, offerPass);
  // functions to build form returned by useForm() hook
  const { register, handleSubmit, reset, setValue, formState } = useForm();

  const onSubmit = (data) => {
    console.log("Save button sends data: " + JSON.stringify(data));
    setPageState({
      ...pageState,
      isLoading: true,
      error: "",
    });

    data.product = product_key;
    console.log(data.offer_product_code);
    if (data.offer_product_code === "None" || data.offer_product_code === "") {
      data.offer_product_code = "";
    }
    if (data.x === "None" || data.x === "") {
      data.x = 0;
    }
    if (data.y === "None" || data.y === "") {
      data.y = 0;
    }
    if (data.z === "None" || data.z === "") {
      data.z = 0;
    }
    if (data.unit_amount === "None" || data.unit_amount === "") {
      data.unit_amount = 0;
    }
    if (data.quantity === "None" || data.quantity === "") {
      data.quantity = 0;
    }
    // console.log(data.offer_product_code, data.quantity);

    offerProductsService.update(offer_product_key, data).then((resp) => {
      if (resp.message === undefined || resp.message === null) {
        // sendDimensionsData(product_key, data.offer_product_code, data.quantity)
        setPageState({
          ...pageState,
          isLoading: false,
          error: "",
        });
      } else {
        setPageState({
          ...pageState,
          isLoading: false,
          error: data.message,
        });
      }
    });
    // }
  };

  useEffect(() => {
    if (!isAddMode) {
      setPageState({
        ...pageState,
        isLoading: true,
        error: "",
      });
      // get customer and set form fields
      offerProductsService.getById(offer_product_key).then((data) => {
        if (data.message === undefined || data.message === null) {
          const fields = [
            "offer_product_code",
            "product",
            "x",
            "y",
            "z",
            "unit_amount",
            "offer",
            "quantity",
          ];
          fields.forEach((field) => {
            // if (field === "unit_amount") setCurrentValue(data[field]);
            setValue(field, data[field]);
          });
          setPageState({
            ...pageState,
            isLoading: false,
            error: "",
          });
        } else {
          setPageState({
            ...pageState,
            isLoading: false,
            error: data.message,
          });
        }
      });
    }
  }, []);

  return (
    <form className="row g-1" onSubmit={handleSubmit(onSubmit)} onReset={reset}>
      <h1>
        {isAddMode ? "Εισαγωγή Μορφοποιήσεων" : "Επεξεργασία Μορφοποιήσεων"}
      </h1>
      {/* Εμφάνιση πεδίων προσφοράς */}
      <div className="row mb-2 shadow p-2 bg-light rounded justify-content-center">
        <h3>Προσφορά</h3>
        <div className="col-md-2 g-0">
          {/* <label className="form-label">Προσφορά</label> */}
          <input
            type="text"
            value={offerPass.offerIdPass}
            placeholder="Προσφορά"
            className="form-control"
            disabled={true}
          />
        </div>
        <div className="col-md-2 g-0">
          {/* <label className="form-label">Ημερομηνία</label> */}
          <input
            type="text"
            value={offerPass.offerDatePass}
            placeholder="Ημερομηνία"
            className="form-control"
            disabled={true}
          />
        </div>
        <div className="col-md-2 g-0">
          {/* <label className="form-label">Χρήστης</label> */}
          <input
            type="text"
            value={offerPass.offerUserPass}
            placeholder="Χρήστης"
            className="form-control"
            disabled={true}
          />
        </div>
        <div className="col-md-3 g-0">
          {/* <label className="form-label">Πελάτης</label> */}
          <input
            type="text"
            value={offerPass.offerCustomerPass}
            placeholder="Πελάτης"
            className="form-control"
            disabled={true}
          />
        </div>
        <div className="col-md-2 g-0">
          {/* <label className="form-label">Κατασκευαστής</label> */}
          <input
            type="text"
            value={offerPass.offerConstructorPass}
            placeholder="Κατασκευαστής"
            className="form-control"
            disabled={true}
          />
        </div>
      </div>
      {/* Εμφάνιση πεδίων προιόντος */}
      <div className="row mb-2 shadow p-2 bg-light rounded justify-content-center">
        <h3>Προϊόν</h3>
        <div>
          <div className="col-md-12 g-0">
            <input
              type="text"
              value={productNamePass}
              className="form-control"
              disabled={true}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 g-0">
            <input
              type="text"
              value={productTypologyPass}
              className="form-control"
              disabled={true}
            />
          </div>
          <div className="col-md-4 g-0">
            <input
              type="text"
              value={productFyllaPass}
              className="form-control"
              disabled={true}
            />
          </div>
          <div className="col-md-4 g-0">
            <input
              type="text"
              value={productOpenPass}
              className="form-control"
              disabled={true}
            />
          </div>
        </div>
      </div>
      {/* Κύρια πεδία φόρμας */}
      <div className=" mb-3">
        <div className="row justify-content-center">
          <div className="col-md-2">
            <label>Πλάτος</label>
            <input
              type="number"
              {...register("x")}
              placeholder={pageState.x}
              className="form-control"
            />
          </div>
          <div className="col-md-2">
            <label>Ύψος</label>
            <input type="number" {...register("y")} className="form-control" />
          </div>
          <div className="col-md-2">
            <label>Πάχος</label>
            <input type="number" {...register("z")} className="form-control" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-2">
            <label>Κωδικός</label>
            <input
              type="text"
              {...register("offer_product_code")}
              // placeholder={pageState.offer_product_code}
              className="form-control"
            />
          </div>
          <div className="col-md-2">
            <label>Τεμάχια</label>
            <input
              type="number"
              {...register("quantity")}
              placeholder={pageState.quantity}
              className="form-control"
            />
          </div>
          <div className="col-md-2">
            <label>Ποσό</label>
            <input
              type="text"
              // value={currentValue}
              // onChange={(event) => checkValue(event, "change")}
              {...register("unit_amount")}
              placeholder={pageState.unit_amount}
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <button
          type="submit"
          disabled={formState.isSubmitting}
          className="btn btn-success"
        >
          {formState.isSubmitting && (
            <span className="spinner-border spinner-border-sm mr-1"></span>
          )}
          {!pageState.isLoading && "Αποθήκευση"}
          {pageState.isLoading && "Loading.."}
        </button>
        <Link
          to={`/offers/edit/${offer_key}`}
          className="btn btn-sm btn-outline-danger ms-3 mt-1 ms-1 me-1"
        >
          Επιστροφή
        </Link>
        {/* <Link to={`/offers/edit/${offer_key}`} className="btn btn-link">
          Ακύρωση
        </Link> */}
      </div>
      {pageState.error && `${pageState.error}`}
    </form>
  );
};

export default AddEditOfferProductsDimensions;
