import { fetchWrapper } from "../_helpers/fetch-wrapper";
import config from "../conf/Config.json";

let apiUrl;
if (process.env.REACT_APP_ENV === 'production') {
  apiUrl = `https://${config.production.api_id}.execute-api.${config.production.region}.amazonaws.com`;
} else if (process.env.REACT_APP_ENV === 'nikos-aws') {
  apiUrl = `https://${config["nikos-aws"].api_id}.execute-api.${config["nikos-aws"].region}.amazonaws.com`;
} else {
  apiUrl = '';
}
const baseUrl = `${apiUrl}/offers_product`;

const getAll = () => {
    return fetchWrapper.get(baseUrl);
};

const getById = (offer_key) => {
    return fetchWrapper.get(`${baseUrl}/id?offer_product_key=${offer_key}`);
};

const getByOffer = (offer_id, lang) => {
    // console.log(`${baseUrl}?offer_id=${offer_id}`)
    return fetchWrapper.get(`${baseUrl}?offer_id=${offer_id}&lang=${lang}`);
};

const create = (params) => {
    return fetchWrapper.post(`${baseUrl}/registration`, params);
};

const update = (offer_product_key, params) => {
    console.log(`${baseUrl}/edit?offer_product_key=${offer_product_key}`)
    return fetchWrapper.put(`${baseUrl}/edit?offer_product_key=${offer_product_key}`, params);
};

const _delete = (offer_product_key) => {
    return fetchWrapper.delete(`${baseUrl}/delete?offer_product_key=${offer_product_key}`);
};

export const offerProductsService = {
    getAll,
    getById,
    getByOffer,
    create,
    update,
    delete: _delete,
};
