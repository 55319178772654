import React, { useState, useEffect } from "react";
import { productsService } from "../../_services/productsService";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

const FavoriteProductsList = (props) => {
  const [products, setProducts] = useState(null);

  const [productNameOptions, setProductNameOptions] = useState({});
  const [typologyOptions, setTypologyOptions] = useState({});
  const [fyllaOptions, setFyllaOptions] = useState({});
  const [anoigmaOptions, setAnoigmaOptions] = useState({});
  const [tabladesOptions, setTabladesOtions] = useState({});
  const [kaitiaOptions, setKaitiaOptions] = useState({});
  const [sxedioOptions, setSxedioOptions] = useState({});
  const [koutiOptions, setKoutiOtions] = useState({});

  const [hasResult, setHasResult] = useState(false);
  const [isError, setIsError] = useState("");

  const isOfferMode = props.isOffer ? true : false;

  useEffect(() => {
    productsService.getFav().then((data) => {
      if (data) {
        setProducts(data);
        handleFilter(data);
        console.log(data);
        setHasResult(true);
      } else setIsError("error fetching data");
    });
  }, []);

  //Bootstrap Columns filtering
  const handleFilter = (data) => {
    console.log(data);
    const uniqueProductName = [
      ...new Set(
        data
          .map((item) =>
            item.product_name.value === null ? "" : item.product_name.value
          )
          .filter((notUndefined) => notUndefined !== undefined)
      ),
    ];
    const productNameTemp = uniqueProductName.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    console.log(productNameTemp);
    setProductNameOptions(productNameTemp);

    const uniqueTypology = [
      ...new Set(
        data
          .map((item) =>
            item.typology.value === null ? "" : item.typology.value
          )
          .filter((notUndefined) => notUndefined !== undefined)
      ),
    ];
    const typologyTemp = uniqueTypology.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    // console.log(typologyTemp);
    setTypologyOptions(typologyTemp);

    const uniqueFylla = [
      ...new Set(
        data.map((item) =>
          item.typology_1.value === null ? "" : item.typology_1.value
        )
      ),
    ];
    // console.log(uniqueFylla);
    const fyllaTemp = uniqueFylla
      .map((product) => {
        return {
          value: product,
          label: product,
        };
        // }
      })
      .filter((notUndefined) => notUndefined !== undefined);
    // console.log(fyllaTemp);
    setFyllaOptions(fyllaTemp);

    const uniqueAnoigma = [
      ...new Set(
        data
          .map((item) =>
            item.typology_2.value === null ? "" : item.typology_2.value
          )
          .filter((notUndefined) => notUndefined !== undefined)
      ),
    ];
    const anoigmaTemp = uniqueAnoigma.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    // console.log(anoigmaTemp);
    setAnoigmaOptions(anoigmaTemp);

    const uniqueTablades = [
      ...new Set(
        data
          .map((item) =>
            item.typology_3.value === null ? "" : item.typology_3.value
          )
          .filter((notUndefined) => notUndefined !== undefined)
      ),
    ];
    const tabladesTemp = uniqueTablades.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    setTabladesOtions(tabladesTemp);

    const uniqueKaitia = [
      ...new Set(
        data
          .map((item) =>
            item.typology_4.value === null ? "" : item.typology_4.value
          )
          .filter((notUndefined) => notUndefined !== undefined)
      ),
    ];
    const kaitiaTemp = uniqueKaitia.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    setKaitiaOptions(kaitiaTemp);

    const uniqueSxedio = [
      ...new Set(
        data
          .map((item) =>
            item.typology_5.value === null ? "" : item.typology_5.value
          )
          .filter((notUndefined) => notUndefined !== undefined)
      ),
    ];
    const sxedioTemp = uniqueSxedio.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    setSxedioOptions(sxedioTemp);

    const uniqueKouti = [
      ...new Set(
        data
          .map((item) =>
            item.typology_6.value === null ? "" : item.typology_6.value
          )
          .filter((notUndefined) => notUndefined !== undefined)
      ),
    ];
    const koutiTemp = uniqueKouti.map((data) => {
      return {
        value: data,
        label: data,
      };
    });
    setKoutiOtions(koutiTemp);
  };

  // function columnFormatter(column, colIndex, { sortElement, filterElement }) {
  //   return (
  //     <div style={{ display: "block", flexDirection: "column" }}>
  //       {column.text}
  //       {sortElement}
  //       {filterElement}
  //     </div>
  //   );
  // }

  const columns = [
    {
      dataField: "product_key",
      text: "ID",
      hidden: true,
    },
    {
      dataField: "product_name.value",
      text: "Προϊόν",
      sort: true,
      filter: selectFilter({
        options: productNameOptions,
      }),
    },
    {
      dataField: "typology.value",
      text: "Τυπολογία",
      sort: true,
      filter: selectFilter({ options: typologyOptions }),
    },
    {
      dataField: "typology_1.value",
      text: "Φύλλα",
      sort: true,
      filter: selectFilter({
        options: fyllaOptions,
      }),
    },
    {
      dataField: "typology_2.value",
      text: "Άνοιγμα",
      sort: true,
      filter: selectFilter({
        options: anoigmaOptions,
      }),
    },
    {
      dataField: "typology_3.value",
      text: "Ταμπλάδες",
      sort: true,
      filter: selectFilter({
        options: tabladesOptions,
      }),
    },
    {
      dataField: "typology_4.value",
      text: "Καϊτια",
      sort: true,
      filter: selectFilter({
        options: kaitiaOptions,
      }),
    },
    {
      dataField: "typology_5.value",
      text: "Σχέδιο",
      sort: true,
      filter: selectFilter({
        options: sxedioOptions,
      }),
    },
    {
      dataField: "typology_6.value",
      text: "Κουτί",
      sort: true,
      filter: selectFilter({
        options: koutiOptions,
      }),
    },
  ];

  // const pageRefresh = () => {window.location.reload(false)}

  // const saveProductSchema = (fav_product_key) => {
  //   const request_body = {
  //     offer: props.offer_key,
  //     product: fav_product_key,
  //   };

  //   console.log(request_body);
  //   productsService.create(props.neededProducts, request_body).then((data) => {
  //     console.log("productsService.create resp is :" + JSON.stringify(data));
  //     if (data.message === undefined || data.message === null) {
  //       console.log("Created New Product");
  //     }
  //   });
  //   // console.log("request_body is :" + JSON.stringify(request_body));
  // };

  const columnsOffer = [
    ...columns,
    {
      dataField: "product_key",
      text: "Ενέργειες",
      formatter: (cellContent, row) => (
        <button
          onClick={() => {
            props.saveFav(cellContent, true);
          }}
          className="btn btn-outline-secondary"
        >
          Επιλογή
        </button>
      ),
    },
  ];
  //Bootstrap Columns filtering stop

  // Bootstrap Custom Pagination Start
  const customTotal = (from, to, size) => (
    <span
      className="react-bootstrap-table-pagination-total"
      style={{ zIndex: "100" }}
    >
      Εμφάνιση {from} από {to} σε {size} αποτελέσματα
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 0,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "<<",
    prePageText: "<",
    nextPageText: ">",
    lastPageText: ">>",
    nextPageTitle: "Προηγούμενη",
    prePageTitle: "Επόμενη",
    firstPageTitle: "Αρχή",
    lastPageTitle: "Τέλος",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "Όλα",
        value: 5, //products.length
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  // Bootstrap Custom Pagination Stop

  return (
    <section>
      {!isOfferMode && <h1>Λίστα Αγαπημένων Προϊόντων</h1>}
      {!hasResult && <p>Loading ...</p>}
      {isError && <p>{isError}</p>}
      {products && (
        <div className="table-responsive">
          <BootstrapTable
            bootstrap4
            striped
            hover
            condensed
            keyField="product_key"
            data={products}
            columns={isOfferMode ? columnsOffer : columns}
            filter={filterFactory()}
            pagination={paginationFactory(options)}
          />
        </div>
      )}
    </section>
  );
};

export default FavoriteProductsList;
