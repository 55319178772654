import React, { useState, useEffect } from "react";
import { translationsService } from "../../_services/translationsService";
import config from "../../conf/Config";
import { Link } from "react-router-dom";

function TypologyList() {
  const [translations, setTranslations] = useState({
    productNames: [],
    hasProductNames: false,
    typologies: [],
    hasTypologies: false,
    typologyFilla: [],
    hasFilla: false,
    typologyAnoigma: [],
    hasAnoigma: false,
    typologyTamplades: [],
    hasTamplades: false,
    typologyKaitia: [],
    hasKaitia: false,
    typologySxedio: [],
    hasSxedio: false,
    typologyKouti: [],
    hasKouti: false,
    isLoading: false,
    hasResult: false,
    error: "",
  });

  // console.log("translations.hasProductNames is :" + JSON.stringify(translations.hasProductNames));

  useEffect(() => {
    setTranslations({
      ...translations,
      isLoading: true,
    });
    translationsService.getAll().then((data) => {
      // console.log("data is :" + JSON.stringify(data));
      if (data.message === undefined || data.message === null) {
        // Successful response
        let _productNames = [];
        let _typologies = [];
        let _typologyFilla = [];
        let _typologyAnoigma = [];
        let _typologyTamplades = [];
        let _typologyKaitia = [];
        let _typologySxedio = [];
        let _typologyKouti = [];
        data.map((translation, pos) => {
          // console.log("Translation.type is :" + JSON.stringify(translation.type));

          if (translation.type === config.translationTypes.productName) {
            _productNames = [..._productNames, translation];
          } else if (translation.type === config.translationTypes.typology) {
            _typologies = [..._typologies, translation];
          } else if (
            translation.type === config.translationTypes.typologyFilla
          ) {
            _typologyFilla = [..._typologyFilla, translation];
          } else if (
            translation.type === config.translationTypes.typologyAnoigma
          ) {
            _typologyAnoigma = [..._typologyAnoigma, translation];
          } else if (
            translation.type === config.translationTypes.typologyTamplades
          ) {
            _typologyTamplades = [..._typologyTamplades, translation];
          } else if (
            translation.type === config.translationTypes.typologyKaitia
          ) {
            _typologyKaitia = [..._typologyKaitia, translation];
          } else if (
            translation.type === config.translationTypes.typologySxedio
          ) {
            _typologySxedio = [..._typologySxedio, translation];
          } else if (
            translation.type === config.translationTypes.typologyKouti
          ) {
            _typologyKouti = [..._typologyKouti, translation];
          }
        });
        setTranslations({
          ...translations,
          productNames: _productNames,
          typologies: _typologies,
          typologyFilla: _typologyFilla,
          typologyAnoigma: _typologyAnoigma,
          typologyTamplades: _typologyTamplades,
          typologyKaitia: _typologyKaitia,
          typologySxedio: _typologySxedio,
          typologyKouti: _typologyKouti,
          isLoading: false,
          hasResult: true,
          error: "",
        });
      } else {
        setTranslations({
          ...translations,
          isLoading: false,
          error: data.message,
        });
      }
    });
  }, []);

  const showProducts = () => {
    // if (translations.productNames.length > 0) {
    setTranslations({
      ...translations,
      hasProductNames: true,
      hasTypologies: false,
      hasFilla: false,
      hasAnoigma: false,
      hasTamplades: false,
      hasKaitia: false,
      hasSxedio: false,
      hasKouti: false,
    });
    // }
  };

  const showTypologies = () => {
    setTranslations({
      ...translations,
      hasProductNames: false,
      hasTypologies: true,
      hasFilla: false,
      hasAnoigma: false,
      hasTamplades: false,
      hasKaitia: false,
      hasSxedio: false,
      hasKouti: false,
    });
  };

  const showFilla = () => {
    setTranslations({
      ...translations,
      hasProductNames: false,
      hasTypologies: false,
      hasFilla: true,
      hasAnoigma: false,
      hasTamplades: false,
      hasKaitia: false,
      hasSxedio: false,
      hasKouti: false,
    });
  };

  const showAnoigma = () => {
    setTranslations({
      ...translations,
      hasProductNames: false,
      hasTypologies: false,
      hasFilla: false,
      hasAnoigma: true,
      hasTamplades: false,
      hasKaitia: false,
      hasSxedio: false,
      hasKouti: false,
    });
  };

  const showTamplades = () => {
    setTranslations({
      ...translations,
      hasProductNames: false,
      hasTypologies: false,
      hasFilla: false,
      hasAnoigma: false,
      hasTamplades: true,
      hasKaitia: false,
      hasSxedio: false,
      hasKouti: false,
    });
  };

  const showKaitia = () => {
    setTranslations({
      ...translations,
      hasProductNames: false,
      hasTypologies: false,
      hasFilla: false,
      hasAnoigma: false,
      hasTamplades: false,
      hasKaitia: true,
      hasSxedio: false,
      hasKouti: false,
    });
  };

  const showSxedio = () => {
    setTranslations({
      ...translations,
      hasProductNames: false,
      hasTypologies: false,
      hasFilla: false,
      hasAnoigma: false,
      hasTamplades: false,
      hasKaitia: false,
      hasSxedio: true,
      hasKouti: false,
    });
  };

  const showKouti = () => {
    setTranslations({
      ...translations,
      hasProductNames: false,
      hasTypologies: false,
      hasFilla: false,
      hasAnoigma: false,
      hasTamplades: false,
      hasKaitia: false,
      hasSxedio: false,
      hasKouti: true,
    });
  };

  const _delete = (id, type) => {
    console.log(id, type);
    setTranslations({
      ...translations,
      isLoading: true,
    });
    translationsService.delete(id).then((data) => {
      console.log(data);
      if (data.message === undefined || data.message === null) {
        if (type === config.translationTypes.productName) {
          setTranslations({
            ...translations,
            isLoading: false,
            productNames: translations.productNames.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.translationTypes.typology) {
          setTranslations({
            ...translations,
            isLoading: false,
            typologies: translations.typologies.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.translationTypes.typologyFilla) {
          setTranslations({
            ...translations,
            isLoading: false,
            typologyFilla: translations.typologyFilla.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.translationTypes.typologyAnoigma) {
          setTranslations({
            ...translations,
            isLoading: false,
            typologyAnoigma: translations.typologyAnoigma.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.translationTypes.typologyTamplades) {
          setTranslations({
            ...translations,
            isLoading: false,
            typologyTamplades: translations.typologyTamplades.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.translationTypes.typologyKaitia) {
          setTranslations({
            ...translations,
            isLoading: false,
            typologyKaitia: translations.typologyKaitia.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.translationTypes.typologySxedio) {
          setTranslations({
            ...translations,
            isLoading: false,
            typologySxedio: translations.typologySxedio.filter(
              (x) => x.translation_id !== id
            ),
          });
        } else if (type === config.translationTypes.typologyKouti) {
          setTranslations({
            ...translations,
            isLoading: false,
            typologyKouti: translations.typologyKouti.filter(
              (x) => x.translation_id !== id
            ),
          });
        }
      } else {
        setTranslations({
          ...translations,
          isLoading: false,
          error: data.message,
        });
      }
    });
  };

  const buttonName = [
    "Προϊόντα",
    "Τυπολογίες",
    "Φύλλα",
    "Άνοιγμα",
    "Ταμπλάδες",
    "Καϊτια",
    "Σχέδιο",
    "Κουτί",
  ];

  return (
    <section>
      <h1>Τυπολογίες Προϊόντων</h1>
      {translations.isLoading && <p>Loading...</p>}
      {translations.error && <p>{translations.error}</p>}
      {translations.hasResult && (
        <section>
          <button
            onClick={showProducts}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[0]}
          </button>
          <button
            onClick={showTypologies}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[1]}
          </button>
          <button
            onClick={showFilla}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[2]}
          </button>
          <button
            onClick={showAnoigma}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[3]}
          </button>
          <button
            onClick={showTamplades}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[4]}
          </button>
          <button
            onClick={showKaitia}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[5]}
          </button>
          <button
            onClick={showSxedio}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[6]}
          </button>
          <button
            onClick={showKouti}
            className="btn btn-outline-secondary btn-sm m-1"
          >
            {buttonName[7]}
          </button>
        </section>
      )}
      <section className="table-responsive">
        {translations.hasProductNames && (
          <div>
            <h5 className="text-primary">{buttonName[0]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "25%" }}>Ιταλικά</th>
                  <th style={{ width: "5%" }}>Ώρες</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.translationTypes.productName}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.productNames &&
                  translations.productNames.map((t) => (
                    <tr key={t.translation_id}>
                      <td className="cell">{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td>{t.placement_h}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.translationTypes.productName}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.translationTypes.productName
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasTypologies && (
          <div>
            <h5 className="text-primary">{buttonName[1]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "5%" }}>Ώρες</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.translationTypes.typology}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.typologies &&
                  translations.typologies.map((t) => (
                    <tr key={t.translation_id}>
                      {/* <tr key={"row_" + index}> */}
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td>{t.placement_h}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.translationTypes.typology}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.translationTypes.typology
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasFilla && (
          <div>
            <h5 className="text-primary">{buttonName[2]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "5%" }}>Ώρες</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.translationTypes.typologyFilla}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.typologyFilla &&
                  translations.typologyFilla.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td>{t.placement_h}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.translationTypes.typologyFilla}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.translationTypes.typologyFilla
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasAnoigma && (
          <div>
            <h5 className="text-primary">{buttonName[3]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.translationTypes.typologyAnoigma}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.typologyAnoigma &&
                  translations.typologyAnoigma.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.translationTypes.typologyAnoigma}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.translationTypes.typologyAnoigma
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasTamplades && (
          <div>
            <h5 className="text-primary">{buttonName[4]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.translationTypes.typologyTamplades}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.typologyTamplades &&
                  translations.typologyTamplades.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.translationTypes.typologyTamplades}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.translationTypes.typologyTamplades
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasKaitia && (
          <div>
            <h5 className="text-primary">{buttonName[5]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.translationTypes.typologyKaitia}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.typologyKaitia &&
                  translations.typologyKaitia.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.translationTypes.typologyKaitia}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.translationTypes.typologyKaitia
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasSxedio && (
          <div>
            <h5 className="text-primary">{buttonName[6]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.translationTypes.typologySxedio}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.typologySxedio &&
                  translations.typologySxedio.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.translationTypes.typologySxedio}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.translationTypes.typologySxedio
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}

        {translations.hasKouti && (
          <div>
            <h5 className="text-primary">{buttonName[7]}</h5>
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "30%" }}>Ελληνικά</th>
                  <th style={{ width: "30%" }}>Αγγλικά</th>
                  <th style={{ width: "30%" }}>Ιταλικά</th>
                  <th style={{ width: "10%" }}>
                    <Link
                      to={`add/${config.translationTypes.typologyKouti}`}
                      className="btn btn-sm btn-success mb"
                    >
                      Δημιουργία
                    </Link>
                  </th>
                </tr>
              </thead>
              <tbody className="align-middle">
                {translations.typologyKouti &&
                  translations.typologyKouti.map((t) => (
                    <tr key={t.translation_id}>
                      <td>{t.el}</td>
                      <td>{t.en}</td>
                      <td>{t.it}</td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        <Link
                          to={`edit/${config.translationTypes.typologyKouti}/${t.translation_id}`}
                          className="btn btn-sm btn-primary me-1 "
                        >
                          Επεξεργασία
                        </Link>
                        <button
                          onClick={() =>
                            _delete(
                              t.translation_id,
                              config.translationTypes.typologyKouti
                            )
                          }
                          className="btn btn-sm btn-danger btn-delete-user"
                          disabled={t.isDeleting}
                        >
                          {t.isDeleting ? (
                            <span className="spinner-border spinner-border-sm"></span>
                          ) : (
                            <span>Διαγραφή</span>
                          )}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </section>
    </section>
  );
}

export default TypologyList;
