import React, { useEffect, useState } from "react";
import TableRowsCharge from "./TableRowsCharge";

const AddDeleteTableRowsCharge = (props) => {
  const [rowsDataCharge, setRowsDataCharge] = useState([]);

  // console.log(props.chargesDescPass);
  // console.log(props.chargesAmountPass);
  
  let chargesObject = [];
  if (
    props.chargesDescPass &&
    props.chargesDescPass.length > 0 &&
    props.chargesAmountPass &&
    props.chargesAmountPass.length > 0
  ) {
    chargesObject = props.chargesDescPass.map((desc, index) => {
      return {
        desc: desc,
        value: props.chargesAmountPass[index],
      };
    });
  }

  useEffect(() => {
    if (!props.isAddMode && chargesObject.length > 0) {
      if (rowsDataCharge.length > 0) {
        setRowsDataCharge([...rowsDataCharge, chargesObject]);
      } else {
        setRowsDataCharge(chargesObject);
      }
    }
  }, [chargesObject.length]);

  const addTableRows = () => {
    const rowsInputCharge = {
      desc: "",
      value: 0,
    };
    // console.log(rowsInputCharge);
    setRowsDataCharge([...rowsDataCharge, rowsInputCharge]);
    // console.log(rowsDataCharge);
  };

  const deleteTableRows = (index) => {
    const rows = [...rowsDataCharge];
    rows.splice(index, 1);
    // console.log(rows);
    setRowsDataCharge(rows);
    props.getAllCharges(rows);
  };

  const handleChange = (index, e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const rowsInputCharge = [...rowsDataCharge];
    rowsInputCharge[index][name] = value;
    // console.log(rowsInputCharge);
    setRowsDataCharge(rowsInputCharge);
    props.getAllCharges(rowsInputCharge);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <table className="table">
            <thead>
              <tr>
                <th>Περιγραφή</th>
                <th>Ποσό</th>
                <th>
                  <button
                    className="btn btn-outline-success"
                    onClick={addTableRows}
                  >
                    +
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <TableRowsCharge
                rowsDataCharge={rowsDataCharge}
                deleteTableRows={deleteTableRows}
                handleChange={handleChange}
              />
            </tbody>
          </table>
        </div>
        <div className="col-sn-12"></div>
      </div>
    </div>
  );
};

export default AddDeleteTableRowsCharge;
